import React from 'react';
import cn from 'classnames';
import { string, func, bool, number } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';

import { DATA_TEST_ID } from '~constants/tests';
import LocalStorageService from '~services/LocalStorageService';
import { actionCreators as creditCardsActions } from '~redux/CreditCards/actions';
import Text from '~components/Text';
import RadioText, { SIZES } from '~app/components/RadioGroup/components/RadioOption/components/RadioText';
import radioOptionStyles from '~app/components/RadioGroup/components/RadioOption/styles.module.scss';
import { CLIENT_PAGES } from '~constants/pages';

import styles from './styles.module.scss';

function PaymentMethod({
  name,
  id,
  displayName,
  iconClassName,
  className,
  spanClassName,
  isSelected,
  onSelectPaymentMethod,
  hasDelete,
  asButton,
  imgLogo,
  secondaryLogo,
  subscriptionMethodEnabled
}) {
  const dispatch = useDispatch();
  const accessToken = LocalStorageService.getAccessToken();
  const handleSelectPaymentMethod = () => onSelectPaymentMethod({ paymentMethod: name });
  const isProfile = useRouteMatch(CLIENT_PAGES.PROFILE.path);
  const handleDeleteCard = () => {
    dispatch(
      creditCardsActions.removeCard({
        cardId: id,
        externalId: accessToken,
        subscriptionMethodEnabled
      })
    );
  };
  return (
    <button
      type="button"
      onClick={handleSelectPaymentMethod}
      className={cn(
        'row middle',
        styles.paymentMethodContainer,
        { [styles.paymentMethodContainerButton]: asButton },
        radioOptionStyles.buttonContainer,
        className
      )}
      data-testid={`${DATA_TEST_ID.paymentMethod}-${name}`}>
      {!asButton && !isProfile && (
        <RadioText styles={radioOptionStyles} useCheck checked={isSelected} size={SIZES.LARGE} />
      )}
      {iconClassName && <i className={`m-right-1 ${styles.paymentMethodIcon} ${iconClassName}`} />}
      {imgLogo && <img src={imgLogo} className={styles.logo} />}
      {secondaryLogo && <img src={secondaryLogo} className={styles.logo} />}
      <Text className={spanClassName}>{displayName}</Text>
      {hasDelete && (
        <button type="button" className={styles.deleteCard} onClick={handleDeleteCard}>
          <i className="row middle center fa fa-close " />
        </button>
      )}
    </button>
  );
}

PaymentMethod.defaultProps = {
  asButton: false,
  className: '',
  hasDelete: false,
  id: '',
  isSelected: '',
  spanClassName: ''
};

PaymentMethod.propTypes = {
  displayName: string.isRequired,
  name: string.isRequired,
  onSelectPaymentMethod: func.isRequired,
  asButton: bool,
  className: string,
  hasDelete: bool,
  iconClassName: string,
  id: number,
  imgLogo: string,
  isSelected: string,
  secondaryLogo: string,
  spanClassName: string,
  subscriptionMethodEnabled: string
};

export default PaymentMethod;
