import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';

import './config/i18n';
import './initializer/numeral';
import './scss/application.scss';

import RollbarProvider from '~app/components/RollbarProvider';

import App from './app';

ReactDOM.render(
  <RollbarProvider>
    <App />
  </RollbarProvider>,
  document.getElementById('root')
);
