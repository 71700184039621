import React from 'react';
import { string } from 'prop-types';

import { DATA_TEST_ID } from '~constants/tests';

function ErrorMessage({ message, className }) {
  return (
    <span
      data-testid={DATA_TEST_ID.errorMessage}
      className={`text-emperor light text-red m-top-1 font-size-12 ${className}`}>
      {message}
    </span>
  );
}

ErrorMessage.defaultProps = {
  className: ''
};

ErrorMessage.propTypes = {
  message: string.isRequired,
  className: string
};

export default ErrorMessage;
