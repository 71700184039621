import { removeItemFromArray } from '~utils/array';

export const clearAction = (action, state) => {
  const actions = state?.nextUp?.nextActions;
  return removeItemFromArray(action, actions);
};

export const addAction = (action, state) => {
  const actions = state?.nextUp?.nextActions;
  return actions?.concat(action);
};
