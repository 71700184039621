import i18next from 'i18next';

i18next.addResources('es_CL', 'SimpleOrderDetail', {
  cartTotal: 'Subtotal',
  discount: 'Cupón',
  deliveryFee: 'Delivery',
  serviceFee: 'Servicios',
  tip: 'Propina repartidor',
  storeTip: 'Propina a la tienda'
});
