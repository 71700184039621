import React from 'react';
import cn from 'classnames';
import { bool, obj, string, oneOf } from 'prop-types';

import Text from '~components/Text';

import { SIZES } from './constants';

function RadioText({
  checked,
  styles,
  error,
  isMultiple,
  label,
  disabled,
  disabledText,
  labelClassName,
  useCheck,
  size
}) {
  const check = checked && useCheck;

  return (
    <>
      <div
        className={cn(styles.checkmark, 'm-right-2', styles[size], {
          [styles.checkmarkColor]: useCheck,
          [styles.checked]: checked,
          [styles.checkedColor]: check,
          [styles.checkIcon]: check,
          [styles.errors]: error,
          [styles.hidden]: isMultiple
        })}
      />
      <span
        className={cn(
          styles.label,
          labelClassName,
          { [`line-through ${styles.redLine}`]: disabled },
          { light: !checked },
          { bold: checked },
          {
            [styles.errors]: error
          }
        )}>
        {label}
        {disabled && disabledText && (
          <Text className="m-left-2 light-text text-thunderbird">{disabledText}</Text>
        )}
      </span>
    </>
  );
}

RadioText.defaultProps = {
  size: SIZES.MEDIUM
};

RadioText.propTypes = {
  checked: bool,
  disabled: bool,
  disabledText: bool,
  error: bool,
  isMultiple: bool,
  label: string,
  labelClassName: string,
  size: oneOf(Object.values(SIZES)),
  styles: obj,
  useCheck: bool
};

export { SIZES };
export default RadioText;
