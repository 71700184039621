import { snakeCase } from 'snake-case';
import i18next from 'i18next';
import Immutable from 'seamless-immutable';
import moment from 'moment';

import { isAvailableDispatchMethod } from '~models/dispatchMethod';
import { arrayHasLength, findAndUpdate, flatMap } from '~utils/array';
import { getWithoutStockUntilDate } from '~app/screens/Commerce/screens/Home/components/CategorySection/components/Product/utils';

export const getFinalPrice = (item) => item?.discountPrice || item?.price;

export const modifiersMapper = (items, mapper) => Object.values(items).flat().map(mapper);

export const priceItem = (items, count, basePrice) =>
  count *
  (basePrice +
    modifiersMapper(items, (modifier) => getFinalPrice(modifier) * (modifier.quantity || 1)).reduce(
      (accum, price) => accum + price,
      0
    ));

export const priceCart = (cart) =>
  cart.reduce((accum, item) => accum + priceItem(item.items, item.count, item.basePrice), 0);

// creates unique hash for each item - modifier combination
export const getHash = ({ items, product: { id } }) => {
  const itemsCopy = { ...items };
  return `${id}${Object.keys(itemsCopy)
    .sort()
    .map(
      (key, firstIndex) =>
        itemsCopy[key].length
          ? Immutable.asMutable(itemsCopy[key])
              .sort((a, b) => a.id - b.id)
              .map((modifier) => `${firstIndex}${key}${modifier.id}`)
              .join('')
          : `${firstIndex}0`
      // no modifier in that position
    )
    .join('')}`;
};

export const addItemToCart = (item, state) => {
  const { cart } = state;
  const hash = getHash(item);
  const [newCart, existingItem] = findAndUpdate(
    (i) => i.hash === hash,
    (i) => ({ ...i, count: i.count + item.count }),
    cart.cart
  );
  return existingItem ? newCart : [...newCart, { ...item, hash }];
};

export const sumItemToCart = (item, state) => {
  const { cart } = state;
  const hash = getHash(item);
  const [newCart, existingItem] = findAndUpdate(
    (i) => i.hash === hash,
    (i) => ({ ...i, count: i.count + 1 }),
    cart.cart
  );
  return existingItem ? newCart : [...newCart, { ...item, hash }];
};

export const checkCartDenormalize = (address = null) => (state) => {
  const { cart } = state;
  return { cart: cart.cart, state, address };
};

const updateCart = (cart, item) => {
  const hash = getHash(item);
  const [newCart] = findAndUpdate(
    (i) => i.hash === hash,
    (i) => ({ ...i, count: i.count - 1 }),
    cart.cart
  );
  return { cart: newCart };
};
export const removeItemFromCart = (item, state) => {
  const { cart } = state;
  const newCart = item.length
    ? item.reduce((acc, curr) => updateCart(acc, curr), cart)
    : updateCart(cart, item);
  return newCart.cart.filter((i) => i.count > 0);
};

export const removeItemsFromCart = (item, state) => {
  const { cart } = state;
  const getHashes = item.map((i) => i.hash);
  const newCart = cart.cart.filter((item) => !getHashes.includes(item.hash));
  return newCart;
};

export const modifyItemInCart = (item, state) => {
  const { cart } = state;
  const [newCart] = findAndUpdate(
    (i) => i.hash === item.hash,
    () => ({ ...item, hash: getHash(item) }),
    cart.cart
  );
  return newCart;
};

export const addDiscountToCart = ({ couponData, loyaltyData }) => (state) => {
  return {
    coupon: couponData.coupon,
    paymentMethod: couponData.paymentMethod ? snakeCase(couponData.paymentMethod) : '',
    cashToExchange: loyaltyData.cashToExchange,
    state
  };
};

const getMenuProducts = (menu, futureOrder) => {
  const menuPrices = flatMap(({ prices }) => prices, menu);
  const inStockProducts = menuPrices.filter((price) => {
    const endDatetime = price.product.endDatetime;
    const withoutStockUntil = getWithoutStockUntilDate(price.withoutStockUntil, price.product.startDatetime);
    const futureOrderDate =
      futureOrder?.date && futureOrder?.time
        ? moment(`${futureOrder.date} ${futureOrder.time}`, 'YYYY-MM-DD HH:mm')
        : null;

    if (futureOrder?.date && futureOrder?.time) {
      if (endDatetime && futureOrderDate.isAfter(moment(endDatetime))) {
        return false;
      }

      if (withoutStockUntil || price.product.availability.length > 0) {
        if (price.product.availability.length > 0 && !withoutStockUntil) {
          return price.product.availability.includes(futureOrderDate.format('dddd').toLowerCase());
        }

        if (price.product.availability.length > 0 && withoutStockUntil) {
          return (
            moment(withoutStockUntil).isBefore(futureOrderDate) &&
            price.product.availability.includes(futureOrderDate.format('dddd').toLowerCase())
          );
        }

        return moment(withoutStockUntil).isBefore(futureOrderDate);
      }
    }

    if (price.product.availability.length > 0) {
      if (withoutStockUntil) {
        return moment(withoutStockUntil).isBefore(moment());
      }

      return price.product.availability.includes(moment().format('dddd').toLowerCase());
    }

    return price.stock;
  });

  return flatMap(({ product: { id } }) => id, inStockProducts);
};

const getProductModifiers = (menu) => {
  const menuPrices = flatMap(({ prices }) => prices, menu);
  const modifiersCategory = flatMap(({ product: { modifiers } }) => modifiers, menuPrices);
  const modifiers = flatMap(({ children }) => children, modifiersCategory);
  return [...new Set(flatMap(({ id }) => id, modifiers))];
};

export const isAvailable = ({ product: { id }, dispatchMethod }, menu, futureOrder) => {
  const availableInMenu = getMenuProducts(menu, futureOrder).includes(id);
  return isAvailableDispatchMethod(dispatchMethod) && availableInMenu;
};

export const getUnavailableModifiers = ({ items }, menu) => {
  const modifiers = Object.values(items).map((res) => res[0]);
  if (arrayHasLength(modifiers)) {
    const modifiersFromProducts = getProductModifiers(menu);
    const unavailableModifiers = modifiers.filter(
      ({ id: modifierId }) => !modifiersFromProducts.includes(modifierId)
    );
    return unavailableModifiers;
  }
};

export const anyProductIsUnavailable = (state) => {
  const {
    cart: { cart },
    menu: { menu },
    order: { futureOrder }
  } = state;
  return (
    arrayHasLength(cart) &&
    cart.some((product) => {
      return (
        !isAvailable(product, menu, futureOrder) || arrayHasLength(getUnavailableModifiers(product, menu))
      );
    })
  );
};

export const getDeferredText = (cookingTime, type) =>
  `${i18next.t('Products:availableAfter')} ${cookingTime} ${i18next.t(`Products:${type}`)} ${i18next.t(
    'Products:afterBuy'
  )}`;

const mapToTimes = (array) => array.map(({ product }) => product.cookingTime);
const filterProductBy = (products, key) => products.filter(({ product }) => product.cookingTimeType === key);
const DAY_HOURS = 24;

export const getMaxTime = (products) => {
  const hours = filterProductBy(products, 'hour');
  const days = filterProductBy(products, 'day');
  const hoursArray = mapToTimes(hours).concat(mapToTimes(days).map((time) => time * DAY_HOURS));

  return Array.from(hoursArray).sort((a, b) => b - a)?.[0];
};

export const isAvailablePerTimeRange = (product) => {
  const FORMAT = 'HH:mm:ss';
  const { availabilityTime = {} } = product;
  const today = moment().format('dddd').toLowerCase();
  const todayAvailabilityTime = availabilityTime?.[today] || [];

  if (todayAvailabilityTime.length === 0) return true;

  const toTime = (dateTime) => moment.utc(dateTime.format(FORMAT), FORMAT);
  const now = toTime(moment());

  return todayAvailabilityTime.some(([from, to]) =>
    now.isBetween(toTime(moment.utc(from)), toTime(moment.utc(to)))
  );
};

export const isAvailableProduct = (product, dayLimit) => {
  const { startDatetime, endDatetime, availability } = product;
  const duration = moment.duration(dayLimit.diff(moment()));
  const availableDays = Array(Math.ceil(duration.asDays()) + 1)
    .fill(null)
    .reduce((days, _, index) => [...days, moment().add(index, 'days').format('dddd').toLowerCase()], []);

  const startIsBeforeNow = startDatetime && moment(startDatetime).isSameOrBefore(moment());
  const startIsBeforeLimit = startDatetime && moment(startDatetime).isSameOrBefore(dayLimit);
  const endIsAfterNow = endDatetime && moment(endDatetime).isSameOrAfter(moment());
  const endIsAfterLimit = endDatetime && moment(endDatetime).isSameOrAfter(dayLimit);
  const limitIsBetweenStartAndEnd =
    (startIsBeforeNow || startIsBeforeLimit) && (endIsAfterNow || endIsAfterLimit);

  const isAvailablePerDay =
    availability.length === 0 || availability.some((day) => availableDays.includes(day));

  return (
    ((!startDatetime && !endDatetime) ||
      ((startIsBeforeNow || startIsBeforeLimit) && !endDatetime) ||
      ((endIsAfterNow || endIsAfterLimit) && !startDatetime) ||
      limitIsBetweenStartAndEnd) &&
    isAvailablePerDay &&
    isAvailablePerTimeRange(product)
  );
};
