import { CODE_COUNTRY } from '~constants/environment';
import { LOGIN_TYPES } from '~constants/loginTypes';
import { arrayHasLength } from '~utils/array';

import { DEFAULT_VALUES, TARGETS } from './constants';

export const registerWithEmailMapper = ({ name, phone }) => (state) => {
  const { id, email } = state.auth[TARGETS.SEND_SIGN_IN_CODE_TO_EMAIL];
  return {
    user: {
      name,
      email,
      phone: `${CODE_COUNTRY}${phone}`,
      loginType: LOGIN_TYPES.EMAIL,
      externalId: id
    }
  };
};

export const signInWithEmailMapper = ({ email, verificationCode }) => ({
  user: {
    email,
    verificationCode
  }
});

export const mapUser = ({ email, name, phone }, address, state) => {
  const newState = state.auth?.address
    ? { ...state }
    : {
        ...state,
        auth: {
          address: DEFAULT_VALUES.address
        }
      };

  const {
    auth: {
      address: { current }
    },
    searchStore: { currentSubsidiary }
  } = newState;

  const { textAddress, description, apartmentDetails, latitude, longitude, placeId } = address || current;

  return {
    userName: name,
    ...(email && {
      userEmail: email
    }),
    userPhone: phone.toString().startsWith(CODE_COUNTRY) ? phone : `${CODE_COUNTRY}${phone}`,
    userAddress: arrayHasLength(description) ? description : textAddress,
    userCity: currentSubsidiary.commune,
    userLatitude: latitude,
    userLongitude: longitude,
    apartmentDetails,
    googlePlaceId: placeId
  };
};
