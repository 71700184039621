/* eslint-disable max-nested-callbacks */
/* eslint-disable no-unexpected-multiline */
/* eslint-disable complexity */
import { createTypes, completeTypes, withPostFailure, withSuccess, withPostSuccess } from 'redux-recompose';
import i18next from 'i18next';

import { history } from '~redux/store';
import { deserializer } from '~services/SearchStore/serializers';
import SearchStoreService from '~services/SearchStore/service';
import AuthStoreService from '~services/Auth/service';
import ModalActions from '~redux/Modal/actions';
import NextUpActions from '~redux/NextUp/actions';
import MenuActions from '~redux/Menu/actions';
import OrderActions from '~redux/Order/actions';
import { MODALS, ADD_ADDRESS_MODAL } from '~redux/Modal/constants';
import LocalStorageService from '~services/LocalStorageService';
import { DISPATCH_METHODS } from '~constants/address';
import { getTodayString, dateToTime, nowIsBeforeThan } from '~utils/time';
import { TRANSLATION_COUNTRY } from '~constants/environment';
import { ORDER_METHODS } from '~constants/orders';
import { CLIENT_PAGES } from '~constants/pages';
import { SUBSCRIPTION_STATUS, isAccountStatusSuspended } from '~constants/subscription';

import {
  getSubsidiary,
  getTopSiteMessage,
  mapToProductIds,
  noHoursAvailable,
  someProductOnMenu
} from './utils';
import {
  NEAR_TARGET,
  CURRENT_SUBSIDIARY_TARGET,
  DELIVERY_TARGET,
  DISPATCH_METHOD_TARGET,
  DISPATCH_TYPE_TAB_TARGET,
  USER_ADDRESS,
  USER_ADRESSES,
  DONT_SHOW_ALERTS,
  SCHEDULED_DATE_AVAILABILITY,
  STORES_BY_COMMUNE_TARGET,
  ALL_STORES
} from './constants';

export const actions = createTypes(
  completeTypes(
    [
      'PICK_UP',
      'DELIVERY',
      'GET_USER_ADDRESS',
      'GET_STORES',
      'GET_NEAR',
      'GET_USED_COUPONS',
      'GET_SUBSIDIARY',
      'GET_SCHEDULED_DATE_AVAILABILITY',
      'GET_ALL_STORES',
      'GET_STORE_DATA'
    ],
    ['SET_VALUE']
  ),
  '@@SEARCH_LOCAL'
);

const privateActionCreators = {
  getStores: (params, type, target, service) => ({
    type,
    target,
    service,
    payload: {
      latitude: params.lat,
      longitude: params.lng,
      address: params.address,
      page: params.page,
      commune: params.commune,
      googlePlaceId: params.googlePlaceId
    },
    injections: [withPostFailure((dispatch) => dispatch({ type: actions.SET_VALUE, target, payload: null }))]
  }),
  getNear: (params, type, target, service) => ({
    type,
    target,
    service,
    payload: {
      latitude: params.lat,
      longitude: params.lng,
      address: params.address,
      googlePlaceId: params.googlePlaceId
    },
    injections: [withPostFailure((dispatch) => dispatch({ type: actions.SET_VALUE, target, payload: null }))]
  })
};

export const actionCreators = {
  getStoresByCommune: (commune) =>
    privateActionCreators.getStores(
      { commune },
      actions.GET_STORES,
      STORES_BY_COMMUNE_TARGET,
      SearchStoreService.getStores
    ),
  getNear: (position) =>
    privateActionCreators.getNear(position, actions.GET_NEAR, NEAR_TARGET, SearchStoreService.getNear),
  getAllStores: () => ({
    type: actions.GET_ALL_STORES,
    target: ALL_STORES,
    service: SearchStoreService.getNear
  }),
  getStoreDelivery: (position) =>
    privateActionCreators.getStores(
      position,
      actions.DELIVERY,
      DELIVERY_TARGET,
      SearchStoreService.getStoreDelivery
    ),
  selectSubsidiary: (subsidiary, shouldClearCart = true) => (dispatch) => {
    LocalStorageService.setCurrentSubsidiary(subsidiary);
    if (shouldClearCart) {
      // TODO when the cart is ready, check this
      // dispatch(homeActions.clearCart());
    }
    dispatch({
      type: actions.SET_VALUE,
      target: CURRENT_SUBSIDIARY_TARGET,
      payload: subsidiary
    });
    dispatch(actionCreators.checkIfStoreIsOpen());
  },
  getStoreData: (id, dispatchType, currentSubsidiary) => async (dispatch, getState) => {
    dispatch({
      type: actions.GET_STORE_DATA,
      target: CURRENT_SUBSIDIARY_TARGET,
      service: SearchStoreService.getStoreData,
      payload: { id, dispatchMethod: dispatchType },
      successSelector: ({ data: { store, banners, disabledProducts } }) => {
        const storeFormatted = getSubsidiary({ data: store }, currentSubsidiary);
        return { store: storeFormatted, banners, disabledProducts };
      },
      injections: [
        withPostSuccess((dispatch, { data: { store, banners, disabledProducts } }) => {
          const state = getState();
          const { staticMenu } = state.core.settings || {};
          dispatch(actionCreators.setDispatchType(dispatchType));
          dispatch(actionCreators.selectSubsidiary(store));
          dispatch(actionCreators.getScheduledDateAvailability());
          dispatch(
            staticMenu ? MenuActions.getStaticMenu(store, banners, disabledProducts) : MenuActions.getMenu()
          ).then(() => {
            const openProductDetailModalAction = ModalActions.openModalWithData(MODALS.PRODUCT_DETAIL);
            const nextActions = state.nextUp.nextActions?.filter(
              ({ target }) =>
                target === ModalActions.openModal(MODALS.MINI_CART).target ||
                target === openProductDetailModalAction.target
            );
            nextActions.forEach((nextAction) => {
              if (nextAction.target === openProductDetailModalAction.target) {
                const someProduct = someProductOnMenu(
                  nextAction.payload.data.productId,
                  getState().menu?.menu
                );
                if (someProduct) {
                  history.push(nextAction.payload.data.productUrlPath);
                  dispatch(ModalActions.openModal(MODALS.PRODUCT_DETAIL));
                }
              } else {
                dispatch(nextAction);
              }

              dispatch(NextUpActions.clearAction(nextAction));
            });
          });
        }),
        withPostFailure((dispatch) => {
          dispatch(MenuActions.getMenu());
        })
      ]
    });
  },
  setDispatchType: (dispatchType) => (dispatch) => {
    LocalStorageService.setDispatchType(dispatchType);
    dispatch({
      type: actions.SET_VALUE,
      target: DISPATCH_METHOD_TARGET,
      payload: dispatchType
    });
    // TODO - when service is ready check this
    // dispatch(homeActions.getBannerImages());
  },
  setDispatchTypeTab: (dispatchType) => ({
    type: actions.SET_VALUE,
    target: DISPATCH_TYPE_TAB_TARGET,
    payload: dispatchType
  }),
  setAddress: (address) => ({
    type: actions.SET_VALUE,
    target: USER_ADDRESS,
    payload: address
  }),
  getUserAddress: () => ({
    type: actions.GET_USER_ADDRESS,
    target: USER_ADRESSES,
    service: SearchStoreService.getUserAddress,
    successSelector: (response) =>
      deserializer.serialize(response.data).map((address) => ({ ...address, hasStreetNumber: true }))
  }),
  clearUserAddresses: () => ({
    type: actions.SET_VALUE,
    target: USER_ADRESSES
  }),
  createUserAddress: (address) => ({
    type: actions.CREATE_USER_ADDRESS,
    service: AuthStoreService.createAddress,
    payload: address,
    injections: [
      withSuccess((dispatch) => {
        dispatch(ModalActions.closeModal(ADD_ADDRESS_MODAL));
        dispatch(actionCreators.getUserAddress());
      })
    ]
  }),
  editUserAddress: (address, id) => ({
    type: actions.UPDATE_USER_ADDRESS,
    service: AuthStoreService.updateAddress,
    payload: { address, id },
    injections: [
      withSuccess((dispatch) => {
        dispatch(ModalActions.closeModal(ADD_ADDRESS_MODAL));
        dispatch(actionCreators.getUserAddress());
      })
    ]
  }),
  deleteUserAddress: (id) => ({
    type: actions.DELETE_USER_ADDRESS,
    service: AuthStoreService.deleteAddress,
    payload: id,
    injections: [
      withSuccess((dispatch) => {
        dispatch(ModalActions.closeModal(ADD_ADDRESS_MODAL));
        dispatch(actionCreators.getUserAddress());
      })
    ]
  }),
  setValue: (target, payload) => ({
    type: actions.SET_VALUE,
    target,
    payload
  }),
  checkIfStoreIsOpen: () => (dispatch, getState) => {
    const { searchStore, order, core } = getState();
    const subscriptionStatus = core?.settings?.subscriptionStatus || SUBSCRIPTION_STATUS.ACTIVE;
    const { currentSubsidiary, dispatchType } = searchStore;
    const { businessHours, orderMethod, futureOrdersEnabled } = currentSubsidiary;
    const { futureOrder } = order;
    const closedBySwitch = orderMethod === ORDER_METHODS.NEITHER;
    const today = getTodayString(TRANSLATION_COUNTRY);
    const todayInfoHours = businessHours.filter((el) => el.day === today)[0];
    const inPathForShowAlert = !DONT_SHOW_ALERTS.includes(window.location.pathname);
    const isOrdersPage = window.location.pathname.includes(CLIENT_PAGES.ORDERS.path);
    if (inPathForShowAlert) {
      const openingTime = dateToTime(todayInfoHours?.openingTime);
      const closingTime = dateToTime(todayInfoHours?.closingTime);
      const messageData = getTopSiteMessage(futureOrdersEnabled, openingTime, dispatchType);
      const storeIsOpen = todayInfoHours
        ? dispatchType === DISPATCH_METHODS.PICKUP
          ? currentSubsidiary?.pickUpOpen
          : currentSubsidiary?.deliveryOpen
        : false;
      if (isAccountStatusSuspended(subscriptionStatus)) {
        dispatch(ModalActions.openModal(MODALS.STORE_CLOSED));
      } else {
        dispatch(ModalActions.closeModalWithData(MODALS.TOP_SITE_MESSAGE));
      }
      if (currentSubsidiary && !storeIsOpen && !DONT_SHOW_ALERTS.includes(window.location.pathname)) {
        if (!closedBySwitch && futureOrdersEnabled) {
          if (!futureOrder?.date && !isOrdersPage) {
            dispatch(ModalActions.openModal(MODALS.FUTURE_ORDER));
          }
        } else {
          dispatch(ModalActions.openModal(MODALS.STORE_CLOSED));
        }
        if (closingTime && nowIsBeforeThan(closingTime)) {
          messageData.message = i18next('StoreInfo:storeClosedwithOpeningTime', { value: openingTime });
        }
        dispatch(ModalActions.openModalWithData(MODALS.TOP_SITE_MESSAGE, messageData));
      } else {
        dispatch(ModalActions.closeModalWithData(MODALS.TOP_SITE_MESSAGE));
      }
    } else {
      dispatch(ModalActions.closeModalWithData(MODALS.TOP_SITE_MESSAGE));
    }
  },
  getScheduledDateAvailability: (forceDispatch) => (dispatch, getState) => {
    const {
      order: { futureOrder },
      searchStore: {
        currentSubsidiary: { id, futureOrdersEnabled },
        dispatchType
      },
      cart
    } = getState();
    const productIds = mapToProductIds(cart.cart);

    if (!futureOrdersEnabled) {
      dispatch(OrderActions.setFutureOrderDate(null));
      return;
    }

    if (forceDispatch === undefined || forceDispatch) {
      dispatch({
        type: actions.GET_SCHEDULED_DATE_AVAILABILITY,
        target: SCHEDULED_DATE_AVAILABILITY,
        service: SearchStoreService.getScheduledDateAvailability,
        payload: { id, dispatchMethod: dispatchType, productIds },
        injections: [
          withPostSuccess((dispatch, response) => {
            if (futureOrder && noHoursAvailable(response.data, futureOrder)) {
              dispatch(ModalActions.openModal(MODALS.FUTURE_ORDER));
            }
          })
        ]
      });
    }
  }
};

export default actionCreators;
