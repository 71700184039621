import { Kushki as KushkiJs } from '@kushki/js';

class Kushki {
  constructor() {
    this.instance = null;
  }

  setInstance(kushkiPublicMerchantId) {
    if (!this.instance) {
      this.instance = new KushkiJs({
        merchantId: kushkiPublicMerchantId,
        inTestEnvironment: process.env.REACT_APP_ENV !== 'production',
        regional: false
      });
    }
  }
}

export const kushki = new Kushki();
