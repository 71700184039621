export const GOOGLE_MAPS_URL = 'https://www.google.com/maps/dir/?api=1';

export const GOOGLE_MAPS_SEARCH_URL = 'https://www.google.com/maps/search/?api=1';

export const GOOGLE_MAPS_STATIC_URL = 'https://maps.googleapis.com/maps/api/staticmap?';

export const STREET_NUMBER = 'street_number';

export const ADDRESS_TYPES = {
  streetNumber: ['street_number'],
  postalCode: ['postal_code'],
  street: ['street_address', 'route'],
  region: [
    'administrative_area_level_1',
    'administrative_area_level_2',
    'administrative_area_level_3',
    'administrative_area_level_4',
    'administrative_area_level_5'
  ],
  commune: [
    'locality',
    'sublocality',
    'sublocality_level_1',
    'sublocality_level_2',
    'sublocality_level_3',
    'sublocality_level_4'
  ],
  country: ['country']
};

// this is the city for santiago (area level 2). in the future this can be refactored to include all the possibles cities
export const CITY = ADDRESS_TYPES.region[1];

export const DEFAULT_MAP_ZOOM = 12;

// START Static map config

export const ZOOM_LEVEL = {
  city: 10,
  blocks: 14,
  streets: 15,
  buildings: 20
};

export const MAP_SIZE = {
  small: '300x300',
  medium: '600x600',
  vertical: '550x640'
};

export const MAP_SCALE = {
  normal: '1',
  double: '2'
};

export const QUALITY = {
  pretty: 'png32',
  fast: 'jpg',
  faster: 'jpg-baseline'
};

export const MARKER_STORE_COLOR = '0x3fae7f';

// END Static map config

export const DEFAULT_ANCHOR_ICON_SIZE = 32;
export const DEFAULT_SCALED_ICON_SIZE = 48;

export const MAPBOX_MAP_URL_DEFAULT =
  'https://api.mapbox.com/styles/v1/mercat/ckx538q3n27jz15tc16wltfyj/tiles/{z}/{x}/{y}@2x';
