import numeral from 'numeral';

import 'numeral/locales';

const defaultLocaleData = numeral.localeData('es');

export const LOCALE_DATA = [
  {
    code: 'es-cl',
    locale: { ...defaultLocaleData }
  },

  {
    code: 'es-gt',
    locale: {
      ...defaultLocaleData,
      delimiters: {
        thousands: ',',
        decimal: '.'
      }
    }
  },
  {
    code: 'es-mx',
    locale: {
      ...defaultLocaleData,
      delimiters: {
        thousands: ',',
        decimal: '.'
      }
    }
  }
];
