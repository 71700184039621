import { createTypes, completeTypes } from 'redux-recompose';

export const actions = createTypes(
  completeTypes(
    [],
    ['OPEN_MODAL', 'CLOSE_MODAL', 'OPEN_MODAL_WITH_DATA', 'CLOSE_MODAL_WITH_DATA', 'CLEAR_MODALS']
  ),
  '@@MODAL'
);

const targetName = (modalName) => `${modalName}ModalIsOpen`;

export const actionCreators = {
  openModal: (modalName) => ({
    type: actions.OPEN_MODAL,
    target: targetName(modalName)
  }),
  closeModal: (modalName) => ({
    type: actions.CLOSE_MODAL,
    target: targetName(modalName)
  }),
  openModalWithData: (modalName, data) => ({
    type: actions.OPEN_MODAL_WITH_DATA,
    target: modalName,
    payload: { data }
  }),
  closeModalWithData: (modalName) => ({
    type: actions.CLOSE_MODAL_WITH_DATA,
    target: modalName,
    payload: { open: false }
  }),
  clearModals: () => ({
    type: actions.CLEAR_MODALS
  })
};

export default actionCreators;
