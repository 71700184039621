import { createTypes, completeTypes, withPostSuccess, withPostFailure } from 'redux-recompose';

import { CouponService } from '~services/Coupons/service';

export const actions = createTypes(completeTypes(['CREATE_COUPON', 'REMOVE_COUPON']), '@@COUPONS');

const TARGET = {
  COUPON_DATA: 'coupons'
};

export const actionCreators = {
  createCoupon: (amount, { onPostSuccess, onPostFailure }) => ({
    payload: amount,
    type: actions.CREATE_COUPON,
    target: TARGET.COUPON_DATA,
    service: CouponService.createCoupon,
    injections: [
      withPostSuccess((_, response) => {
        if (onPostSuccess) onPostSuccess(response);
      }),
      withPostFailure((_, response) => {
        if (onPostFailure) onPostFailure(response);
      })
    ]
  }),
  removeCoupon: () => ({
    type: actions.REMOVE_COUPON,
    target: TARGET.COUPON_DATA
  })
};

export default actionCreators;
