import Immutable from 'seamless-immutable';
import { createReducer, completeReducer, completeState } from 'redux-recompose';
import { onSetValue } from 'redux-recompose';

import { actions } from './actions';

const stateDescription = {
  coupons: []
};

const initialState = completeState(stateDescription);

const reducerDescription = {
  primaryActions: [actions.CREATE_COUPON],
  override: { [actions.REMOVE_COUPON]: onSetValue([]) }
};

export default createReducer(new Immutable(initialState), completeReducer(reducerDescription));
