import i18next from 'i18next';

i18next.addResources('es_CL', 'CreditCardInput', {
  number: 'Número de Tarjeta',
  name: 'Nombre del titular',
  id: 'Rut del titular',
  expireDate: 'Expiración',
  code: 'Código',
  numberPlaceholder: '**** **** **** ****',
  namePlaceholder: 'Nombre del titular',
  idPlaceholder: '111111-1',
  expireDatePlaceholder: 'MM/YY',
  codePlaceholder: 'CVC',
  complianceText:
    'Los datos de tu tarjeta de crédito estan protegidos por un sistema últra seguro y nunca pasan por nuestros servidores. Tu tarjeta estará asociada tu cuenta Mercat.'
});
