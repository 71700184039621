import React from 'react';
import i18next from 'i18next';
import { func, object, bool, number } from 'prop-types';
import { useSelector } from 'react-redux';

import ErrorMessage from '~components/ErrorMessage';
import { checkoutValuesPropTypes } from '~propTypes/checkoutValues';
import Text from '~components/Text';
import { TARGETS as orderTargets } from '~redux/Order/constants';
import { PAYMENT_METHODS_NAMES } from '~constants/paymentMethods';

import styles from './styles.module.scss';
import PaymentMethod from './components/PaymentMethod';
import { PAYMENT_METHOD } from './constants';
import usePaymentMethod from './hooks/usePaymentMethod';
import CreditCards from './components/CreditCards';
import useCreditCards from './components/CreditCards/hooks/useCreditCards';
import { KushkiCardForm } from './components/KushkiCardForm';
function PaymentMethods({ setFieldValue, values, errors, avoidErrorUntilSubmit, submitCount }) {
  const currentUser = useSelector((state) => state.auth?.currentUser);

  const { currentSubsidiary } = useSelector((state) => state.searchStore);
  const futureOrder = useSelector((state) => state.order[orderTargets.FUTURE_ORDER]);
  const paymentMethods = currentSubsidiary?.paymentMethods || {};

  const {
    handleSelect,
    handleSelectCreditCard,
    methodsWithoutSubscriptionCards,
    isSubscriptionMethodsEnabled,
    isCashEnabled,
    subscriptionMethodEnabled
  } = usePaymentMethod(paymentMethods, setFieldValue);

  const { creditCardsFilteredByType, isKushkiCardSubscriptionEnabled } = useCreditCards({
    subscriptionMethodEnabled
  });

  const filteredMethodsWithoutSubscription = futureOrder?.date
    ? methodsWithoutSubscriptionCards.reduce((ac, el) => [...ac, ...(el.isOnline ? [el] : [])], [])
    : methodsWithoutSubscriptionCards;

  return (
    <div className="column space-between relative">
      <Text className={`text-emperor m-top-2 ${styles.title}`}>{i18next.t('PaymentMethods:available')}</Text>
      <div className={`row wrap m-top-5 ${styles.paymentMethodsContainer}`}>
        {filteredMethodsWithoutSubscription?.map((method) => (
          <PaymentMethod
            name={method.name}
            id={method.id}
            key={method.name}
            displayName={i18next.t(`PaymentMethods:${method.name}`)}
            iconClassName={method.icon}
            spanClassName={`text-emperor ${styles.payment}`}
            isSelected={values[PAYMENT_METHOD] === method.name}
            onSelectPaymentMethod={handleSelect(method.name)}
            imgLogo={method.logo}
            secondaryLogo={method?.secondaryLogo}
          />
        ))}
      </div>
      {values.paymentMethod === PAYMENT_METHODS_NAMES.kushki ? (
        <KushkiCardForm enableOptionSubscribeCard={isKushkiCardSubscriptionEnabled} />
      ) : null}
      {isSubscriptionMethodsEnabled && currentUser?.id && creditCardsFilteredByType.length > 0 && (
        <div className="column full-width">
          <div className="m-top-4">
            <CreditCards
              handleSelectCreditCard={handleSelectCreditCard}
              values={values}
              subscriptionMethodEnabled={subscriptionMethodEnabled}
              showAddCard={false}
            />
          </div>
        </div>
      )}
      {!isCashEnabled && (
        <Text className="text-emperor font-size-11 m-top-4">{i18next.t('PaymentMethods:cashDisabled')}</Text>
      )}
      {(avoidErrorUntilSubmit || submitCount > 0) && errors.paymentMethod && (
        <ErrorMessage className={styles.error} message={errors.paymentMethod} />
      )}
    </div>
  );
}

PaymentMethods.propTypes = {
  avoidErrorUntilSubmit: bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: object.isRequired,
  setFieldValue: func.isRequired,
  submitCount: number.isRequired,
  values: checkoutValuesPropTypes.isRequired
};

export default PaymentMethods;
