import i18next from 'i18next';

import { formatPrice } from '~utils/number';

import { COUPON_CODE } from './constants';

export const getCouponErrorMessage = ({ code, metadata }) => {
  const defaultCode = COUPON_CODE[code] || COUPON_CODE.INVALID_COUPON_CODE;
  const values =
    defaultCode === COUPON_CODE.COUPON_WITH_MINIMUM_BILL
      ? { minimum: formatPrice(metadata.value) }
      : { ...metadata };
  return i18next.t(`CouponDiscount:${defaultCode}`, values);
};
