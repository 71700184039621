import i18next from 'i18next';

i18next.addResources('es_CL', 'FullOrderDetail', {
  products: 'Productos',
  title: 'Detalle de tu compra',
  cartTotal: 'Subtotal de productos',
  discount: 'Cupón descuento',
  subtotalDiscount: 'Subtotal con descuentos',
  total: 'Total a pagar a la tienda',
  deliveryFee: 'Delivery',
  serviceFee: 'Cargo por servicio',
  subtotal: 'Subtotal con cargos',
  tip: 'Propina repartidor',
  storeTip: 'Propina a la tienda'
});
