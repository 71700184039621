import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool } from 'prop-types';

import LoginDiscounts from '~app/components/LoginDiscounts';
import GiftIcon from '~components/GiftIcon';
import { MODALS } from '~redux/Modal/constants';
import ModalActions from '~redux/Modal/actions';

import styles from './styles.module.scss';

export default function GuestUser({ isLoyaltyActivated }) {
  const dispatch = useDispatch();
  const rewardPointsName = useSelector((state) => state?.core?.settings?.loyaltyProgramParams?.name);

  const handleOpenLoginModal = () => {
    dispatch(ModalActions.openModal(MODALS.LOGIN));
  };
  return (
    <div className={styles.container}>
      <div>
        <GiftIcon primaryColor={styles.giftIconPrimary} secondaryColor={styles.giftIconSecondary} />
      </div>
      <div className="m-left-3 m-top-3">
        <LoginDiscounts
          onClick={handleOpenLoginModal}
          rewardPointsName={rewardPointsName}
          className={styles.loginContainer}
          isLoyaltyActivated={isLoyaltyActivated}
        />
      </div>
    </div>
  );
}

GuestUser.propTypes = {
  isLoyaltyActivated: bool
};
