import immutable from 'seamless-immutable';
import { createReducer, completeReducer, completeState, onReadValue } from 'redux-recompose';

import { actions as storeActions } from '../SearchStore/actions';

import { actions } from './actions';

const defaultState = completeState(
  {
    menu: [],
    upsellings: [],
    banners: [],
    fixedBar: false,
    publicMenu: null,
    disabledProducts: []
  },
  ['fixedBar']
);

const reducerDescription = {
  primaryActions: [
    actions.GET_MENU,
    actions.GET_PUBLIC_MENU,
    actions.GET_UPSELLINGS,
    actions.GET_BANNERS,
    actions.GET_STATIC_MENU,
    storeActions.GET_STORE_DATA
  ],
  override: {
    [actions.SET_FIXED_BAR]: onReadValue(),
    [storeActions.GET_STORE_DATA]: (state) => state.merge({ ...state, menuLoading: true }),
    [storeActions.GET_STORE_DATA_SUCCESS]: (state, { payload: { banners, disabledProducts } }) =>
      state.merge({ ...state, banners, disabledProducts }),
    [storeActions.GET_STORE_DATA_FAILURE]: (state) => state.merge({ ...state, menuLoading: false })
  }
};

const reducer = createReducer(immutable(defaultState), completeReducer(reducerDescription));
export default reducer;
