import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { snakeCase } from 'snake-case';

import { actionCreators as creditCardsActions } from '~redux/CreditCards/actions';
import { actionCreators as authActions } from '~redux/Auth/actions';
import LocalStorageService from '~services/LocalStorageService';
import { CLIENT_PAGES } from '~constants/pages';
import ModalActions from '~redux/Modal/actions';
import { MODALS } from '~redux/Modal/constants';
import { PAYMENT_METHODS_NAMES } from '~constants/paymentMethods';

import { onStartAddCard } from './utils';

const useCreditCards = ({ subscriptionMethodEnabled }) => {
  const dispatch = useDispatch();
  const accessToken = LocalStorageService.getAccessToken();
  const creditCards = useSelector((state) => state.auth.creditCards || []);
  const creditCardsLoading = useSelector((state) => state.auth.creditCardsLoading);
  const isProfile = useRouteMatch(CLIENT_PAGES.PROFILE.path);
  const getCreditCards = useCallback((token) => dispatch(authActions.getCreditCards(token)), [dispatch]);
  const isKushkiCardSubscriptionEnabled =
    subscriptionMethodEnabled === snakeCase(PAYMENT_METHODS_NAMES.kushkiCardSubscription);
  const handleAddCreditcard = (token, subscriptionMethodEnabled) =>
    dispatch(creditCardsActions.addCard({ token, subscriptionMethodEnabled }));
  const openAddCardModal = (accessToken, subscriptionMethodEnabled) =>
    dispatch(
      subscriptionMethodEnabled === PAYMENT_METHODS_NAMES.credomatic
        ? ModalActions.openModalWithData(`${MODALS.ADD_CARD}ModalIsOpen`, {
            accessToken,
            subscriptionMethodEnabled
          })
        : ModalActions.openModal(MODALS.ADD_CARD)
    );

  const handleOnStartAddCard = () => {
    return onStartAddCard({
      accessToken,
      subscriptionMethodEnabled,
      openAddCardModal,
      handleAddCreditcard
    });
  };

  const creditCardsFilteredByType = subscriptionMethodEnabled
    ? creditCards.filter((card) => card?.type === subscriptionMethodEnabled)
    : [];
  useEffect(() => {
    if (accessToken) {
      getCreditCards(accessToken);
    }
  }, [accessToken, dispatch, getCreditCards]);
  const handleKushkiModal = () => {
    dispatch(ModalActions.openModal(MODALS.KUSHKI));
  };
  return {
    accessToken,
    creditCardsLoading,
    isProfile,
    handleOnStartAddCard,
    creditCardsFilteredByType,
    handleKushkiModal,
    isKushkiCardSubscriptionEnabled
  };
};

export default useCreditCards;
