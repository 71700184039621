import { PAYMENT_METHODS_NAMES } from '~constants/paymentMethods';
import { hyphensToCamelCase } from '~utils/functions';

export const onStartAddCard = ({
  accessToken,
  subscriptionMethodEnabled,
  openAddCardModal,
  handleAddCreditcard
}) => {
  const subscriptionMethodEnabledName = hyphensToCamelCase(subscriptionMethodEnabled);
  const choosePayment = {
    [PAYMENT_METHODS_NAMES.credomatic]: () => openAddCardModal(accessToken, subscriptionMethodEnabled),
    [PAYMENT_METHODS_NAMES.kushkiCardSubscription]: () =>
      handleAddCreditcard(accessToken, subscriptionMethodEnabled),
    [PAYMENT_METHODS_NAMES.oneClick]: () => handleAddCreditcard(accessToken, subscriptionMethodEnabled),
    [PAYMENT_METHODS_NAMES.chek]: () => handleAddCreditcard(accessToken, subscriptionMethodEnabled)
  };

  // eslint-disable-next-line babel/no-unused-expressions
  return choosePayment[subscriptionMethodEnabledName]();
};
