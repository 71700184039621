import i18next from 'i18next';

i18next.addResources('es_CL', 'Login', {
  title: 'Ingresa',
  placeholder: 'Tu correo',
  enterYourEmail: 'Ingresa con tu correo',
  retryOtherEmail: 'Reintenta con otro correo',
  enterYourCode: 'Ingresa con tu código de verificación enviado a tu correo',
  enter: 'Ingresar',
  text: 'Recibirás un código de verificación en tu correo para ingresar',
  name: 'Nombre',
  phone: 'Teléfono',
  start: 'Comenzar',
  poweredBy: 'Powered by',
  acceptanceText: 'Continuando, estoy aceptando los ',
  termsAndConditions: ' Términos y Condiciones ',
  endingText: ' de Mercat',
  linkSended: 'Te enviamos un link a tu correo: {{email}}',
  errorCode: 'Código de verificacion incorrecto',
  error: 'Por favor ingrese sus datos para comenzar.'
});
