import { createTypes, completeTypes, withSuccess, withFailure, withPreFetch } from 'redux-recompose';
import { push } from 'connected-react-router';

import ModalActions from '~redux/Modal/actions';
import { MODALS } from '~redux/Modal/constants';
import { CLIENT_PAGES } from '~constants/pages';
import Auth from '~services/Auth/service';
import { actionCreators as authActions } from '~redux/Auth/actions';
import { PAYMENT_METHODS_NAMES } from '~constants/paymentMethods';

export const actions = createTypes(
  completeTypes([
    'ADD_CARD',
    'GET_URL_INSCRIPTION',
    'CHECK_IF_CARD_WAS_ADDED',
    'REMOVE_CARD',
    'CHECK_IF_CARD_WAS_DELETED',
    'CHECK_KUSHKI_CARD_SUBSCRIPTION'
  ]),
  '@@CREDIT_CARD'
);

export const TARGET = {
  CREDIT_CARD: 'creditCard'
};

export const actionCreators = {
  addCard: ({ token, subscriptionMethodEnabled, kushkiToken }) => ({
    payload: { token, subscriptionMethodEnabled, kushkiToken },
    type: actions.ADD_CARD,
    target: TARGET.CREDIT_CARD,
    service: Auth.addCreditCard,
    injections: [
      withPreFetch((dispatch) => {
        dispatch(ModalActions.openModal(MODALS.ORDER_CREATION_STATUS));
      }),
      withSuccess((dispatch, response) => {
        dispatch(
          actionCreators.getUrlInscription({
            token: response.data.token,
            subscriptionMethodEnabled
          })
        );
      }),
      withFailure((dispatch) => {
        dispatch(ModalActions.closeModal(MODALS.ORDER_CREATION_STATUS));
      })
    ]
  }),
  addCredomaticCard: ({ token, subscriptionMethodEnabled, card }) => ({
    payload: { token, subscriptionMethodEnabled, card: { card } },
    type: actions.ADD_CARD,
    target: TARGET.CREDIT_CARD,
    service: Auth.addCreditCard,
    injections: [
      withSuccess((dispatch, response) => {
        dispatch(actionCreators.checkIfCardWasAdded(response.data.token));
      }),
      withFailure((dispatch) => {
        dispatch(push(CLIENT_PAGES.ADD_CARD_FAILURE.path));
      })
    ]
  }),
  removeCard: ({ cardId, externalId, subscriptionMethodEnabled }) => ({
    payload: { cardId, externalId, subscriptionMethodEnabled },
    type: actions.REMOVE_CARD,
    target: TARGET.CREDIT_CARD,
    service: Auth.removeCreditCard,
    injections: [
      withPreFetch((dispatch) => {
        dispatch(ModalActions.openModal(MODALS.ORDER_CREATION_STATUS));
      }),
      withSuccess((dispatch, response) => {
        dispatch(actionCreators.checkIfCardWasDeleted(response.data.token, externalId));
      }),
      withFailure((dispatch) => {
        dispatch(ModalActions.closeModal(MODALS.ORDER_CREATION_STATUS));
        dispatch(authActions.getCreditCards(externalId));
      })
    ]
  }),
  getUrlInscription: ({ token, subscriptionMethodEnabled }) => ({
    payload: { token, service: Auth.usersJob },
    type: actions.GET_URL_INSCRIPTION,
    target: TARGET.CREDIT_CARD,
    service: Auth.processUsersJob,
    injections: [
      withSuccess((dispatch, response) => {
        if (subscriptionMethodEnabled === PAYMENT_METHODS_NAMES.oneClick) {
          return (window.location.href = `${response.data.urlWebpay}?TBK_TOKEN=${response.data.token}`);
        }
        dispatch(ModalActions.closeModal(MODALS.ORDER_CREATION_STATUS));
        dispatch(push(CLIENT_PAGES.ADD_CARD_SUCCESS.path));
      }),
      withFailure((dispatch) => {
        dispatch(ModalActions.closeModal(MODALS.ORDER_CREATION_STATUS));
        dispatch(push(CLIENT_PAGES.ADD_CARD_FAILURE.path));
      })
    ]
  }),
  checkIfCardWasAdded: (token) => ({
    payload: { token, service: Auth.usersJob },
    type: actions.CHECK_IF_CARD_WAS_ADDED,
    target: TARGET.CREDIT_CARD,
    service: Auth.processUsersJob,
    injections: [
      withSuccess((dispatch) => {
        dispatch(ModalActions.closeModal(MODALS.ADD_CARD));
        dispatch(push(CLIENT_PAGES.ADD_CARD_SUCCESS.path));
      }),
      withFailure((dispatch) => {
        dispatch(ModalActions.closeModal(MODALS.ADD_CARD));
        dispatch(push(CLIENT_PAGES.ADD_CARD_FAILURE.path));
      })
    ]
  }),
  checkIfCardWasDeleted: (token, externalId) => ({
    payload: { token, service: Auth.usersJob },
    type: actions.CHECK_IF_CARD_WAS_DELETED,
    target: TARGET.CREDIT_CARD,
    service: Auth.processUsersJob,
    injections: [
      withSuccess((dispatch) => {
        dispatch(ModalActions.closeModal(MODALS.ORDER_CREATION_STATUS));
        dispatch(authActions.getCreditCards(externalId));
      }),
      withFailure((dispatch) => {
        dispatch(ModalActions.closeModal(MODALS.ORDER_CREATION_STATUS));
        dispatch(authActions.getCreditCards(externalId));
      })
    ]
  }),
  checkKushkiCardSubscription: (token, successCb, failureCb) => ({
    payload: { token, service: Auth.usersJob },
    type: actions.CHECK_KUSHKI_CARD_SUBSCRIPTION,
    target: TARGET.CREDIT_CARD,
    service: Auth.processUsersJob,
    injections: [
      withSuccess(() => {
        if (successCb) {
          successCb();
        }
      }),
      withFailure(() => {
        if (failureCb) {
          failureCb();
        }
      })
    ]
  })
};

export default actionCreators;
