import i18next from 'i18next';

i18next.addResources('es_CL', 'ProductTags', {
  vegetarian: 'Vegetariano',
  vegan: 'Vegano',
  organic: 'Orgánico',
  spicy: 'Picante',
  cold: 'Frío',
  hot: 'Caliente',
  gluten_free: 'Sin gluten',
  lactose_free: 'Sin lactosa'
});
