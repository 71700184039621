import { shape, string, number, bool } from 'prop-types';

export const checkoutValuesPropTypes = shape({
  name: string,
  surname: string,
  email: string,
  phone: string,
  napkin: bool,
  coverly: bool,
  deliveryOption: string,
  instructions: string,
  count: number,
  paymentMethod: string,
  installmentsNumber: number,
  tip: number,
  storeTip: number
});
