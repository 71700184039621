import i18next from 'i18next';

i18next.addResources('es_CL', 'ProfileLinks', {
  login: 'Iniciar sesión',
  myProfile: 'Mi perfil',
  myOrders: 'Mis ordenes',
  closeSession: 'Cerrar sesión',
  onlineChat: 'Chat en línea',
  knowUs: 'Conócenos',
  start: 'Inicio',
  greeting: 'Hola'
});
