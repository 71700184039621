import { cdnApi, globalCdnApi } from '~config/api';

const getTermsAndConditions = () => cdnApi.get('/statements/terms_and_conditions');

const getMercatTermsAndConditions = () => globalCdnApi.get('/terms_and_conditions');

export default {
  getTermsAndConditions,
  getMercatTermsAndConditions
};
