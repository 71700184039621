import { createTypes, completeTypes } from 'redux-recompose';

export const actions = createTypes(completeTypes([], ['SET_SEARCH_VALUE']), '@@SEARCH_BAR');

export const actionCreators = {
  setSearchValue: (key, value) => ({
    type: actions.SET_SEARCH_VALUE,
    target: key,
    payload: value
  })
};

export default actionCreators;
