import React from 'react';
import cn from 'classnames';
import { oneOf, string } from 'prop-types';

import styles from './styles.module.scss';
import { VARIANT_ICONS } from './constants';

const AlertMessage = ({ variant, title, text }) => {
  return (
    <div className={cn(styles.container, styles[variant])}>
      <span className={cn('material-icons', styles.icon)}>{VARIANT_ICONS[variant]}</span>
      <div className={styles.textContainer}>
        {title && <span className={cn(styles.title, 'm-bottom-1')}>{title}</span>}
        {text && <span className={styles.text}>{text}</span>}
      </div>
    </div>
  );
};

AlertMessage.propTypes = {
  text: string,
  title: string,
  variant: oneOf(['info', 'error', 'warning', 'success'])
};

export default AlertMessage;
