export const TEMPLATE_MODELS = {
  SUSHI: 'Sushi',
  GENERIC: 'Generic',
  BURGERS: 'Burgers'
};

export const COMPONENT_ORIENTATION = {
  TOP: 'top',
  BOTTOM: 'bottom',
  RIGHT: 'right',
  LEFT: 'left'
};
