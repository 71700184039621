import { setDatalayer } from '~utils/document';

const DataLayerService = {
  addPurchase: (data) => {
    setDatalayer({
      dataLayerName: 'dataLayer',
      dataLayer: {
        event: 'purchase',
        ecommerce: {
          products: data.items
        },
        purchase: {
          actionField: {
            action: 'purchase',
            currency: data.totalPriceCurrency,
            dispatch: data.dispatchMethod,
            id: data.id,
            revenue: data.totalPrice
          }
        },
        currency: data.totalPriceCurrency,
        revenue: data.totalPrice
      }
    });
    return Promise.resolve({ ok: true, data: { data } });
  },
  addPageView: (data) => {
    setDatalayer({
      dataLayerName: 'dataLayer',
      dataLayer: {
        event: 'page-update',
        ...data
      }
    });
    return Promise.resolve({ ok: true, data: {} });
  },
  inCheckoutEvent: () => {
    setDatalayer({
      dataLayerName: 'dataLayer',
      dataLayer: {
        event: 'checkout'
      }
    });
    return Promise.resolve({ ok: true, data: {} });
  },
  addToCart: ({ item, currency, revenue }) => {
    setDatalayer({
      dataLayerName: 'dataLayer',
      dataLayer: {
        event: 'addToCart',
        ecommerce: {
          products: item
        },
        currency: currency,
        revenue: revenue
      }
    });
    return Promise.resolve({ ok: true, data: {} });
  }
};

export default DataLayerService;
