import React from 'react';
import cn from 'classnames';
import { string, func, bool, shape } from 'prop-types';

import Text from '~components/Text';

import ErrorMessage from '../ErrorMessage';

import styles from './styles.module.scss';

function Input({
  name,
  title,
  containerStyle,
  spanStyle,
  inputStyle,
  placeholder,
  onChange,
  icon,
  type,
  value,
  error,
  prefix,
  readOnly,
  inputClassName,
  maxlength,
  inputContainer
}) {
  return (
    <div className={cn('space-between middle', containerStyle)} {...inputContainer}>
      {title && <div className={`bold text-emperor m-bottom-2 ${spanStyle}`}>{title}</div>}
      {icon && <i className={`${icon} ${styles.icon} row center middle m-right-2`} />}
      <div
        className={cn(inputStyle, 'row middle', styles.inputContainer, {
          [styles.inputError]: error
        })}>
        {prefix && <Text className={`${styles.input} m-right-1`}>{prefix}</Text>}
        <input
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          type={type}
          value={value}
          className={`full-width ${inputClassName} ${styles.input} focus:outline-none`}
          readOnly={readOnly}
          maxLength={maxlength}
        />
      </div>
      <ErrorMessage className={styles.error} message={error} />
    </div>
  );
}

Input.defaultProps = {
  containerStyle: '',
  inputClassName: '',
  inputStyle: '',
  placeholder: '',
  spanStyle: '',
  type: 'text'
};

Input.propTypes = {
  error: string.isRequired,
  name: string.isRequired,
  title: string.isRequired,
  value: string.isRequired,
  onChange: func.isRequired,
  containerStyle: string,
  icon: string,
  inputClassName: string,
  inputContainer: shape({
    id: string
  }),
  inputStyle: string,
  maxlength: string,
  placeholder: string,
  prefix: string,
  readOnly: bool,
  spanStyle: string,
  type: string
};

export default Input;
