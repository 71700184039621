import api from '~config/api';
import { objToQueryString } from '~utils/parseUrl';
import MenuService from '~services/Menu/service';

const SearchStoreService = {
  getNear: (data) => api.get('/v2/stores/pick_up', data),
  getStoreDelivery: (data) => api.get('/v2/stores/delivery', data),
  getStores: (data) => api.get('/v2/stores', data),
  getStore: (id) => api.get(`/v2/stores/${id}`),
  getUserAddress: () => api.get('/v1/addresses'),
  getUsedCoupons: () => api.get('/v1/users/used_coupons'),
  getCompanies: () => api.get('v1/users/companies'),
  getScheduledDateAvailability: ({ id, dispatchMethod, productIds }) =>
    api.get(
      `v1/stores/${id}/scheduled_date_availability?${objToQueryString({
        dispatch_method: dispatchMethod,
        product_ids: productIds
      })}`
    ),
  getStoreData: ({ id, dispatchMethod }) =>
    Promise.all([
      SearchStoreService.getStore(id),
      MenuService.getBanners({ dispatchMethod, storeId: id, platform: 'web' }),
      MenuService.getDisabledsProducts(id)
    ])
      .then(([storeRes, bannersRes, disabledProductsRes]) => {
        if (storeRes.ok && bannersRes.ok && disabledProductsRes.ok) {
          const store = storeRes.data;
          const banners = bannersRes.data;
          const disabledProducts = disabledProductsRes.data;

          return {
            ok: true,
            data: { store, banners, disabledProducts }
          };
        }

        return {
          ok: false,
          data: {}
        };
      })
      .catch((res) => ({ ok: false, data: res }))
};

export default SearchStoreService;
