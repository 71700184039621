import { STATUS_CODES } from '~constants/statusCodes';

export const DEFAULT_INTERVAL = 1000;
const DEFAULT_TIMEOUT = 25000;

const pollResource = (service, interval, timeout) => {
  const endTime = new Date().getTime() + timeout;

  const pollingRequest = (resolve, reject) => {
    service()
      .then((response) => {
        if (response.status === STATUS_CODES.NO_CONTENT && new Date().getTime() < endTime) {
          setTimeout(pollingRequest, interval, resolve, reject);
        } else {
          resolve(response.data ? response : { ok: false });
        }
      })
      .catch(resolve);
  };

  return new Promise(pollingRequest);
};

const poll = async (request, polleableService, interval = DEFAULT_INTERVAL, timeout = DEFAULT_TIMEOUT) => {
  const response = await request;
  return response.ok ? pollResource(() => polleableService(response.data), interval, timeout) : response;
};

const singlePoll = (polleableService, data, interval = DEFAULT_INTERVAL, timeout = DEFAULT_TIMEOUT) =>
  pollResource(() => polleableService(data), interval, timeout);

export default { poll, singlePoll };
