import React, { useContext, useState } from 'react';
import { node, string, bool, func, object, oneOf } from 'prop-types';
import cn from 'classnames';

import RadioGroupContext from '~components/RadioGroup/context';
import ItemQuantity from '~components/ItemQuantity';

import styles from './styles.module.scss';
import RadioText, { SIZES } from './components/RadioText';

function RadioOption({
  children,
  className = '',
  labelClassName = '',
  label,
  id,
  disabled = false,
  disabledText = '',
  item,
  useCheck,
  size,
  ...props
}) {
  const { name, handleChange, groupDisabled, selectedValue, error, limit, mapInitialValue } = useContext(
    RadioGroupContext
  );
  const checked = mapInitialValue(selectedValue) === id;
  const [quantityItemChecked, setQuantityItemChecked] = useState(selectedValue?.[0]?.quantity > 1);
  const isMultiple = checked && quantityItemChecked;
  const itemMax = item.maxQuantityModifiers ? item.maxQuantityModifiers : limit;
  const onClick = () => {
    if (item.maxQuantityModifiers && item.maxQuantityModifiers > 0) {
      setQuantityItemChecked(true);
    }
    handleChange(id, { ...item, quantity: 1 })();
  };
  return (
    <div className={cn('row full-width middle space-between m-top-2 m-bottom-2', className)}>
      {isMultiple ? (
        <div
          className={cn('row middle', {
            [styles.errors]: error
          })}>
          <ItemQuantity
            initialQuantity={item.quantity}
            maxQuantityCondition={(quantity) => itemMax <= quantity || quantity >= limit}
            handleChange={(quantity) => handleChange(id, { ...item, quantity })}
            setQuantityItemChecked={setQuantityItemChecked}
          />
          <RadioText
            checked={checked}
            styles={styles}
            error={error}
            isMultiple={isMultiple}
            label={label}
            disabled={disabled}
            disabledText={disabledText}
            labelClassName={labelClassName}
            useCheck={useCheck}
            size={size}
          />
        </div>
      ) : (
        <button
          {...props}
          name={name}
          type="button"
          value={id}
          disabled={disabled || groupDisabled || isMultiple}
          onClick={onClick}
          className={cn('row middle', styles.buttonContainer, {
            [styles.errors]: error
          })}>
          <RadioText
            checked={checked}
            styles={styles}
            error={error}
            isMultiple={isMultiple}
            label={label}
            disabled={disabled}
            disabledText={disabledText}
            labelClassName={labelClassName}
            useCheck={useCheck}
            size={size}
          />
        </button>
      )}
      {children}
    </div>
  );
}
RadioOption.propTypes = {
  children: node,
  className: string,
  disabled: bool,
  disabledText: string,
  id: string,
  inputClassName: string,
  // eslint-disable-next-line react/forbid-prop-types
  item: object,
  label: string,
  labelClassName: string,
  size: oneOf(Object.values(SIZES)),
  useCheck: bool,
  onChange: func
};

export { SIZES };
export default RadioOption;
