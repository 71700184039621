import React, { useRef, useEffect } from 'react';
import { bool, node, shape, string } from 'prop-types';
import { useSelector } from 'react-redux';

import LottieIcon from '~components/LottieIcon';

function LoadingWrapper({
  loading,
  refreshed,
  children,
  withInitialLoading = false,
  className = '',
  iconProps
}) {
  const initialLoading = useRef(withInitialLoading);
  useEffect(() => {
    if (initialLoading.current && loading) {
      initialLoading.current = false;
    }
  }, [loading]);

  const { settings } = useSelector((state) => state.core);

  return initialLoading.current || (loading && !refreshed) ? (
    <LottieIcon
      icon={settings ? settings.animationLoader : ''}
      isLoading
      className={className}
      {...iconProps}
    />
  ) : (
    <>{children}</>
  );
}

LoadingWrapper.propTypes = {
  loading: bool.isRequired,
  children: node,
  className: string,
  iconProps: shape({}),
  refreshed: bool,
  withInitialLoading: bool
};

export default LoadingWrapper;
