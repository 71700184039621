import { replaceColor } from 'lottie-colorify';

export const defaultAnimationColor = '#ff4757';

const getOptions = ({ VARIANTS, icon, loop = false, autoplay = true, rendererSettings, primaryColor }) => ({
  loop,
  autoplay,
  animationData: replaceColor(
    defaultAnimationColor,
    primaryColor || defaultAnimationColor,
    VARIANTS[icon] || VARIANTS.preparingFoodCooking
  ),
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
    ...rendererSettings
  }
});

export default getOptions;
