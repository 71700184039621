/* eslint-disable no-magic-numbers */
import numeral from 'numeral';

import { CURRENCY_SYMBOL, ISO_COUNTRY } from '~constants/environment';

const FORMAT_TYPES = {
  ROUND: 'round',
  DECIMAL: 'decimal'
};

const DECIMAL_COUNTRY_USE = {
  cl: FORMAT_TYPES.ROUND,
  gt: FORMAT_TYPES.DECIMAL,
  mx: FORMAT_TYPES.DECIMAL
};

const DECIMAL_METHODS = {
  round: '0,0',
  decimal: '0,0.00'
};

const defaultCountry = 'cl';
const defaultCurrency = '$';

export const formatPrice = (
  price,
  currencySymbol = CURRENCY_SYMBOL || defaultCurrency,
  isoCountry = ISO_COUNTRY || defaultCountry
) => `${currencySymbol} ${numeral(price).format(DECIMAL_METHODS[DECIMAL_COUNTRY_USE[isoCountry]])}`;

export const roundMount = (mount) => Number(mount).toFixed(0);

export const getPercentage = (percent, amount) => Math.round((percent * amount) / 100);
