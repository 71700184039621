import Immutable from 'seamless-immutable';
import { createReducer, completeReducer, completeState } from 'redux-recompose';

import { actions, TARGET } from './actions';

const stateDescription = {
  [TARGET.CREDIT_CARD]: null
};

const initialState = completeState(stateDescription);

const reducerDescription = {
  primaryActions: [actions.ADD_CARD, actions.REMOVE_CARD],
  override: {}
};

export default createReducer(new Immutable(initialState), completeReducer(reducerDescription));
