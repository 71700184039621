import i18next from 'i18next';

i18next.addResources('es_CL', 'KushkiCardForm', {
  name: 'Nombre del titular',
  card: 'Número de tarjeta',
  expirationDate: 'Expiración',
  month: 'Mes',
  year: 'Año',
  cvc: 'CVC',
  signIn: 'Inicia sesión',
  signInMessage: 'para guardar tarjeta.',
  error: 'Hubo un error al momento de procesar su tarjeta, por favor revise los datos e intente nuevamente.',
  errorForm: 'Los datos de la tarjeta no son válidos, reviselos e intente nuevamente.',
  errorCardNumber: 'Número de tarjeta inválido',
  errorCardExpirationDate: 'Fecha de expiración inválida',
  errorCardCVC: 'CVC inválido',
  subscribeCard: 'Suscribir tarjeta',
  subscribeCardLoading: 'Suscribiendo tarjeta'
});
