import React, { lazy } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';

import { CLIENT_PAGES } from '~constants/pages';
import { history } from '~redux/store';
import DataLayerService from '~services/DataLayer/service';
import { IMPORTS_NAMES, lazyRetry } from '~utils/dynamicImports';

import Suspense from '../Suspense';

import styles from './styles.module.scss';

const Commerce = lazy(() => lazyRetry(() => import('~screens/Commerce'), IMPORTS_NAMES.commerce));
const Menu = lazy(() => lazyRetry(() => import('~app/screens/Menu'), IMPORTS_NAMES.menu));

function AppRoutes() {
  history.listen((_, action) => {
    DataLayerService.addPageView({ 'path-update': window.location.pathname });
    if (action === 'PUSH') {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  });
  return (
    <ConnectedRouter history={history}>
      <div className={`column ${styles.container}`}>
        <Suspense>
          <Switch>
            <Route path={CLIENT_PAGES.MENU.path} exact component={Menu} />
            <Route path="/" exact={false} component={Commerce} />
          </Switch>
        </Suspense>
      </div>
    </ConnectedRouter>
  );
}

export default AppRoutes;
