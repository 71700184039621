import { ENVIRONMENT, ROLLBAR_ACCESS_TOKEN, CODE_VERSION, DEV_ENVIRONMENT } from '~constants/environment';

function transform(payload) {
  const trace = payload.body.trace;
  const locRegex = /^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/;
  if (trace && trace.frames) {
    for (let i = 0; i < trace.frames.length; i++) {
      const filename = trace.frames[i].filename;
      if (filename) {
        const [, , protocol, , , path] = filename.match(locRegex);
        trace.frames[i].filename = protocol + '://dynamichost' + path;
        console.log(trace.frames[i].filename);
      }
    }
  }
}
export const rollbarConfig = {
  accessToken: ROLLBAR_ACCESS_TOKEN,
  environment: ENVIRONMENT,
  enabled: ENVIRONMENT !== DEV_ENVIRONMENT,
  payload: {
    environment: ENVIRONMENT,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: CODE_VERSION,
        guess_uncaught_frames: true
      }
    }
  },
  transform
};
