import qs from 'qs';

import { isADate, formatYYYYMMDD } from './date';

export const getQueryParams = (queryString) => qs.parse(queryString, { ignoreQueryPrefix: true });

export const getQueryString = (queryObject) => qs.stringify(queryObject, { addQueryPrefix: true });

export const stripTrailingSlash = (str) => (str.endsWith('/') ? str.slice(0, -1) : str);

export const objToQueryString = (obj) => {
  const keyValuePairs = [];
  Object.keys(obj).map(
    (key) =>
      obj[key] && keyValuePairs.push(`${key}=${isADate(obj[key]) ? formatYYYYMMDD(obj[key]) : obj[key]}`)
  );
  return keyValuePairs.join('&');
};
