import { capitalize } from '~utils/string';

const cartMapperAnalytics = (cart, brand, currency) =>
  cart.reduce(
    (acc, { count, product: { id, name }, category, basePrice, items }) => [
      ...acc,
      {
        name,
        id,
        price: basePrice,
        brand,
        category,
        ...(Object.values(items).length > 0 && {
          variant: Object.values(items)
            .reduce((variants, item) => [...variants, item.map(({ name }) => capitalize(name))], [])
            .join(', ')
        }),
        quantity: count,
        currency
      }
    ],
    []
  );

export const checkoutObject = (cart, brand, currency) => ({
  event: 'checkout',
  ecommerce: {
    checkout: {
      actionField: { step: 1 },
      products: cartMapperAnalytics(cart, brand, currency)
    }
  }
});

export const ga4mappers = {
  addToCart: ({ brand, category, currency, id, name, price, quantity }) => {
    return {
      currency: currency,
      items: [
        {
          item_id: id,
          item_name: name,
          item_brand: brand,
          item_category: category,
          price: price,
          currency: currency,
          quantity: quantity
        }
      ],
      value: price
    };
  },
  purchase: (cart, commerceName, id, revenue, currency) => {
    return {
      affiliation: commerceName,
      currency: currency,
      transaction_id: id,
      items: cart.reduce((ac, { productId, name, price, quantity }) => {
        return [
          ...ac,
          {
            item_id: productId,
            item_name: name,
            item_brand: commerceName,
            price: price,
            currency: currency,
            quantity: quantity
          }
        ];
      }, []),
      value: revenue
    };
  }
};
