import { createTypes, completeTypes } from 'redux-recompose';

import { addAction, clearAction } from './mappers';

export const actions = createTypes(
  completeTypes(['SET_NEXT_ACTION'], ['CLEAR_ACTION', 'CLEAR_ALL_ACTIONS_BY_TARGET']),
  '@@NEXT_UP'
);

export const targets = {
  NEXT_ACTIONS: 'nextActions'
};

const actionCreators = {
  clearAction: (action) => (dispatch, getState) =>
    dispatch({
      type: actions.CLEAR_ACTION,
      payload: clearAction(action, getState()),
      target: targets.NEXT_ACTIONS
    }),
  setNextAction: (action) => (dispatch, getState) =>
    dispatch({
      type: actions.SET_NEXT_ACTION,
      payload: addAction(action, getState()),
      target: targets.NEXT_ACTIONS
    }),
  clearAllActionsByTarget: (target) => (dispatch, getState) => {
    const newActions = getState().nextUp.nextActions.filter((action) => action.tagert === target);
    dispatch({
      type: actions.CLEAR_ALL_ACTIONS_BY_TARGET,
      payload: [...newActions],
      target: targets.NEXT_ACTIONS
    });
  }
};

export default actionCreators;
