import { object, string, bool } from 'yup';
import i18next from 'i18next';
import cardValidator from 'card-validator';

import { ISO_COUNTRY } from '~constants/environment';

import { PAYMENT_METHODS_NAMES } from '../constants/paymentMethods';

const REGEX = {
  ONLY_NUMBERS: /^[0-9 +]+$/,
  NORMAL_CHARACTERS: new RegExp('^([a-z A-Z ÑñáÁéÉíÍóÓúÚüÜçÇ]*)$'),
  NOT_ONLY_WHITE_SPACES: new RegExp('^(?!\\s*$).+'),
  NOT_SPACES_AT_BEGIN_OR_END: new RegExp('^[^\\s]+(\\s+[^\\s]+)*$')
};

const PHONE_VALIDATION = {
  default: { length: 8 },
  cl: { length: 8 }
};

const NAME_VALIDATION = {
  min: 3,
  max: 60
};

export const VALIDATIONS = {
  email: string()
    .email(i18next.t('Field:invalid', { field: i18next.t('Field:email') }))
    .required(i18next.t('Field:required', { field: i18next.t('Field:email') })),
  name: string()
    .required(i18next.t('Field:required', { field: i18next.t('Field:name') }))
    .min(
      NAME_VALIDATION.min,
      i18next.t('Field:min', {
        field: i18next.t('Field:name'),
        min: NAME_VALIDATION.min
      })
    )
    .max(
      NAME_VALIDATION.max,
      i18next.t('Field:max', {
        field: i18next.t('Field:name'),
        max: NAME_VALIDATION.max
      })
    )
    .matches(REGEX.NOT_ONLY_WHITE_SPACES, i18next.t('Field:matches', { field: i18next.t('Field:name') }))
    .matches(REGEX.NORMAL_CHARACTERS, i18next.t('Field:matches', { field: i18next.t('Field:name') }))
    .matches(REGEX.NOT_SPACES_AT_BEGIN_OR_END, i18next.t('Field:spaceAtBeginOrEnd')),
  surname: string().required(i18next.t('Field:required', { field: i18next.t('Field:name') })),
  termsAndConditions: bool()
    .required(i18next.t('Field:termsAndConditions'))
    .oneOf([true], i18next.t('Field:termsAndConditions')),
  phone: string()
    .required(i18next.t('Field:required', { field: i18next.t('Field:phone') }))
    .length(
      PHONE_VALIDATION?.[ISO_COUNTRY]?.length || PHONE_VALIDATION.default.length,
      i18next.t('Field:length', {
        field: i18next.t('Field:phone'),
        length: PHONE_VALIDATION[ISO_COUNTRY]?.length || PHONE_VALIDATION.default.length
      })
    )
    .matches(REGEX.ONLY_NUMBERS, i18next.t('Field:matches', { field: i18next.t('Field:phone') })),
  paymentMethod: string()
    .typeError(i18next.t('Field:required'))
    .required(i18next.t('Field:required', { field: i18next.t('Field:paymentMethod') })),
  deliveryOption: string().required(
    i18next.t('Field:required', { field: i18next.t('Field:deliveryOption') })
  ),
  cardName: string().when('paymentMethod', {
    is: PAYMENT_METHODS_NAMES.kushki,
    then: (schema) =>
      schema
        .required(
          i18next.t('Field:required', {
            field: i18next.t('KushkiCardForm:name')
          })
        )
        .min(
          NAME_VALIDATION.min,
          i18next.t('Field:min', {
            field: i18next.t('KushkiCardForm:name'),
            min: NAME_VALIDATION.min
          })
        )
        .max(
          NAME_VALIDATION.max,
          i18next.t('Field:max', {
            field: i18next.t('KushkiCardForm:name'),
            max: NAME_VALIDATION.max
          })
        )
        .matches(
          REGEX.NOT_ONLY_WHITE_SPACES,
          i18next.t('Field:matches', {
            field: i18next.t('KushkiCardForm:name')
          })
        )
        .matches(
          REGEX.NORMAL_CHARACTERS,
          i18next.t('Field:matches', {
            field: i18next.t('KushkiCardForm:name')
          })
        )
  }),

  cardNumber: string().when('paymentMethod', {
    is: PAYMENT_METHODS_NAMES.kushki,
    then: (schema) =>
      schema
        .typeError(i18next.t('Field:required'))
        .required(
          i18next.t('Field:required', {
            field: i18next.t('KushkiCardForm:card')
          })
        )
        .test({
          name: 'cardNumber',
          test: (value) => cardValidator.number(value, { maxLength: 16 }).isValid,
          message: i18next.t('KushkiCardForm:errorCardNumber')
        })
  }),
  cardExpirationDate: string().when('paymentMethod', {
    is: PAYMENT_METHODS_NAMES.kushki,
    then: (schema) =>
      schema
        .typeError(i18next.t('Field:required'))
        .required(
          i18next.t('Field:required', {
            field: i18next.t('KushkiCardForm:expirationDate')
          })
        )
        .test({
          name: 'cardExpirationDate',
          test: (value) => cardValidator.expirationDate(value).isValid,
          message: i18next.t('KushkiCardForm:errorCardExpirationDate')
        })
  }),
  cardCVC: string().when('paymentMethod', {
    is: PAYMENT_METHODS_NAMES.kushki,
    then: (schema) =>
      schema
        .typeError(i18next.t('Field:required'))
        .required(
          i18next.t('Field:required', {
            field: i18next.t('KushkiCardForm:cvc')
          })
        )
        .test({
          name: 'cardExpirationDate',
          test: function (value) {
            const cardData = cardValidator.number(this.parent.cardNumber, {
              maxLength: 16
            });
            return cardValidator.cvv(value, cardData.isValid ? cardData.card.code.size : undefined).isValid;
          },
          message: i18next.t('KushkiCardForm:errorCardCVC')
        })
  })
};

export const buildValidation = (validations) => object().shape(validations);
