export const ALERT_VARIANTS = {
  info: 'info',
  error: 'error',
  success: 'success',
  warning: 'warning'
};

export const VARIANT_ICONS = {
  [ALERT_VARIANTS.info]: 'info_outline',
  [ALERT_VARIANTS.error]: 'error_outline',
  [ALERT_VARIANTS.success]: 'check_circle',
  [ALERT_VARIANTS.warning]: 'warning_amber'
};
