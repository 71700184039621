import React from 'react';
import { string, func } from 'prop-types';
import Emoji from 'react-emoji-render';

function Text({ className, children, onClick, ...props }) {
  return <Emoji onClick={onClick} text={`${children}`} className={className} {...props} />;
}

Text.propTypes = {
  className: string,
  onClick: func
};

export default Text;
