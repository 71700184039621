import Immutable from 'seamless-immutable';
import { createReducer, completeReducer, completeState } from 'redux-recompose';
import onReadValue from 'redux-recompose/lib/effects/onReadValue';

import { DEFAULT_STORE_ID } from '~constants/environment';
import { TEMPLATE_MODELS } from '~constants/templates';

import { actions } from './actions';

const stateDescription = {
  settings: null,
  settingsLoading: true,
  tenant: '',
  templateModel: TEMPLATE_MODELS.GENERIC,
  defaultStoreId: DEFAULT_STORE_ID
};

const initialState = completeState(stateDescription, ['settingsLoading']);

const reducerDescription = {
  primaryActions: [
    actions.GET_SETTINGS,
    actions.GET_TENANT,
    actions.SET_TEMPLATE,
    actions.GET_DEFAULT_STORE_ID
  ],
  override: {
    [actions.SET_TEMPLATE]: onReadValue(),
    [actions.SET_DEFAULT_STORE_ID]: onReadValue()
  }
};

export default createReducer(new Immutable(initialState), completeReducer(reducerDescription));
