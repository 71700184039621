import i18next from 'i18next';

i18next.addResources('es_CL', 'RewardPoints', {
  title: 'Canjea hasta {{amount}} en {{rewardPointsName}}. ',
  pointsAcummulated: 'Tienes {{rewardPointsValue}} acumulados.',
  howManyPoints: '¿Cuánto quieres canjear?',
  notEnoughPoints: 'No tienes tantos {{rewardPointsName}}.',
  minAmountToExchange: 'El monto mínimo a canjear es de {{amount}}.',
  maxAmountToExchange: 'El monto máximo a canjear es de {{amount}}.',
  notDiscount: 'No aplicaremos descuentos a tu compra.',
  validCoupon: 'Descuento aplicado correctamente.',
  remove: 'Remover',
  apply: 'Aplicar',
  rewardHigherThanTotalPrice: 'El monto a canjear no puede superar el total a pagar.',
  errorResponse: 'Hubo un error al intentar canjear los puntos.',
  apiDefaultError: 'Ocurrió un error. Te enviamos un correo para solucionarlo.',
  disclaimerNotEnoughPoints: 'No tienes {{rewardPointsName}} suficientes para canjear.',
  disclaimerDescription: ' Realiza una compra para acumular puntos automáticamente.',
  disclaimerCouponUsed:
    'Aplicaste el descuento máximo para esta compra. Usa tus {{rewardPointsName}} para tu próximo pedido.'
});
