import { create } from 'apisauce';
import cardValidator from 'card-validator';

import api, { apiSetup, HEADERS } from '~config/api';
import PollingService from '~services/PollingService';
import { retry } from '~utils/functions';
import { API_URL } from '~constants/environment';
import { tzDataBaseName } from '~utils/date';
import getMobileOperatingSystem from '~utils/userAgent';

import { kushki } from '../../initializer/kushki';

import { OFFLINE_RETRY_TIMES } from './constants';

export const OrderService = {
  checkOrder: ({ token }) => {
    const { headers } = api;
    const temporalApi = create({
      baseURL: API_URL,
      headers: {
        [HEADERS.JOB_AUTHORIZATION]: token,
        [HEADERS.TENANT]: headers[HEADERS.TENANT],
        [HEADERS.PLATFORM]: headers[HEADERS.PLATFORM]
      }
    });
    apiSetup(temporalApi);
    return temporalApi.get('v1/orders_job');
  },
  getOrders: () => api.get('v1/orders'),
  getLastOrder: (token) => {
    const { headers } = api;
    const temporalApi = create({
      baseURL: API_URL,
      headers: {
        [HEADERS.AUTHORIZATION]: token,
        [HEADERS.TENANT]: headers[HEADERS.TENANT]
      }
    });
    apiSetup(temporalApi);
    return temporalApi.get('v1/orders/last');
  },
  getActiveOrders: () => api.get('v1/orders/active'),
  createOrder: (order) => {
    const isOfflinePayment = !order.onlinePayment;
    const retriesLeft = isOfflinePayment ? OFFLINE_RETRY_TIMES : 0;
    const request = () =>
      PollingService.poll(
        api.post('v1/orders', order, {
          headers: { [HEADERS.TIMEZONE]: order.timeZone || tzDataBaseName }
        }),
        OrderService.checkOrder
      );
    return retry(request, retriesLeft);
  },
  webPayStartPayment: ({ id, redirectUrl }) =>
    PollingService.poll(
      api.post(`/v1/orders/${id}/payments/webpay`, { redirectUrl }),
      OrderService.checkOrder
    ),
  getnetStartPayment: ({ id, redirectUrl }) =>
    PollingService.poll(
      api.post(`/v1/orders/${id}/payments/getnet`, { redirectUrl }),
      OrderService.checkOrder
    ),
  sodexoStartPayment: ({ id, redirectUrl }) =>
    PollingService.poll(
      api.post(`/v1/orders/${id}/payments/sodexo`, { redirectUrl }),
      OrderService.checkOrder
    ),
  chekStartPayment: ({ id }) =>
    PollingService.poll(api.post(`/v1/orders/${id}/payments/chek`), OrderService.checkOrder),
  mercadoPagoStartPayment: ({ id, redirectUrl }) =>
    PollingService.poll(
      api.post(`/v1/orders/${id}/payments/mercado_pago`, { redirectUrl }),
      OrderService.checkOrder
    ),
  qrStartPayment: ({ id, payment, redirectUrl }) => {
    const channel = getMobileOperatingSystem() === 'unknown' ? 'WEB' : 'MOBILE';
    const data = redirectUrl ? { redirectUrl, channel } : {};

    return PollingService.poll(
      api.post(`/v1/orders/${id}/payments/${payment}`, data),
      OrderService.checkOrder
    );
  },
  oneClickAuthorize: (orderId, idCard, installmentsNumber, token) => {
    const { headers } = api;
    const temporalApi = create({
      baseURL: API_URL,
      headers: {
        [HEADERS.TENANT]: headers[HEADERS.TENANT],
        [HEADERS.AUTHORIZATION]: token
      }
    });
    apiSetup(temporalApi);
    return temporalApi.post(
      `/v1/orders/${orderId}/payments/oneclick/authorize?card_id=${idCard}&installments_number=${installmentsNumber}`
    );
  },
  oneClickStartPayment: ({ orderId, idCard, installmentsNumber, token }) =>
    PollingService.poll(
      OrderService.oneClickAuthorize(orderId, idCard, installmentsNumber, token),
      OrderService.checkOrder
    ),
  kushkiCardSubscriptionAuthorize: (orderId, idCard, token, kushkiDeviceToken) => {
    const { headers } = api;
    const temporalApi = create({
      baseURL: API_URL,
      headers: {
        [HEADERS.TENANT]: headers[HEADERS.TENANT],
        [HEADERS.AUTHORIZATION]: token
      }
    });
    apiSetup(temporalApi);
    return temporalApi.post(`/v1/orders/${orderId}/payments/kushki_card_subscription`, {
      card_id: idCard,
      payment_token: kushkiDeviceToken
    });
  },
  kushkiCardSubscriptionStartPayment: ({ orderId, idCard, token, kushkiDeviceToken }) =>
    PollingService.poll(
      OrderService.kushkiCardSubscriptionAuthorize(orderId, idCard, token, kushkiDeviceToken),
      OrderService.checkOrder
    ),
  kuskiStartPayment: ({ id, paymentToken }) =>
    PollingService.poll(
      api.post(`/v1/orders/${id}/payments/kushki_card`, {
        payment_token: paymentToken
      }),
      OrderService.checkOrder
    ),
  credomaticStartPayment: ({ token, orderId, cardId, cvc }) =>
    PollingService.poll(
      api.post(`/v1/orders/${orderId}/payments/credomatic`, {
        token,
        cardId,
        cvc
      }),
      OrderService.checkOrder
    ),
  getCurrentOrder: (token) => PollingService.poll({ ok: true, data: { token } }, OrderService.checkOrder),
  getQuestions: ({ orderId, userId }) => {
    const { headers } = api;
    const temporalApi = create({
      baseURL: API_URL,
      headers: {
        [HEADERS.USER_ID]: userId,
        [HEADERS.TENANT]: headers[HEADERS.TENANT]
      }
    });
    apiSetup(temporalApi);
    return temporalApi.get(`v1/orders/${orderId}/poll`);
  },
  createAnswers: ({ answers, userId }) => {
    const { headers } = api;
    const temporalApi = create({
      baseURL: API_URL,
      headers: {
        [HEADERS.USER_ID]: userId,
        [HEADERS.TENANT]: headers[HEADERS.TENANT]
      }
    });
    apiSetup(temporalApi);
    return temporalApi.post('/v1/answers/mass_create', { ...answers });
  },
  getKushkiToken: ({ total, kushkiPublicMerchantId, currency, order }) => {
    return new Promise((resolve) => {
      kushki.setInstance(kushkiPublicMerchantId);
      const expirationDate = cardValidator.expirationDate(order.cardExpirationDate);
      const totalWithTip = total + (order.tip ? order.tip : 0);

      kushki.instance.requestToken(
        {
          amount: Math.floor(totalWithTip),
          currency,
          card: {
            name: order.cardName.toString().trim(),
            number: order.cardNumber.toString(),
            cvc: order.cardCvc.toString(),
            expiryMonth: expirationDate.month.toString(),
            expiryYear: expirationDate.year.toString()
          }
        },
        (kushkiResponse) => {
          if (!kushkiResponse.code) {
            resolve({ token: kushkiResponse.token, ok: true });
          }
          resolve({
            ok: false,
            code: kushkiResponse.code
          });
        }
      );
    });
  },
  getKushkiDeviceToken: ({ cardToken, kushkiPublicMerchantId }) => {
    return new Promise((resolve) => {
      kushki.setInstance(kushkiPublicMerchantId);

      kushki.instance.requestDeviceToken(
        {
          subscriptionId: cardToken
        },
        (kushkiResponse) => {
          if (!kushkiResponse.code) {
            resolve({ token: kushkiResponse.token, ok: true });
          }
          resolve({
            ok: false,
            code: kushkiResponse.code
          });
        }
      );
    });
  },
  getKushkiSubscripionToken: ({ currency, order, kushkiPublicMerchantId = null }) => {
    if (!kushki.instance) {
      kushki.setInstance(kushkiPublicMerchantId);
    }

    return new Promise((resolve) => {
      const expirationDate = cardValidator.expirationDate(order.cardExpirationDate);
      kushki.instance.requestSubscriptionToken(
        {
          card: {
            name: order.cardName.toString().trim(),
            number: order.cardNumber.toString(),
            cvc: order.cardCvc.toString(),
            expiryMonth: expirationDate.month.toString(),
            expiryYear: expirationDate.year.toString()
          },
          currency
        },
        (kushkiResponse) => {
          if (!kushkiResponse.code) {
            resolve({ ok: true, token: kushkiResponse.token });
          }
          resolve({ ok: false });
        }
      );
    });
  }
};

export default OrderService;
