import { CARD_FIELDS } from '~app/screens/Commerce/components/PaymentMethods/components/KushkiCardForm/fields';
import { PAYMENT_METHODS_NAMES } from '~constants/paymentMethods';
import { VALIDATIONS, buildValidation } from '~utils/schema';

export const INITIAL_VALUES = {
  paymentMethod: PAYMENT_METHODS_NAMES.kushki,
  [CARD_FIELDS.CARD_NAME]: '',
  [CARD_FIELDS.CARD_NUMBER]: '',
  [CARD_FIELDS.CARD_EXPIRATION_DATE]: '',
  [CARD_FIELDS.CARD_CVC]: ''
};

export const validationSchema = () =>
  buildValidation({
    [CARD_FIELDS.CARD_NAME]: VALIDATIONS.cardName,
    [CARD_FIELDS.CARD_NUMBER]: VALIDATIONS.cardNumber,
    [CARD_FIELDS.CARD_EXPIRATION_DATE]: VALIDATIONS.cardExpirationDate,
    [CARD_FIELDS.CARD_CVC]: VALIDATIONS.cardCVC
  });

export const cardSubscriptionMapper = (values) => {
  return {
    cardExpirationDate: values.cardExpirationDate,
    cardName: values.cardName,
    cardNumber: values.cardNumber,
    cardCvc: values.cardCvc
  };
};
