import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';

import ModalActions from '~redux/Modal/actions';
import { MODALS } from '~redux/Modal/constants';

import styles from './styles.module.scss';

function LoginContainer() {
  const { currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleOpenLoginModal = () => {
    dispatch(ModalActions.openModal(MODALS.LOGIN));
  };

  return (
    <div className={styles.loginContainer}>
      {!currentUser && (
        <button type="button" onClick={handleOpenLoginModal} className="bold text-brand-primary font-size-15">
          {i18next.t('CompleteProfile:login')}
        </button>
      )}
    </div>
  );
}

export default LoginContainer;
