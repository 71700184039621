import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { FIELDS } from '../../../constants';

const useQuestions = ({ setFieldValue, values }) => {
  const { checkoutQuestions = [] } = useSelector((state) => state.searchStore?.currentSubsidiary);
  const fieldName = FIELDS.QUESTIONS;
  const answers = values[fieldName].map((question, index) => ({
    ...question,
    answerFieldName: `${fieldName}.${index}`
  }));

  useEffect(() => {
    checkoutQuestions.forEach(({ id: checkoutQuestionId, name: checkoutQuestion }, index) => {
      setFieldValue(`${fieldName}.${index}`, {
        checkoutQuestionId,
        checkoutQuestion,
        answer: null
      });
    });
  }, [checkoutQuestions, fieldName, setFieldValue]);

  const handleOnChange = (fieldName, question) => ({ target }) =>
    setFieldValue(fieldName, { ...question, answer: target.value });

  return { answers, handleOnChange };
};

export default useQuestions;
