/* eslint-disable no-empty-function */
import React from 'react';
import i18next from 'i18next';
import { func, string, bool } from 'prop-types';

import kushkiIcon from '~assets/ico-kushki.svg';
import creditIcon from '~assets/ico-credit.svg';
import LoadingWrapper from '~components/Spinner/LoadingWrapper';
import { checkoutValuesPropTypes } from '~propTypes/checkoutValues';
import Text from '~components/Text';
import { arrayHasLength } from '~utils/array';
import { camelize } from '~utils/string';
import { PAYMENT_METHODS_NAMES } from '~constants/paymentMethods';
import KushkiForm from '~app/screens/Commerce/screens/Profile/components/KushkiModal/components/KushkiForm';

import PaymentMethod from '../PaymentMethod';
import stylesPayment from '../../styles.module.scss';
import { ADD_CREDIT_CARD, PAYMENT_METHOD, ID_CARD } from '../../constants';

import { LOADING_ICON_SIZE } from './constants';
import styles from './styles.module.scss';
import useCreditCards from './hooks/useCreditCards';

function CreditCards({ values, handleSelectCreditCard, subscriptionMethodEnabled, showAddCard = true }) {
  const {
    creditCardsLoading,
    isProfile,
    handleOnStartAddCard,
    creditCardsFilteredByType,
    handleKushkiModal,
    isKushkiCardSubscriptionEnabled
  } = useCreditCards({
    subscriptionMethodEnabled
  });

  return (
    <div className="column relative">
      <Text className={`text-emperor m-bottom-5 ${stylesPayment.title}`}>
        {i18next.t('PaymentMethods:myCreditCards')}
      </Text>
      <div className={`row wrap ${styles.container}`}>
        <LoadingWrapper
          loading={creditCardsLoading}
          withInitialLoading
          className={styles.loadingContainer}
          iconProps={{ width: LOADING_ICON_SIZE, height: LOADING_ICON_SIZE }}>
          {!arrayHasLength(creditCardsFilteredByType) ? (
            <Text className={`text-emperor ${stylesPayment.title}`}>
              {i18next.t('CreditCards:withoutCreditCards')}
            </Text>
          ) : (
            creditCardsFilteredByType.map(({ id, lastDigits, type, token }) => (
              <PaymentMethod
                name={camelize(subscriptionMethodEnabled)}
                key={id}
                id={String(id)}
                hasDelete={isProfile}
                displayName={i18next.t('CreditCards:cardEndWith', {
                  lastDigits
                })}
                spanClassName={`text-emperor ${styles.payment}`}
                isSelected={
                  values &&
                  values[PAYMENT_METHOD] === camelize(subscriptionMethodEnabled) &&
                  id === values[ID_CARD]
                }
                onSelectPaymentMethod={
                  handleSelectCreditCard
                    ? handleSelectCreditCard(subscriptionMethodEnabled, id, token)
                    : () => {}
                }
                subscriptionMethodEnabled={subscriptionMethodEnabled}
                imgLogo={
                  type === PAYMENT_METHODS_NAMES.oneClick || type === PAYMENT_METHODS_NAMES.credomatic
                    ? creditIcon
                    : kushkiIcon
                }
              />
            ))
          )}
        </LoadingWrapper>
      </div>

      <div className={`m-top-3 m-bottom-3 ${styles.buttonAddCard}`}>
        {showAddCard ? (
          <>
            <PaymentMethod
              key={ADD_CREDIT_CARD.name}
              displayName={ADD_CREDIT_CARD.name}
              onSelectPaymentMethod={
                isKushkiCardSubscriptionEnabled ? handleKushkiModal : handleOnStartAddCard
              }
              spanClassName={`bold ${stylesPayment.payment}`}
              asButton
              subscriptionMethodEnabled={subscriptionMethodEnabled}
            />
            {isKushkiCardSubscriptionEnabled ? <KushkiForm /> : null}
          </>
        ) : null}
      </div>
    </div>
  );
}

CreditCards.propTypes = {
  subscriptionMethodEnabled: string.isRequired,
  handleSelectCreditCard: func,
  showAddCard: bool,
  values: checkoutValuesPropTypes
};

CreditCards.defaultProps = {
  showAddCard: true
};

export default CreditCards;
