import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { bool } from 'prop-types';
import cn from 'classnames';
import { GoogleApiWrapper } from 'google-maps-react';

import useCheckStoreOpen from '~hooks/useCheckStoreOpen';
import { MODALS } from '~redux/Modal/constants';
import ModalActions from '~redux/Modal/actions';
import { DISPATCH_METHOD_TARGET } from '~redux/SearchStore/constants';
import { TARGETS } from '~redux/Order/constants';
import { DISPATCH_METHODS } from '~constants/address';
import { ICONS } from '~constants/icons';
import { dayFormat } from '~utils/time';
import { parseLocation } from '~utils/maps';
import AuthActions from '~redux/Auth/actions';
import { LANGUAGE } from '~constants/search';

import GetDispathMethodDetail from '../GetDispatchMethodDetail';

import styles from './styles.module.scss';
import LoadingContainer from './LoadingContainer';

function DispatchDetails({ edition }) {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.cart);
  const openChangeAddressModal = () => dispatch(ModalActions.openModal(MODALS.DELIVERY_OR_PICKUP));
  const currentSubsidiary = useSelector((state) => state.searchStore?.currentSubsidiary);
  const dispatchType = useSelector((state) => state.searchStore[DISPATCH_METHOD_TARGET]);
  const scheduledOrder = useSelector((state) => state.order[TARGETS.FUTURE_ORDER]);
  const openFutureOrderModal = () => dispatch(ModalActions.openModal(MODALS.FUTURE_ORDER));
  const { address: authAddress } = useSelector((state) => state.auth);
  const { closedByAttribute, futureOrdersEnabled } = useCheckStoreOpen();

  const currentAddress = authAddress.current;
  useEffect(() => {
    const currentAddressPlaceId = currentAddress.placeId;
    const { latitude, longitude } = currentAddress;
    if (!currentAddressPlaceId) {
      parseLocation({ location: { latitude, longitude } }, ({ placeId }) => {
        dispatch(AuthActions.setAddress({ ...currentAddress, placeId }));
      });
    }
  }, [dispatch, currentAddress]);

  const dispatchtitle =
    dispatchType === DISPATCH_METHODS.DELIVERY
      ? i18next.t('DeliveryOrPickUp:deliveryTo')
      : i18next.t('DeliveryOrPickUp:near');
  const address = useSelector((state) =>
    dispatchType === DISPATCH_METHODS.PICKUP
      ? currentSubsidiary?.name
      : state.auth.address?.current?.textAddress
  );
  const timeForDispatch =
    dispatchType === DISPATCH_METHODS.DELIVERY
      ? currentSubsidiary?.deliveryWaitTime
      : currentSubsidiary?.pickUpWaitTime;

  const buttonProps = {
    type: 'button',
    className: 'bold text-brand-primary font-size-15'
  };

  return currentSubsidiary ? (
    <>
      <div className={`row space-between full-width ${styles.container}`}>
        <div className={`column ${styles.columnContainer}`}>
          <div className="row middle m-bottom-3">
            <span className="m-right-1 bold text-uppercase text-emperor font-size-16">{dispatchtitle}</span>
            <span className={`material-icons-outlined text-brand-primary ${styles.iconLink}`}>
              {dispatchType === DISPATCH_METHODS.DELIVERY ? ICONS.DELIVERY : ICONS.PICKUP}
            </span>
          </div>
          <div className="row middle m-bottom-3 bold text-emperor">
            <GetDispathMethodDetail
              className={`text-emperor font-size-15 ${styles.address}`}
              dispatchType={dispatchType}
              currentSubsidiary={currentSubsidiary}
              address={address}
            />
          </div>
        </div>
        <div className={`column end ${styles.columnContainer}`}>
          <span className="m-bottom-3 bold text-uppercase text-emperor font-size-16">
            {i18next.t('DispatchDetails:deliveryTime')}
          </span>
          <div className={`row end m-bottom-3 ${styles.time}`}>
            <span
              className={cn('material-icons-outlined m-right-1 text-emperor font-size-17', styles.iconLink)}>
              {scheduledOrder ? 'schedule' : 'update'}
            </span>
            {scheduledOrder ? (
              <div className="col full-width">
                <div className="row end full-width">{dayFormat(scheduledOrder?.date, false, true)}</div>
                <div className="row end full-width">{scheduledOrder?.time}</div>
              </div>
            ) : cart.some(({ product }) => product.deferred) ? (
              i18next.t('FutureOrder:asap')
            ) : (
              i18next.t('DispatchDetails:approximateTime', {
                time: timeForDispatch
              })
            )}
          </div>
        </div>
      </div>
      {edition && (
        <div className="row space-between full-width">
          <div>
            <button type="button" onClick={openChangeAddressModal} {...buttonProps}>
              {i18next.t('DispatchDetails:modify')}
            </button>
          </div>
          <div>
            {futureOrdersEnabled && !closedByAttribute && (
              <button type="button" onClick={openFutureOrderModal} {...buttonProps}>
                {i18next.t('DispatchDetails:modify')}
              </button>
            )}
          </div>
        </div>
      )}
    </>
  ) : (
    <div className={`row space-between full-width ${styles.container}`}>
      <div className="column middle text-emperor">{i18next.t('DispatchDetails:selectAddress')}</div>
    </div>
  );
}

DispatchDetails.defaultProps = {
  edition: true
};

DispatchDetails.propTypes = {
  edition: bool
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  language: LANGUAGE,
  LoadingContainer
})(DispatchDetails);
