import { snakeCase } from 'snake-case';

import { DISPATCH_METHOD_TARGET, CURRENT_SUBSIDIARY_TARGET } from '~redux/SearchStore/constants';
import { DISPATCH_METHODS } from '~constants/address';
import { flatMap } from '~utils/array';
import { mapUser } from '~redux/Auth/mappers';
import { convertMoneyToPoints } from '~screens/Commerce/screens/Checkout/components/Discounts/components/utils';

import { FAKE_DATA_FOR_USER } from './constants';

export const mapCart = (state) => {
  const {
    cart,
    state: { order, core },
    user = null,
    address = null
  } = state;
  const country = core.settings.country?.isoCountry || 'cl';

  const PHONE_VALIDATION = {
    default: 8,
    cl: 8,
    cr: 8
  };

  const FAKE_PHONE_NUMBER_PATTERN = 9;

  const fakePhoneNumber = Array(PHONE_VALIDATION[country] || PHONE_VALIDATION.default)
    .fill(FAKE_PHONE_NUMBER_PATTERN)
    .join('');

  const dispatchMethod = state.state.searchStore[DISPATCH_METHOD_TARGET] || DISPATCH_METHODS.DELIVERY;
  const storeId = state.state.searchStore[CURRENT_SUBSIDIARY_TARGET]?.id || state.state.core.defaultStoreId;

  const { futureOrder } = order;
  return {
    shoppingCart: {
      ...mapUser(
        user || {
          ...FAKE_DATA_FOR_USER,
          phone: fakePhoneNumber
        },
        address,
        state.state
      ),
      storeId: parseInt(storeId),
      dispatchMethod,
      items: cart.map(({ product, count, items }) => ({
        productId: product.id,
        quantity: count,
        ...(Object.keys(items).length
          ? {
              modifiers: flatMap(
                (key) =>
                  items[key].map(({ id, name, quantity, parentId }) => ({
                    id,
                    name,
                    quantity,
                    action: 'add',
                    parentId
                  })),
                Object.keys(items)
              )
            }
          : {})
      })),
      requestedTime: futureOrder ? `${futureOrder.date} ${futureOrder.time}` : null
    }
  };
};

export const mapCartWithDiscount = ({ state, coupon, paymentMethod, cashToExchange }) => {
  const { decode } = state.core.settings?.pointRate || {};
  const { cart } = state.cart;
  const mappedCartWithCoupon = mapCart({ cart, state });
  return {
    ...mappedCartWithCoupon,
    shoppingCart: {
      ...mappedCartWithCoupon.shoppingCart,
      loyaltyPoints: convertMoneyToPoints({
        money: cashToExchange,
        decode
      }),
      coupon: coupon?.trim(),
      couponPaymentMethod: paymentMethod ? snakeCase(paymentMethod) : ''
    }
  };
};
