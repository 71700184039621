import { MAPBOX_MAP_URL_DEFAULT } from './maps';

const DEFAULT_INTERVAL_POOLING_ORDERS = 60000;

export const TENANT = window.settings?.tenant || 'justburger';

export const ISO_COUNTRY = window.settings?.country?.['iso_country'] || process.env.REACT_APP_ISO_COUNTRY;
export const CODE_COUNTRY = window.settings?.country?.['code_country'] || process.env.REACT_APP_CODE_COUNTRY;
export const LANGUAGE = window.settings?.country?.['translation_country'] || 'es_CL';
export const PUBLIC_CDN = process.env.REACT_APP_PUBLIC_CDN;
export const USE_SPECIAL_ENCODING = process.env.REACT_APP_USE_SPECIAL_ENCODING_DECODING === String(true);
export const DEFAULT_STORE_ID = process.env.REACT_APP_DEFAULT_STORE_ID;
export const CREATE_USER_TOKEN = process.env.REACT_APP_CREATE_USER_TOKEN;
export const TRANSLATION_COUNTRY = process.env.REACT_APP_TRANSLATION_COUNTRY;
export const API_URL = process.env.REACT_APP_BASE_URL;
export const ENVIRONMENT = process.env.REACT_APP_ENV;
export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
export const INTERVAL_POOLING_ORDERS =
  process.env.REACT_APP_INTERVAL_POOLING_ORDERS || DEFAULT_INTERVAL_POOLING_ORDERS;
export const MAPBOX_MAP_URL = process.env.REACT_APP_MAPBOX_MAP_URL || MAPBOX_MAP_URL_DEFAULT;
export const CURRENCY_SYMBOL =
  window.settings?.country?.currency_symbol || process.env.REACT_APP_CURRENCY_SYMBOL || '$';
export const PRODUCTION_ENVIRONMENT = 'production';
export const DEV_ENVIRONMENT = 'development';
export const ROLLBAR_ACCESS_TOKEN = process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN;
export const CODE_VERSION = process.env.REACT_APP_GIT_COMMIT_SHA;
