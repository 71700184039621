import i18next from 'i18next';

i18next.addResources('es_CL', 'DeliveryOrPickUp', {
  local: 'Retiro en tienda',
  near: 'Retiro',
  delivery: 'Delivery',
  deliveryTo: 'Delivery a',
  seeMap: 'Ver mapa',
  iconTime: 'Ícono tiempo',
  iconMap: 'Ícono mapa',
  minAprox: '{{waitTime}} min aprox',
  noData: 'Fuera del área de cobertura',
  noDataMessage: 'No encontramos locales cercanos a la dirección ingresada.',
  pickStore: 'Selecciona una tienda',
  pickStoreMessage: 'Para obtener tu pedido selecciona una tienda',
  indications: 'Indicaciones'
});
