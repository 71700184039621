import moment from 'moment';

export const hideIfTheresNoImage = (availableForImage, productImage) =>
  (availableForImage && productImage) || !availableForImage;

export const getLastDateHour = (date, businessHours = []) => {
  const dayHours = businessHours.filter((hour) => hour.day === date.format('dddd').toLowerCase());

  return (
    dayHours.length > 0 &&
    dayHours.reduce((lastHour, hour) => {
      if (!lastHour) {
        return hour.closingTime;
      }

      if (moment(hour.closingTime).isAfter(moment(lastHour))) {
        return hour.closingTime;
      }

      return lastHour.closingTime;
    })
  );
};

export const getProductStock = (endDatetimeString, availability, withoutStockUntil, futureOrder) => {
  const endDatetime = endDatetimeString ? moment(endDatetimeString) : null;
  const futureOrderDate =
    futureOrder?.date && futureOrder?.time
      ? moment(`${futureOrder.date} ${futureOrder.time}`, 'YYYY-MM-DD HH:mm')
      : null;

  if (endDatetime && futureOrderDate && endDatetime.isSameOrBefore(futureOrderDate)) {
    return false;
  }

  const checkWithoutStockUntilDay =
    withoutStockUntil && moment().isAfter(moment(withoutStockUntil))
      ? moment().format('dddd').toLowerCase()
      : false;

  const checkfutureOrderDateDay =
    futureOrderDate && (!withoutStockUntil || futureOrderDate.isAfter(moment(withoutStockUntil)))
      ? futureOrderDate.format('dddd').toLowerCase()
      : false;

  const hasWithoutStockUntil = withoutStockUntil
    ? checkWithoutStockUntilDay
    : moment().format('dddd').toLowerCase();

  const checkAvailability = futureOrderDate ? checkfutureOrderDateDay : hasWithoutStockUntil;

  const checkFutureOrderDate = futureOrderDate ? futureOrderDate.isAfter(moment(withoutStockUntil)) : false;

  return availability.length > 0 ? availability.includes(checkAvailability) : checkFutureOrderDate;
};

export const getBackToStockDate = (withoutStockUntil, futureOrderDates) => {
  const lastDate =
    withoutStockUntil &&
    futureOrderDates.availabilityDatetime &&
    futureOrderDates.availabilityDatetime.isBefore(moment(withoutStockUntil))
      ? moment(withoutStockUntil)
      : futureOrderDates.availabilityDatetime;

  const checkWithoutStockUntil = withoutStockUntil ? lastDate : futureOrderDates.availabilityDatetime;

  return futureOrderDates.availabilityDatetime ? checkWithoutStockUntil : moment(withoutStockUntil);
};

export const getWithoutStockUntilDate = (withoutStockUntil, startDatetime) => {
  const bothExists = !!withoutStockUntil && !!startDatetime;
  const dateIfBothExists = moment(withoutStockUntil).isSameOrAfter(moment(startDatetime))
    ? withoutStockUntil
    : startDatetime;
  const startDatetimeChecked = moment(startDatetime).isAfter(moment().endOf('day')) ? startDatetime : null;
  const dateIfOneExists = withoutStockUntil ? withoutStockUntil : startDatetimeChecked;

  return bothExists ? dateIfBothExists : dateIfOneExists ? dateIfOneExists : null;
};
