import { snakeCase } from 'snake-case';

import checkIcon from '~assets/ico-chek.svg';
import creditIcon from '~assets/ico-credit.svg';
import cardIcon from '~assets/ico-card.svg';
import cashIcon from '~assets/ico-cash.svg';
import machIcon from '~assets/ico-mach.svg';
import logoWepay from '~assets/ico-webpay.svg';
import sodexoIcon from '~assets/ico-sodexo.svg';
import mercadoPagoIcon from '~assets/ico-mercadopago.svg';
import kushkiIcon from '~assets/ico-kushki.svg';
import getnetIcon from '~assets/ico-getnet.svg';
import fpayIcon from '~assets/ico-fpay.svg';

export const PAYMENT_METHODS_NAMES = {
  card: 'card',
  cash: 'cash',
  chek: 'chek',
  credomatic: 'credomatic',
  getnet: 'getnet',
  kushki: 'kushkiCard',
  kushkiCardSubscription: 'kushkiCardSubscription',
  mach: 'mach',
  mercadoPago: 'mercadoPago',
  oneClick: 'oneclick',
  sodexo: 'sodexo',
  webpay: 'online',
  fpay: 'fpay'
};

export const PRIORITY_PAYMENT_METHODS = {
  [PAYMENT_METHODS_NAMES.kushki]: 0,
  [PAYMENT_METHODS_NAMES.kushkiCardSubscription]: 1,
  [PAYMENT_METHODS_NAMES.webpay]: 2,
  [PAYMENT_METHODS_NAMES.card]: 3,
  [PAYMENT_METHODS_NAMES.cash]: 4,
  [PAYMENT_METHODS_NAMES.sodexo]: 5,
  [PAYMENT_METHODS_NAMES.oneClick]: 6,
  [PAYMENT_METHODS_NAMES.chek]: 7,
  [PAYMENT_METHODS_NAMES.mercadoPago]: 8,
  [PAYMENT_METHODS_NAMES.mach]: 9,
  [PAYMENT_METHODS_NAMES.getnet]: 10,
  [PAYMENT_METHODS_NAMES.credomatic]: 11,
  [PAYMENT_METHODS_NAMES.fpay]: 12
};

export const PAYMENT_METHODS = {
  [PAYMENT_METHODS_NAMES.cash]: {
    name: PAYMENT_METHODS_NAMES.cash,
    isOnline: false,
    logo: cashIcon,
    priority: PRIORITY_PAYMENT_METHODS[PAYMENT_METHODS_NAMES.cash]
  },
  [PAYMENT_METHODS_NAMES.card]: {
    name: PAYMENT_METHODS_NAMES.card,
    isOnline: false,
    logo: cardIcon,
    priority: PRIORITY_PAYMENT_METHODS[PAYMENT_METHODS_NAMES.card]
  },
  [PAYMENT_METHODS_NAMES.webpay]: {
    name: PAYMENT_METHODS_NAMES.webpay,
    isOnline: true,
    logo: logoWepay,
    priority: PRIORITY_PAYMENT_METHODS[PAYMENT_METHODS_NAMES.webpay]
  },
  [PAYMENT_METHODS_NAMES.oneClick]: {
    name: PAYMENT_METHODS_NAMES.oneClick,
    isOnline: true,
    logo: creditIcon,
    priority: PRIORITY_PAYMENT_METHODS[PAYMENT_METHODS_NAMES.oneClick]
  },
  [PAYMENT_METHODS_NAMES.sodexo]: {
    name: PAYMENT_METHODS_NAMES.sodexo,
    isOnline: true,
    logo: sodexoIcon,
    priority: PRIORITY_PAYMENT_METHODS[PAYMENT_METHODS_NAMES.sodexo]
  },
  [PAYMENT_METHODS_NAMES.chek]: {
    name: PAYMENT_METHODS_NAMES.chek,
    isOnline: true,
    logo: checkIcon,
    priority: PRIORITY_PAYMENT_METHODS[PAYMENT_METHODS_NAMES.chek]
  },
  [PAYMENT_METHODS_NAMES.mercadoPago]: {
    name: PAYMENT_METHODS_NAMES.mercadoPago,
    isOnline: true,
    logo: mercadoPagoIcon,
    priority: PRIORITY_PAYMENT_METHODS[PAYMENT_METHODS_NAMES.mercadoPago]
  },
  [PAYMENT_METHODS_NAMES.mach]: {
    name: PAYMENT_METHODS_NAMES.mach,
    isOnline: true,
    logo: machIcon,
    priority: PRIORITY_PAYMENT_METHODS[PAYMENT_METHODS_NAMES.mach]
  },
  [PAYMENT_METHODS_NAMES.kushki]: {
    name: PAYMENT_METHODS_NAMES.kushki,
    isOnline: true,
    logo: kushkiIcon,
    priority: PRIORITY_PAYMENT_METHODS[PAYMENT_METHODS_NAMES.kushki]
  },
  [PAYMENT_METHODS_NAMES.kushkiCardSubscription]: {
    name: PAYMENT_METHODS_NAMES.kushkiCardSubscription,
    isOnline: true,
    priority: PRIORITY_PAYMENT_METHODS[PAYMENT_METHODS_NAMES.kushkiCardSubscription]
  },
  [PAYMENT_METHODS_NAMES.credomatic]: {
    name: PAYMENT_METHODS_NAMES.credomatic,
    isOnline: true,
    priority: PRIORITY_PAYMENT_METHODS[PAYMENT_METHODS_NAMES.credomatic]
  },
  [PAYMENT_METHODS_NAMES.getnet]: {
    name: PAYMENT_METHODS_NAMES.getnet,
    isOnline: true,
    logo: getnetIcon,
    priority: PRIORITY_PAYMENT_METHODS[PAYMENT_METHODS_NAMES.getnet]
  },
  [PAYMENT_METHODS_NAMES.fpay]: {
    name: PAYMENT_METHODS_NAMES.fpay,
    isOnline: true,
    logo: fpayIcon,
    priority: PRIORITY_PAYMENT_METHODS[PAYMENT_METHODS_NAMES.fpay]
  }
};

export const PAYMENT_METHODS_ONLINE = Object.values(PAYMENT_METHODS)
  .filter(({ isOnline }) => isOnline)
  .map(({ name }) => name);

export const SUBSCRIPTION_METHODS = {
  [PAYMENT_METHODS_NAMES.oneClick]: PAYMENT_METHODS_NAMES.oneClick,
  [PAYMENT_METHODS_NAMES.kushkiCardSubscription]: snakeCase(PAYMENT_METHODS_NAMES.kushkiCardSubscription),
  [PAYMENT_METHODS_NAMES.credomatic]: PAYMENT_METHODS_NAMES.credomatic
};

const PAYMENT_METHODS_NOT_ALLOWED_FOR_TIPS = [PAYMENT_METHODS_NAMES.sodexo];

export const TIPPABLE_PAYMENT_METHODS = Object.keys(PAYMENT_METHODS).filter(
  (key) => !PAYMENT_METHODS_NOT_ALLOWED_FOR_TIPS.includes(key)
);
