import i18next from 'i18next';

i18next.addResources('es_CL', 'DeliveryOrPickup', {
  howDoYouWantToOrderToday: '¿Cómo quieres pedir hoy?',
  deliveryToYourHome: 'Pide a tu casa',
  address2: 'Depto/ Oficina/ casa',
  myAddresses: 'Direcciones guardadas',
  lastAddresses: 'Direcciones usadas anteriormente',
  continue: 'continuar',
  addressNoLongerAvailable:
    'La dirección ingresada ya no se encuentra disponible para delivery en esta tienda!',
  orWell: 'O bien',
  pickOneOfOurStoresToPickup: 'Elige una de nuestras tiendas para retirar'
});
