import i18next from 'i18next';

import { mapDateToOptions } from '~screens/Commerce/components/FutureOrderModal/utils';
import { DISPATCH_METHODS } from '~constants/address';
import { KINDS } from '~screens/Commerce/components/TopSiteMessage/constants';
import { deserializer } from '~services/SearchStore/serializers';
import { arrayHasLength } from '~utils/array';

const parseStore = (store) => ({
  ...store,
  deliveryInstructions: null
});

export const parseStores = ({ pickupStores }) => [...(pickupStores ? pickupStores.map(parseStore) : [])];

export const getSubsidiary = (response, currentSubsidiary) => {
  let result = response.data ? { ...deserializer.serialize(response.data) } : {};
  const { paymentMethods } = result;
  const filteredPaymentMethods = Object.keys(paymentMethods).reduce(
    (acc, el) => ({
      ...acc,
      ...(paymentMethods[el] && { [el]: paymentMethods[el] })
    }),
    {}
  );
  if (currentSubsidiary) {
    const { name, textAddress, latitude, longitude } = currentSubsidiary;
    result = {
      ...result,
      paymentMethods: filteredPaymentMethods,
      name,
      textAddress,
      latitude,
      longitude
    };
  } else {
    result = {
      ...result,
      paymentMethods: filteredPaymentMethods
    };
  }
  return result;
};

export const getTopSiteMessage = (futureOrdersEnabled, openingTime, dispatchType) => {
  let topSiteMessage = '';
  if (futureOrdersEnabled) {
    topSiteMessage = i18next.t('StoreInfo:availableForFutureOrder', {
      value: openingTime
    });
  } else {
    topSiteMessage =
      dispatchType === DISPATCH_METHODS.PICKUP
        ? i18next.t('StoreInfo:unavailableForPickup')
        : i18next.t('StoreInfo:unavailableForDelivery');
  }
  return {
    message: topSiteMessage,
    kind: futureOrdersEnabled ? KINDS.WARNING : KINDS.ALERT
  };
};

export const mapToProductIds = (cart) => cart?.map(({ product }) => product.id).join();

export const noHoursAvailable = (hours, futureOrder) => {
  const availableDates = hours && mapDateToOptions(hours);
  const notAvailableDates = hours && Object.values(hours).every((date) => !arrayHasLength(date));
  return (
    hours &&
    (!availableDates[futureOrder?.date]?.times.map(({ label }) => label).includes(futureOrder?.time) ||
      notAvailableDates)
  );
};

export const someProductOnMenu = (productId, menu = []) =>
  menu.some(({ prices }) => prices.some((price) => price.product.id.toString() === productId.toString()));
