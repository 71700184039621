import i18next from 'i18next';
import requireContext from 'require-context.macro';
import countries from 'i18n-iso-countries';

countries.registerLocale(require('i18n-iso-countries/langs/es.json'));

function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
}

i18next.init({
  lng: process.env.REACT_APP_TRANSLATION_COUNTRY,
  initImmediate: false,
  fallbackLng: ['es_CL']
});

requireAll(
  process.env.NODE_ENV === 'test'
    ? requireContext('..', true, /i18n\.js$/)
    : require.context('..', true, /i18n\.js$/)
);
