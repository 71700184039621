import { create } from 'apisauce';
import { snakeCase } from 'snake-case';

import { serializer as baseSerializer } from '~services/baseSerializers';
import api, { apiSetup, HEADERS } from '~config/api';
import { CREATE_USER_TOKEN, API_URL } from '~constants/environment';
import PollingService from '~services/PollingService';
import { retry } from '~utils/functions';
import { PAYMENT_METHODS_NAMES } from '~constants/paymentMethods';

import { OFFLINE_RETRY_TIMES } from './constants';

const services = {
  getCurrentUser: (headers = {}) => {
    const { headers: currentHeaders } = api;
    return api.get('v1/users/me', {}, { headers: { ...currentHeaders, ...headers } });
  },
  signIn: (values) => api.post('v1/users/login', values),
  verifyAccount: (values) => api.post('v1/users/verify_account', values),
  registerUser: (values) => api.patch('v1/users/update_me', values),
  createAddress: ({ apartmentDetails = null, dispatchNote = null, ...addressValues }) =>
    api.post(
      'v1/addresses',
      baseSerializer.serialize({
        address: { ...addressValues, apartmentDetails, dispatchNote }
      })
    ),
  updateAddress: ({ apartmentDetails = null, dispatchNote = null, ...addressValues }) =>
    api.patch(
      `v1/addresses/${addressValues.id}`,
      baseSerializer.serialize({
        ...addressValues,
        apartmentDetails,
        dispatchNote
      })
    ),
  deleteAddress: (id) => api.delete(`v1/addresses/${id}`),
  createGuestUser: (userData) => {
    const { headers } = api;
    const temporalApi = create({
      baseURL: API_URL,
      headers: {
        'X-CREATE-USER-TOKEN': CREATE_USER_TOKEN,
        [HEADERS.TENANT]: headers[HEADERS.TENANT]
      }
    });
    apiSetup(temporalApi);
    return temporalApi.post('/v1/users', baseSerializer.serialize(userData));
  },
  addCreditCard: ({ token, subscriptionMethodEnabled, kushkiToken, card }) => {
    const { headers } = api;
    const temporalApi = create({
      baseURL: API_URL,
      headers: {
        [HEADERS.AUTHORIZATION]: token,
        [HEADERS.TENANT]: headers[HEADERS.TENANT]
      }
    });
    apiSetup(temporalApi);
    if (subscriptionMethodEnabled === PAYMENT_METHODS_NAMES.kushkiCardSubscription) {
      return temporalApi.post(`/v1/users/cards/${snakeCase(subscriptionMethodEnabled)}/init_inscription`, {
        token: kushkiToken
      });
    }
    return temporalApi.post(
      `/v1/users/cards/${subscriptionMethodEnabled}/init_inscription`,
      card && baseSerializer.serialize(card)
    );
  },
  removeCreditCard: ({ cardId, externalId, subscriptionMethodEnabled }) => {
    const { headers } = api;
    const temporalApi = create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        [HEADERS.AUTHORIZATION]: externalId,
        [HEADERS.TENANT]: headers[HEADERS.TENANT]
      }
    });
    apiSetup(temporalApi);
    return temporalApi.delete(`/v1/users/cards/${subscriptionMethodEnabled}/${cardId}`);
  },
  usersJob: (token) => {
    const { headers } = api;
    const temporalApi = create({
      baseURL: API_URL,
      headers: {
        [HEADERS.JOB_AUTHORIZATION]: token,
        [HEADERS.TENANT]: headers[HEADERS.TENANT]
      }
    });
    apiSetup(temporalApi);
    return temporalApi.get('v1/users_job');
  },
  processUsersJob: ({ token, service }) => {
    const request = () => PollingService.poll({ ok: true, data: token }, service);
    return retry(request, OFFLINE_RETRY_TIMES);
  },
  createCreditCard: (token) => {
    const { headers } = api;
    api.post(`v1/users/cards/oneclick/create/${headers[HEADERS.TENANT]}/TBK_TOKEN=${token}`);
  },
  getCreditCards: (userId) => {
    const { headers } = api;
    const temporalApi = create({
      baseURL: API_URL,
      headers: {
        [HEADERS.AUTHORIZATION]: userId,
        [HEADERS.TENANT]: headers[HEADERS.TENANT]
      }
    });
    apiSetup(temporalApi);
    return temporalApi.get('v1/users/cards/');
  }
};

export default services;
