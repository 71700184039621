import Immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer, onReadValue } from 'redux-recompose';

import { actions } from './actions';
import { TARGETS } from './constants';

const initialStateDescription = {
  onInitialLoad: true,
  [TARGETS.ADDRESS]: null,
  [TARGETS.CURRENT_USER]: null,
  [TARGETS.SEND_SIGN_IN_CODE_TO_EMAIL]: null,
  [TARGETS.SIGN_IN_WITH_EMAIL_CODE]: null,
  [TARGETS.REGISTER_USER]: null,
  [TARGETS.CREDIT_CARDS]: null
};

const initialState = completeState(initialStateDescription);

const reducerDescription = {
  primaryActions: [
    actions.SET_ADDRESS,
    actions.GET_CURRENT_USER,
    actions.SEND_SIGN_IN_CODE_TO_EMAIL,
    actions.SIGN_IN_WITH_EMAIL_CODE,
    actions.REGISTER_USER,
    actions.GET_CREDIT_CARDS
  ],
  override: {
    [actions.SET_VALUE]: onReadValue()
  }
};

const reducer = createReducer(new Immutable(initialState), completeReducer(reducerDescription));

export default reducer;
