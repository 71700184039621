import { hexToRgba } from '~utils/rgba';

const createScript = (js, name) => {
  const script = document.createElement(name);
  script.innerHTML = js;
  return script;
};

export const setPrimaryColors = ({ primary, secondary }) => {
  document.documentElement.style.setProperty('--brand-primary', `#${primary}`);
  document.documentElement.style.setProperty('--brand-secondary', `#${secondary}`);
  document.documentElement.style.setProperty('--brand-primary-rgb', hexToRgba(primary));
  document.documentElement.style.setProperty('--brand-secondary-rgb', hexToRgba(secondary));
  document.documentElement.style.setProperty('--swiper-pagination-color', '#ffffff');
  document.documentElement.style.setProperty('--swiper-theme-color', '#ffffff');
};

export const setPrimaryFonts = ({ body, categoryBar, subtitle, title }) => {
  document.documentElement.style.setProperty('--body-font', `${body}`);
  document.documentElement.style.setProperty('--category-bar-font', `${categoryBar}`);
  document.documentElement.style.setProperty('--subtitle-font', `${subtitle}`);
  document.documentElement.style.setProperty('--title-font', `${title}`);
};

export const setGTM = ({ script, noScript }) => {
  document.head.insertBefore(createScript(script, 'script'), document.head.childNodes[0]);
  document.body.insertBefore(createScript(noScript, 'noscript'), document.body.childNodes[0]);
};

// eslint-disable-next-line consistent-return
export const setDatalayer = ({ dataLayer, dataLayerName, script }) => {
  if (window[dataLayerName]) {
    return window[dataLayerName].push(dataLayer);
  }
  document.head.insertBefore(createScript(script, 'script'), document.head.childNodes[0]);
};
