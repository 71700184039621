import PaymentMethods from '~screens/Commerce/components/PaymentMethods';
import { DISPATCH_METHODS } from '~constants/address';

import CompleteProfile from '../../components/CompleteProfile';
import DispatchDetails from '../../components/DispatchDetails';
import LoginContainer from '../../components/CompleteProfile/components/LoginContainer';
import { CARD_FIELDS } from '../../components/PaymentMethods/components/KushkiCardForm/fields';

import DeliveryOptions from './components/DeliveryOptions';
import Questions from './components/Questions';
import { DELIVERY_OPTIONS } from './components/DeliveryOptions/constants';
import styles from './styles.module.scss';
import DeferredDelivery from './components/DeferredDelivery';
import Discounts from './components/Discounts';

export const COMPONENTS_KEYS = {
  DISCOUNTS: 'discounts',
  DISPATCH_DETAILS: 'dispatchDetails',
  PROFILE: 'profile',
  PAYMENT_METHODS: 'paymentMethods',
  DELIVERY_OPTIONS: 'deliveryOptions',
  DEFERRED_TIME: 'deferredTime',
  QUESTIONS: 'questions',
  DIVIDER: 'divider'
};

const divider = {
  key: COMPONENTS_KEYS.DIVIDER,
  component: () => null,
  shouldRender: () => true,
  className: styles.divider
};

export const COMPONENTS = [
  {
    key: COMPONENTS_KEYS.DISPATCH_DETAILS,
    component: DispatchDetails,
    shouldRender: () => true,
    title: 'CheckoutSection:dispatchDetails',
    className: styles.titleNoMarginTop
  },
  {
    key: COMPONENTS_KEYS.DEFERRED_TIME,
    component: DeferredDelivery,
    shouldRender: ({ cart }) => cart.some(({ product }) => product?.deferred),
    className: styles.deferredTime
  },
  {
    key: COMPONENTS_KEYS.DELIVERY_OPTIONS,
    component: DeliveryOptions,
    shouldRender: ({ dispatchType }) => dispatchType === DISPATCH_METHODS.DELIVERY,
    subtitle: 'CheckoutSection:deliveryOptions'
  },
  {
    key: COMPONENTS_KEYS.QUESTIONS,
    component: Questions,
    shouldRender: ({ currentSubsidiary }) => !!currentSubsidiary?.checkoutQuestions?.length,
    subtitle: 'CheckoutSection:questions'
  },
  {
    ...divider,
    key: `${divider.key}-${COMPONENTS_KEYS.QUESTIONS}`
  },
  {
    key: COMPONENTS_KEYS.PROFILE,
    component: CompleteProfile,
    shouldRender: () => true,
    title: 'CheckoutSection:profile',
    className: styles.profileContainer,
    titleInnerComponent: LoginContainer
  },
  {
    ...divider,
    key: `${divider.key}-${COMPONENTS_KEYS.PROFILE}`
  },
  {
    key: COMPONENTS_KEYS.PAYMENT_METHODS,
    component: PaymentMethods,
    shouldRender: () => true,
    title: 'CheckoutSection:paymentMethods'
  },
  {
    ...divider,
    key: `${divider.key}-${COMPONENTS_KEYS.PAYMENT_METHODS}`
  },
  {
    key: COMPONENTS_KEYS.DISCOUNTS,
    component: Discounts,
    shouldRender: () => true,
    title: 'CheckoutSection:discounts',
    titleStyle: 'discountTitle'
  },
  {
    ...divider,
    key: `${divider.key}-${COMPONENTS_KEYS.DISCOUNTS}`
  }
];

export const FIELDS = {
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'phone',
  NAPKIN: 'napkin',
  COVERLY: 'coverly',
  DELIVERY_OPTION: 'deliveryOption',
  INSTRUCTIONS: 'instructions',
  COUNT: 'count',
  PAYMENT_METHOD: 'paymentMethod',
  INSTALLMENTS_NUMBER: 'installmentsNumber',
  TIP: 'tip',
  STORE_TIP: 'storeTip',
  COUPON: 'coupon',
  MAILING: 'emailSubscription',
  QUESTIONS: 'checkoutAnswers',
  TERMS_AND_CONDITIONS: 'termsAndConditions',
  LOYALTY_POINTS: 'loyaltyPoints'
};

export const INITAL_VALUES = {
  [FIELDS.NAME]: '',
  [FIELDS.EMAIL]: '',
  [FIELDS.PHONE]: '',
  [FIELDS.NAPKIN]: false,
  [FIELDS.COVERLY]: false,
  [FIELDS.PAYMENT_METHOD]: '',
  [FIELDS.INSTALLMENTS_NUMBER]: 0,
  [FIELDS.DELIVERY_OPTION]: [DELIVERY_OPTIONS[0]],
  [FIELDS.INSTRUCTIONS]: '',
  [FIELDS.COUNT]: 0,
  [FIELDS.TIP]: 0,
  [FIELDS.STORE_TIP]: 0,
  [FIELDS.COUPON]: '',
  [FIELDS.MAILING]: true,
  [FIELDS.QUESTIONS]: [],
  [FIELDS.TERMS_AND_CONDITIONS]: false,
  [CARD_FIELDS.CARD_NAME]: '',
  [CARD_FIELDS.CARD_NUMBER]: '',
  [CARD_FIELDS.CARD_EXPIRATION_DATE]: '',
  [CARD_FIELDS.CARD_CVC]: ''
};
