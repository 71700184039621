import moment from 'moment';

import { isAvailableDispatchMethod } from '~models/dispatchMethod';
import { isAvailablePlatform } from '~models/platform';
import { DISPATCH_METHOD_TARGET, CURRENT_SUBSIDIARY_TARGET } from '~redux/SearchStore/constants';
import { DISPATCH_METHODS } from '~constants/address';
import { isAvailableProduct } from '~utils/product';
import { arrayHasLength, sortBy } from '~utils/array';
import { SORT_KEYS } from '~constants/sorts';

import { DEACTIVATABLE_TYPES } from './constants';

export const storeMapper = (state) => {
  const dispatchMethod = state.searchStore[DISPATCH_METHOD_TARGET] || DISPATCH_METHODS.DELIVERY;
  const storeId = state.searchStore[CURRENT_SUBSIDIARY_TARGET]?.id || state.core.defaultStoreId;
  return { dispatchMethod, storeId, platform: 'web' };
};

const hasPictures = (product) => product?.pictures?.length > 0;

const defaultPriceData = (price) => {
  const { product } = price;
  return { ...price, ...(!hasPictures(product) && { product: product }) };
};

export const defaultMenuData = (menu) => {
  const prices = menu?.prices?.map(defaultPriceData);
  return { ...menu, ...(prices && { prices }) };
};

const filterDisabledModifiers = (modifiers, disabledProducts, parentId) =>
  modifiers.reduce((filteredModifiers, modifier) => {
    if (
      disabledProducts.some(
        ({ deactivatableId, deactivatableType }) =>
          deactivatableId === modifier.childModifierId && deactivatableType === DEACTIVATABLE_TYPES.MODIFIER
      )
    ) {
      return [...filteredModifiers];
    }

    if (arrayHasLength(modifier.children)) {
      const children = filterDisabledModifiers(modifier.children, disabledProducts, modifier.modifierId);

      return [...filteredModifiers, { ...modifier, children }];
    }

    return [...filteredModifiers, { ...modifier, id: modifier.childModifierId, parentId: parentId }];
  }, []);

export const menuMapper = (data, banners, disabledProducts, dayLimit) => {
  const orderedPriceGroups = sortBy(data.priceGroups, SORT_KEYS.POSITION);
  const menu = orderedPriceGroups.reduce((categories, category) => {
    const positionBanners = banners.filter(
      ({ bannerableId }) => bannerableId && bannerableId === category.id
    );

    return [
      ...categories,
      {
        ...category,
        prices: category.prices.reduce((filteredPrices, price) => {
          const product = {
            ...price.product,
            endDatetime: price.product.endDatetime
              ? moment.utc(price.product.endDatetime).format('YYYY-MM-DDTHH:mm')
              : null,
            startDatetime: price.product.startDatetime
              ? moment.utc(price.product.startDatetime).format('YYYY-MM-DDTHH:mm')
              : null
          };

          if (
            !isAvailableDispatchMethod(product.dispatchMethod) ||
            !isAvailablePlatform(product.platform) ||
            !isAvailableProduct(product, dayLimit) ||
            price.channel !== 'mercat'
          ) {
            return filteredPrices;
          }

          const disabledProduct = disabledProducts.find(
            ({ deactivatableId, deactivatableType }) =>
              deactivatableId === product.id && deactivatableType === DEACTIVATABLE_TYPES.PRODUCT
          );

          const modifiers = filterDisabledModifiers(product.modifiers, disabledProducts);

          if (disabledProduct) {
            if (!disabledProduct.show) {
              return [...filteredPrices];
            }

            return [
              ...filteredPrices,
              {
                ...price,
                stock: false,
                withoutStockUntil: disabledProduct.endDate,
                product: {
                  ...product,
                  modifiers
                }
              }
            ];
          }

          return [
            ...filteredPrices,
            {
              ...price,
              stock: true,
              product: {
                ...product,
                modifiers
              }
            }
          ];
        }, []),
        positionBanners
      }
    ];
  }, []);

  return menu.map(defaultMenuData);
};
