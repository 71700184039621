import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DISPATCH_METHODS } from '~constants/address';
import { isUberDelivery, isPeyaDelivery } from '~constants/delivery';

const useOrderDetail = () => {
  const { dispatchType, deliveryLoading } = useSelector((state) => state.searchStore);
  const currentSubsidiary = useSelector((state) => state.searchStore?.currentSubsidiary);
  const [total, setTotal] = useState(0);

  const { subtotal, total: totalPrice, discount, delivery } = useSelector((state) => state.cart);
  const { tip } = useSelector((state) => state.order);
  const isDelivery = dispatchType === DISPATCH_METHODS.DELIVERY;
  const isUber = isUberDelivery(dispatchType, currentSubsidiary?.deliveryType);
  const isPeya = isPeyaDelivery(dispatchType, currentSubsidiary?.deliveryType);

  useEffect(() => {
    setTotal(tip > 0 ? totalPrice + tip : totalPrice);
  }, [tip, totalPrice]);

  return {
    subtotal,
    total,
    discount,
    delivery,
    tip,
    isDelivery,
    isUber,
    isPeya,
    deliveryLoading
  };
};

export default useOrderDetail;
