import i18next from 'i18next';

i18next.addResources('es_CL', 'Product', {
  addToCart: 'Agregar al Carro',
  outOfStock: 'Producto sin Stock',
  monday: 'lunes',
  tuesday: 'martes',
  wednesday: 'miércoles',
  thursday: 'jueves',
  friday: 'viernes',
  saturday: 'sábados',
  sunday: 'domingos',
  and: ' y ',
  startDatetime: ' desde el {{dayName}} {{day}} de {{month}}',
  endDatetime: ' hasta el {{dayName}} {{day}} de {{month}}'
});
