import { lazy } from 'react';

export const IMPORTS_NAMES = {
  commerce: 'Commerce',
  menu: 'Menu',
  home: 'Home',
  checkout: 'Checkout',
  orders: 'Orders',
  purchaseSuccess: 'PurchaseSuccess',
  purchaseFailure: 'PurchaseFailure',
  processingPurchase: 'ProcessingPurchase',
  processingAddCard: 'ProcessingAddCard',
  addCardSuccess: 'AddCardSuccess',
  addCardFailure: 'AddCardFailure',
  profile: 'Profile',
  termsAndConditions: 'TermsAndConditions',
  mercatTermsAndConditionsImport: 'MercatTermsAndConditionsImport',
  NPS: 'NPS'
};

export const lazyRetry = function (componentImport, name, fallbackUrl) {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false');
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false');
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true');
          return window.location.reload();
        }
        if (fallbackUrl) {
          window.location.pathname = fallbackUrl || '/';
        }
        reject(error);
      });
  });
};

const dynamicImportComponent = (dynamicImport, name, fallbackUrl) =>
  lazy(() => lazyRetry(dynamicImport, name, fallbackUrl));

export default dynamicImportComponent;
