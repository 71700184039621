import i18next from 'i18next';
import { useSelector } from 'react-redux';

export default function useRewardPoints() {
  const rewardPointsName = useSelector((state) => state.core?.settings?.loyaltyProgramParams?.name);
  const { maxLoyaltyMoneyToUse, loyaltyPoints, cartLoading } = useSelector((state) => state.cart);
  const { minAmount = 0, maxAmount = null } = useSelector(
    (state) =>
      state.core?.settings?.loyaltyProgramParams || {
        minAmount: 0,
        maxAmount: null
      }
  );

  const coupon = useSelector((state) => state.cart.coupon);

  const errorMessages = {
    rewardHigherThanTotalPrice: i18next.t(`RewardPoints:rewardHigherThanTotalPrice`),
    maxAmountToExchange: i18next.t(`RewardPoints:maxAmountToExchange`),
    maxLoyaltyMoneyToUse: i18next.t(`RewardPoints:maxAmountToExchange`, {
      amount: maxLoyaltyMoneyToUse
    }),
    notEnoughPoints: i18next.t(`RewardPoints:notEnoughPoints`, {
      rewardPointsName
    }),
    minAmountToExchange: i18next.t(`RewardPoints:minAmountToExchange`, {
      amount: minAmount
    }),
    notDiscount: i18next.t(`RewardPoints:notDiscount`)
  };

  return {
    rewardPointsName,
    maxLoyaltyMoneyToUse,
    loyaltyPoints,
    minAmountCCLimit: minAmount,
    maxAmountCCLimit: maxAmount,
    coupon,
    errorMessages,
    cartLoading
  };
}
