import Immutable from 'seamless-immutable';
import { createReducer, onReadValue, onSuccess } from 'redux-recompose';

import { actions } from './actions';

const initialState = {
  currentTabId: 0,
  tabs: []
};

const reducerDescription = {
  [actions.CHANGE_TAB]: onSuccess(),
  [actions.SET_TABS]: onReadValue()
};

const reducer = createReducer(new Immutable(initialState), reducerDescription);

export default reducer;
