import i18next from 'i18next';

i18next.addResources('es_CL', 'Address', {
  home: 'Casa',
  work: 'Trabajo',
  couple: 'Pareja',
  other: 'Otro',
  addAddress: 'Agregar Dirección',
  editAddressModalTitle: 'Editar dirección',
  noStreetNumber: 'Escribe una dirección con número de calle',
  placeholderSearch: 'Dirección',
  floor: 'Depto / Oficina',
  dispatchNote: 'Nota de despacho',
  save: 'Guardar',
  delete: 'Borrar',
  cancel: 'Cancelar',
  currentAddress: 'Esta es la dirección de:'
});
