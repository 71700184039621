import Immutable from 'seamless-immutable';
import { createReducer, onReadValue } from 'redux-recompose';

import { actions, targets } from './actions';

const initialState = {
  [targets.NEXT_ACTIONS]: []
};

const reducerDescription = {
  [actions.SET_NEXT_ACTION]: onReadValue(),
  [actions.CLEAR_ACTION]: onReadValue(),
  [actions.CLEAR_ALL_ACTIONS_BY_TARGET]: onReadValue()
};

export default createReducer(new Immutable(initialState), reducerDescription);
