import React from 'react';
import i18next from 'i18next';
import cn from 'classnames';

import AlertMessage from '~components/AlertMessage';
import FormWrapper from '~components/FormWrapper';
import { KushkiCardForm } from '~app/screens/Commerce/components/PaymentMethods/components/KushkiCardForm';

import KushkiModal from '../..';
import styles from '../../styles.module.scss';

import { INITIAL_VALUES, validationSchema } from './constants';
import useKushkiSubscription from './useKushkiSubscription';

function KushkiForm() {
  const {
    kushkiSubscriptionLoading,
    kushkiSubscriptionError,
    handleCardSubscriptionSubmit
  } = useKushkiSubscription();

  return (
    <KushkiModal loading={kushkiSubscriptionLoading}>
      <FormWrapper
        initialValues={INITIAL_VALUES}
        onSubmit={handleCardSubscriptionSubmit}
        validationSchema={validationSchema}>
        {({ handleSubmit }) => (
          <form className="full-width" onSubmit={handleSubmit}>
            <KushkiCardForm enableOptionSubscribeCard={false} />
            {kushkiSubscriptionError ? (
              <div className={cn('full-width', styles.oneColumn, styles.errorAlert)}>
                <AlertMessage variant="error" text={i18next.t('KushkiCardForm:error')} />
              </div>
            ) : null}
            <button
              type="submit"
              className={`button primary row space-around middle full-width ${styles.button} ${styles.row} ${styles.oneColumn}`}
              disabled={kushkiSubscriptionLoading}>
              <div className="row space-around full-width">
                <div className="font-size-15 body-font text-uppercase text-white bold">
                  {kushkiSubscriptionLoading
                    ? i18next.t('KushkiCardForm:subscribeCardLoading')
                    : i18next.t('KushkiCardForm:subscribeCard')}
                </div>
              </div>
            </button>
          </form>
        )}
      </FormWrapper>
    </KushkiModal>
  );
}

export default KushkiForm;
