import React, { Component, createContext } from 'react';
import { Formik } from 'formik';
import { func, object, bool } from 'prop-types';

import ErrorFocus from '~utils/ErrorFocus';

const Context = createContext();

export const { Consumer } = Context;

class FormWrapper extends Component {
  state = { setSubmitting: null, focusedInput: null };

  handleFocus = (e) => this.setState({ focusedInput: e.target.name });

  handleBlur = () => this.setState({ focusedInput: null });

  handleSubmit = (values, actions) => {
    const { onActionsChange } = this.props;
    const { setSubmitting } = actions;

    this.setState({ setSubmitting });
    if (onActionsChange) {
      onActionsChange(actions);
    }
    this.props.onSubmit(values, actions);
  };

  componentDidUpdate(prevProps) {
    const { isSubmitting } = this.props;
    const { setSubmitting } = this.state;

    if (isSubmitting !== prevProps.isSubmitting && !isSubmitting && this.state.setSubmitting) {
      setSubmitting(false);
    }
  }

  render() {
    const {
      initialValues,
      validationSchema,
      children,
      avoidErrorUntilSubmit,
      enableReinitialize
    } = this.props;
    const { focusedInput } = this.state;
    const injectProps = {
      focusedInput,
      avoidErrorUntilSubmit,
      handleFocus: this.handleFocus,
      handleBlur: this.handleBlur
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        enableReinitialize={enableReinitialize}>
        {({ handleChange, values, errors, submitCount, setFieldValue, ...props }) => {
          const fieldProps = { handleChange, values, errors, submitCount, setFieldValue };
          return (
            <Context.Provider value={{ ...fieldProps, ...injectProps }}>
              {children({ ...fieldProps, ...props, ...injectProps })}
              <ErrorFocus />
            </Context.Provider>
          );
        }}
      </Formik>
    );
  }
}

FormWrapper.propTypes = {
  onSubmit: func.isRequired,
  avoidErrorUntilSubmit: bool,
  enableReinitialize: bool,
  initialValues: object, // eslint-disable-line react/forbid-prop-types
  isSubmitting: bool,
  validationSchema: object, // eslint-disable-line react/forbid-prop-types
  onActionsChange: func
};

export default FormWrapper;
