export const DATA_TEST_ID = {
  categoryName: 'category-name',
  checkboxButtonModifiers: 'product-detail-modifier-group',
  checkoutBuy: 'checkout-buy',
  closeMiniCart: 'close-mini-cart',
  deliveryOrPickupSelector: 'dispatch-type-modal',
  errorMessage: 'error-message',
  goToCheckout: 'go-to-checkout',
  login: 'login',
  logout: 'logout',
  logoutCloseModal: 'logout-close-modal',
  logoutMessage: 'logout-message',
  openCart: 'open-cart',
  paymentMethod: 'payment-method',
  productName: 'product-name',
  searchAddressResults: 'search-address-results',
  tabDelivery: 'tab-delivery',
  tabPickup: 'tab-pickup',
  verificationCodeError: 'verification-code-error',
  productDetailModifier: 'product-detail-modifier',
  productDetailGroupModifierTitle: 'product-detail-group-modifier-title',
  emptyCart: 'empty-cart',
  closedStoreMessage: 'closed-store-message',
  pickupStoreButton: 'pickup-store-button',
  scheduledOrderOpenModal: 'scheduled-order-open-modal',
  toogleSwitchScheduleOrder: 'toogle-switch-schedule-order',
  toogleSwitchNow: 'toogle-switch-now',
  selectDateScheduledOrder: 'select-date-scheduled-order',
  selectTimeScheduledOrder: 'select-time-scheduled-order',
  buttonConfirmScheduledOrder: 'button-confirm-scheduled-order',
  addQuantityProductButton: 'add-quantity-product-button',
  subtractQuantityProductButton: 'subtract-quantity-product-button',
  addItemQuantityButton: 'add-item-quantity-button',
  removeItemQuantityButton: 'remove-item-quantity-button',
  productDetail: 'product-detail',
  cancelUpselling: 'cancel-upselling',
  productDetailAddProductButton: 'product-detail-add-product-button'
};
