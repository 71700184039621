import i18next from 'i18next';

i18next.addResources('es_CL', 'StoreInfo', {
  titleStoreClosed: 'Estamos cerrados momentaneamente',
  titleStoreClosedDelivery: 'Momentáneamente sin delivery',
  titleStoreClosedPickup: 'Momentáneamente sin retiro en tienda',
  titleStoreClosedFutureOrder:
    'La tienda se encuentra cerrada, pero puedes programar tu orden para envío a domicilio.',
  subtitleStoreClosedDelivery: 'Los horarios de atención para delivery son:',
  subtitleStoreClosedLocal: 'Los horarios de atención para retiro en tienda son:',
  unavailableForPickup: 'La tienda no se encuentra disponible para retiro en local',
  unavailableForDelivery: 'La tienda no se encuentra disponible para delivery',
  availableTitle: 'Esta tienda por ahora',
  availableDelivery: 'solo está recibiendo pedidos para delivery.',
  availablePickup: 'solo está recibiendo pedidos para retiro en local.',
  continueDelivery: 'Continuar con delivery',
  continuePickup: 'Continuar con retiro en local',
  availableForFutureOrder: 'Abrimos a las {{value}}, pero puedes agendar tu pedido',
  storeClosedwithOpeningTime: 'Tienda cerrada, abrimos a las {{value}}',
  schedules: 'Horarios',
  monday: 'Lun',
  tuesday: 'Mar',
  wednesday: 'Mié',
  thursday: 'Jue',
  friday: 'Vie',
  saturday: 'Sáb',
  sunday: 'Dom',
  noData: 'Elige un local',
  allDay: '24hs',
  today: 'hoy',
  tomorrow: 'mañana',
  fullMonday: 'Lunes',
  fullTuesday: 'Martes',
  fullWednesday: 'Miércoles',
  fullThursday: 'Jueves',
  fullFriday: 'Viernes',
  fullSaturday: 'Sábado',
  fullSunday: 'Domingo',
  january: 'Enero',
  february: 'Febrero',
  march: 'Marzo',
  april: 'Abril',
  may: 'Mayo',
  june: 'Junio',
  july: 'Julio',
  august: 'Agosto',
  september: 'Septiembre',
  october: 'Octubre',
  november: 'Noviembre',
  december: 'Diciembre'
});
