import React from 'react';
import i18next from 'i18next';
import { Link } from 'react-router-dom';

import { CLIENT_PAGES } from '~constants/pages';

import styles from './styles.module.scss';

function ScheduledOrderMessage({ children }) {
  return (
    <div className={styles.container}>
      <span className={styles.leading}>{i18next.t('TopSiteMessage:attentive')}</span>
      <span className={styles.content}>{children}</span>
      <Link to={CLIENT_PAGES.ORDERS.path}>
        <span>{i18next.t('TopSiteMessage:tracking')}</span>
        <span className={styles.trackingOrder}>{i18next.t('TopSiteMessage:trackingOrder')}</span>
      </Link>
    </div>
  );
}

export default ScheduledOrderMessage;
