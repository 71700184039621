/* eslint-disable camelcase */
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import ReactPixel from '@bettercart/react-facebook-pixel';

import { history } from '~redux/store';
import DataLayerService from '~services/DataLayer/service';

import { checkoutObject, ga4mappers } from './utils';

ReactGA.plugin.require('ec');

const optionsFBPixel = {
  autoConfig: true,
  debug: false
};

const MERCAT_UA = process.env.REACT_APP_GOOGLE_ANALYTICS_UA;

const COMMERCE_TRACKER = 'commerceTracker';
const MERCAT_TRACKER = 'mercatTracker';

const TRACKERS = [MERCAT_TRACKER, COMMERCE_TRACKER];

const setPageView = (page) => {
  ReactPixel.pageView();
  ReactGA4.send('pageview');
  TRACKERS.forEach((tracker) => {
    ReactGA.set({ page }, [tracker]);
    ReactGA.ga(`${tracker}.send`, 'pageview', { page });
  });
};

const setUp = ({ analyticsUA, analyticsGA4, pixel }) => {
  const mercatArgs = {
    trackingId: MERCAT_UA,
    gaOptions: { name: MERCAT_TRACKER }
  };
  if (analyticsUA) {
    const commerceArgsUA = {
      trackingId: analyticsUA,
      gaOptions: { name: COMMERCE_TRACKER }
    };
    ReactGA.initialize([mercatArgs, commerceArgsUA], {
      debug: false,
      alwaysSendToDefaultTracker: false
    });
  }
  if (analyticsGA4) {
    const commerceArgsGA4 = {
      trackingId: analyticsGA4,
      gaOptions: { name: COMMERCE_TRACKER }
    };
    ReactGA4.initialize([mercatArgs, commerceArgsGA4], {
      debug: false,
      alwaysSendToDefaultTracker: false
    });
  }
  ReactPixel.init(pixel, null, optionsFBPixel);
  if (window) {
    setPageView(window.location.pathname);
  }
  TRACKERS.forEach((tracker) => ReactGA.ga(`${tracker}.require`, 'ec'));
  history.listen((location) => {
    setPageView(location.pathname);
  });
};

const purchaseEvent = (store, { data: { id, totalPrice, totalPriceCurrency, items = [] } }) => {
  const {
    core: {
      settings: { commerceName }
    }
  } = store;
  const PURCHASE = {
    id,
    revenue: totalPrice,
    affiliation: commerceName,
    currency: totalPriceCurrency
  };
  TRACKERS.forEach((tracker) => {
    items.forEach(({ count, category, name, basePrice }) => {
      const PRODUCT = {
        id,
        quantity: count,
        category,
        name,
        price: basePrice,
        currency: totalPriceCurrency,
        brand: commerceName
      };
      ReactGA.plugin.execute(`${tracker}.ec`, 'addToCart', PRODUCT);
      ReactGA4.gtag('event', 'add_to_cart', ga4mappers.addToCart(PRODUCT));
    });
    ReactGA.plugin.execute(`${tracker}.ec`, 'setAction', 'purchase', PURCHASE);
    if (items.length > 0) {
      ReactGA4.gtag(
        'event',
        'purchase',
        ga4mappers.purchase(items, commerceName, id, totalPrice, totalPriceCurrency)
      );
    }
    if (window) {
      ReactGA.ga(`${tracker}.send`, 'pageview', {
        page: window.location.pathname
      });
    }
  });
  ReactPixel.track(
    'Purchase',
    {
      currency: totalPriceCurrency,
      value: totalPrice
    },
    {
      eventID: `${id}`
    }
  );
};

const addToCartEvent = (
  store,
  {
    item: {
      count,
      product: { name, id },
      category,
      basePrice
    },
    currency
  }
) => {
  const {
    core: {
      settings: { commerceName }
    }
  } = store;
  ReactPixel.track(
    'AddToCart',
    {
      content_id: id,
      content_name: name,
      content_category: category,
      content_type: 'product',
      value: basePrice,
      currency
    },
    { eventID: id }
  );
  const PRODUCT = {
    id,
    quantity: count,
    category,
    name,
    price: basePrice,
    currency,
    brand: commerceName
  };
  DataLayerService.addToCart({ item: PRODUCT, currency, revenue: basePrice });
  ReactGA4.gtag('event', 'add_to_cart', ga4mappers.addToCart(PRODUCT));
  TRACKERS.forEach((tracker) => {
    ReactGA.plugin.execute(`${tracker}.ec`, 'addProduct', PRODUCT);
    ReactGA.ga(`${tracker}.send`, 'event', 'UX', 'click', 'add to cart');
  });
};

const inCheckoutEvent = (cart, commerceName, currency) => {
  const checkoutData = checkoutObject(cart, commerceName, currency);
  DataLayerService.inCheckoutEvent(checkoutData);
  TRACKERS.forEach((tracker) => {
    ReactGA.ga(`${tracker}.send`, 'event', 'Ecommerce', 'click', 'checkout');
  });
  ReactGA4.event({
    category: 'Ecommerce',
    action: 'click',
    label: 'checkout'
  });
};

export default { setUp, purchaseEvent, addToCartEvent, inCheckoutEvent };
