import React, { useEffect } from 'react';
import i18next from 'i18next';
import { func, bool } from 'prop-types';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Input from '~components/Input';
import Field from '~components/Field';
import LocalStorageService from '~services/LocalStorageService';
import AuthActions from '~redux/Auth/actions';
import { CODE_COUNTRY } from '~constants/environment';

import styles from './styles.module.scss';
import { INPUTS, FIELDS } from './constants';

function CompleteProfile({ setFieldValue, edition }) {
  const handleChange = (e) => setFieldValue(e.target.name, e.target.value);
  const { currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    const profile = currentUser || LocalStorageService.getProfile();
    if (profile) {
      setFieldValue(FIELDS.NAME, profile.name);
      setFieldValue(FIELDS.EMAIL, profile.email);
      setFieldValue(FIELDS.PHONE, profile.phone?.substring(CODE_COUNTRY.length));
    }
  }, [currentUser, setFieldValue]);

  const dispatch = useDispatch();
  const closeSession = () => dispatch(AuthActions.closeSession());

  return (
    <>
      <div className={`column wrap ${styles.container}`}>
        {INPUTS.map((item) => (
          <div
            key={item.name}
            id={item.name}
            className={cn('row', styles.containerStyle, styles.containerResponsiveStyle)}>
            <Field
              component={Input}
              name={item.name}
              key={item.name}
              prefix={item.prefix}
              containerStyle="m-top-2 full-width"
              placeholder={item.placeholder}
              title={item.title}
              spanStyle={styles.inputTitle}
              inputStyle={`full-width ${styles.profileInput} ${
                edition ? styles.inputEnabled : styles.inputDisabled
              }`}
              onChange={handleChange}
              type={item.type}
              readOnly={!edition}
            />
          </div>
        ))}
      </div>
      {currentUser && (
        <div className={styles.linkContainer}>
          <button onClick={closeSession} type="button" className="m-top-3">
            <span className={`material-icons-outlined m-right-2 font-size-15 ${styles.iconLogout}`}>
              logout
            </span>
            <span className="text-emperor">{i18next.t('Profile:logout')}</span>
          </button>
        </div>
      )}
    </>
  );
}

CompleteProfile.propTypes = {
  setFieldValue: func.isRequired,
  edition: bool
};

export default CompleteProfile;
