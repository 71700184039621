/* eslint-disable id-length */
export const API_ERROR_CODES = {
  default: 9999,
  e9999: 'DEFAULT_ERROR',
  e1001: 'FIELD_VALIDATION_ERROR',
  e1002: 'NOT_FOUND',
  e1003: 'PARAMETER_MISSING',
  e1004: 'DATABASE_REPEATED_VALUE',
  e1005: 'EXCLUSION_DELIVERY_ZONE',
  e1006: 'NOT_AUTHORIZED',
  e1007: 'INVALID_PARAMETER',
  e1008: 'MIDDLEWARE_MODEL_ERROR',
  e1009: 'USERS_MODEL_ERROR',
  e1010: 'SERVICE_ERROR',
  e1011: 'UNPROCESSABLE_ITEMS',
  e1012: 'SAILPLAY_COMMUNICATION_ERROR',
  e1014: 'SAILPLAY_VALIDATION_ERROR',
  e1019: 'RECORD_NOT_DESTROYED',
  e1020: 'OPERATION_NOT_SUPPORTED',
  e1021: 'INVALID_TOKEN',
  e1022: 'JOB_NOT_FOUND',
  e1024: 'STORE_CLOSED',
  e1025: 'PARAMETER_NOT_RELATED',
  e1026: 'DELIVERY_MINIMUM_NOT_REACHED',
  e1027: 'COGNITO_INVALID_PARAMETER',
  e1028: 'COGNITO_EMAIL_EXISTS',
  e1029: 'COGNITO_USER_NOT_FOUND',
  e1030: 'ORDER_HAS_ALREADY_BEEN_PAID',
  e1031: 'INVALID_ONEPAY_CHANNEL',
  e1032: 'INVALID_ORDER_STATUS_FOR_ONLINE_PAYMENT',
  e1033: 'ONEPAY_TRANSACTION_COULD_NOT_BE_COMMITED',
  e1035: 'INVALID_INGENICO_REQUEST',
  e1036: 'INGENICO_CONFIRMATION_FAILED',
  e1037: 'PHONE_TAKEN',
  e1038: 'ORDER_CREATION_BLOCKED',
  e1039: 'WEBPAY_REQUEST_FAILED',
  e1040: 'INVALID_COUPON_CODE',
  e1041: 'SHUTDOWN_ACTIVE',
  e1042: 'TOO_MANY_REQUEST',
  e1043: 'ORDER_CANT_BE_REPROCESSED',
  e1044: 'MAX_ALLOWED_ORDER_AMOUNT',
  e1045: 'MIN_ALLOWED_ORDER_AMOUNT_WITH_POINTS',
  e1048: 'INVALID_CATEGORY_COUPON',
  e1049: 'COUPON_EXPIRED',
  e1050: 'COUPON_MAX_REACHED',
  e1051: 'COUPON_NOT_AVAILABLE',
  e1052: 'MAX_COUPON_USES_BY_USER_REACHED',
  e1053: 'COUPON_DISPATCH_METHOD',
  e1054: 'QUESTION_HAS_ANSWERS_ASSOCIATED',
  e1055: 'COUPON_CANNOT_BE_USED_BY_USER',
  e1058: 'COUPON_WITH_MINIMUM_BILL',
  e1059: 'COUPON_PLATFORM_ERROR',
  e1068: 'NOT_FIRST_PURCHASE',
  e1069: 'INVALID_INVITATION_CODE',
  e1071: 'CART_INCLUDE_ONLY_OFFERS',
  e1072: 'RULE_NOT_COMPLETED',
  e1076: 'INVALID_MINIMUM_START_TIME',
  e1077: 'INVALID_MAXIMUM_EXPIRY_TIME',
  e1080: 'COUPON_OFFER_NOT_PRESENT'
};

export const API_ERRORS = {
  DEFAULT_ERROR: 9999,
  FIELD_VALIDATION_ERROR: 1001,
  NOT_FOUND: 1002,
  PARAMETER_MISSING: 1003,
  DATABASE_REPEATED_VALUE: 1004,
  EXCLUSION_DELIVERY_ZONE: 1005,
  NOT_AUTHORIZED: 1006,
  INVALID_PARAMETER: 1007,
  MIDDLEWARE_MODEL_ERROR: 1008,
  USERS_MODEL_ERROR: 1009,
  SERVICE_ERROR: 1010,
  UNPROCESSABLE_ITEMS: 1011,
  SAILPLAY_COMMUNICATION_ERROR: 1012,
  SAILPLAY_VALIDATION_ERROR: 1014,
  RECORD_NOT_DESTROYED: 1019,
  OPERATION_NOT_SUPPORTED: 1020,
  INVALID_TOKEN: 1021,
  JOB_NOT_FOUND: 1022,
  STORE_CLOSED: 1024,
  PARAMETER_NOT_RELATED: 1025,
  DELIVERY_MINIMUM_NOT_REACHED: 1026,
  COGNITO_INVALID_PARAMETER: 1027,
  COGNITO_EMAIL_EXISTS: 1028,
  COGNITO_USER_NOT_FOUND: 1029,
  ORDER_HAS_ALREADY_BEEN_PAID: 1030,
  INVALID_ONEPAY_CHANNEL: 1031,
  INVALID_ORDER_STATUS_FOR_ONLINE_PAYMENT: 1032,
  ONEPAY_TRANSACTION_COULD_NOT_BE_COMMITED: 1033,
  INVALID_INGENICO_REQUEST: 1035,
  INGENICO_CONFIRMATION_FAILED: 1036,
  PHONE_TAKEN: 1037,
  ORDER_CREATION_BLOCKED: 1038,
  WEBPAY_REQUEST_FAILED: 1039,
  INVALID_COUPON_CODE: 1040,
  SHUTDOWN_ACTIVE: 1041,
  TOO_MANY_REQUEST: 1042,
  ORDER_CANT_BE_REPROCESSED: 1043,
  MAX_ALLOWED_ORDER_AMOUNT: 1044,
  MIN_ALLOWED_ORDER_AMOUNT_WITH_POINTS: 1045,
  // TODO: ADD REMAINING ERRORS
  INVALID_CATEGORY_COUPON: 1048,
  COUPON_EXPIRED: 1049,
  COUPON_MAX_REACHED: 1050,
  COUPON_NOT_AVAILABLE: 1051,
  MAX_COUPON_USES_BY_USER_REACHED: 1052,
  COUPON_DISPATCH_METHOD: 1053,
  QUESTION_HAS_ANSWERS_ASSOCIATED: 1054,
  COUPON_CANNOT_BE_USED_BY_USER: 1055,
  COUPON_WITH_MINIMUM_BILL: 1058,
  COUPON_PLATFORM_ERROR: 1059,
  NOT_FIRST_PURCHASE: 1068,
  INVALID_INVITATION_CODE: 1069,
  CART_INCLUDE_ONLY_OFFERS: 1071,
  RULE_NOT_COMPLETED: 1072,
  INVALID_MINIMUM_START_TIME: 1076,
  INVALID_MAXIMUM_EXPIRY_TIME: 1077,
  COUPON_OFFER_NOT_PRESENT: 1080
};

export const BASE_ERROR = {
  code: 9999,
  description: API_ERROR_CODES.e9999,
  message: 'Malformed error from API'
};
