import i18next from 'i18next';

import { CODE_COUNTRY } from '~constants/environment';

export const FIELDS = {
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'phone'
};

export const INPUTS = [
  {
    name: FIELDS.NAME,
    title: i18next.t('CompleteProfile:name'),
    placeholder: i18next.t('CompleteProfile:fullnamePlaceholder'),
    icon: 'icon-person2',
    type: 'text'
  },
  {
    name: FIELDS.PHONE,
    title: i18next.t('CompleteProfile:phone'),
    placeholder: i18next.t('CompleteProfile:phonePlaceholder'),
    icon: 'icon-phone1',
    type: 'tel',
    prefix: CODE_COUNTRY
  },
  {
    name: FIELDS.EMAIL,
    title: i18next.t('CompleteProfile:email'),
    placeholder: i18next.t('CompleteProfile:emailPlaceholder'),
    icon: 'icon-mail1',
    type: 'email'
  }
];
