import Immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer, onReadValue } from 'redux-recompose';

import LocalStorageService from '~services/LocalStorageService';
import { DISPATCH_METHODS } from '~constants/address';

import { actions } from './actions';
import { onGetStores, onGetAllStores } from './effects';
import {
  NEAR_TARGET,
  DELIVERY_TARGET,
  CURRENT_SUBSIDIARY_TARGET,
  DISPATCH_METHOD_TARGET,
  DISPATCH_TYPE_TAB_TARGET,
  USER_ADDRESS,
  USER_ADRESSES,
  STORES_TARGET,
  EXTERNAL_STORE_TARGET,
  SCHEDULED_DATE_AVAILABILITY,
  ALL_STORES
} from './constants';

const initialState = completeState(
  {
    [NEAR_TARGET]: null,
    [DELIVERY_TARGET]: {},
    [CURRENT_SUBSIDIARY_TARGET]: LocalStorageService.getCurrentSubsidiary() || null,
    [DISPATCH_METHOD_TARGET]: LocalStorageService.getDispatchType() || DISPATCH_METHODS.DELIVERY,
    [DISPATCH_TYPE_TAB_TARGET]: DISPATCH_METHODS.DELIVERY,
    [USER_ADDRESS]: {},
    [USER_ADRESSES]: null,
    [STORES_TARGET]: null,
    [EXTERNAL_STORE_TARGET]: null,
    [SCHEDULED_DATE_AVAILABILITY]: null,
    [ALL_STORES]: null
  },
  [DISPATCH_METHOD_TARGET]
);

initialState[`${NEAR_TARGET}Loading`] = true;
initialState[`${DELIVERY_TARGET}Loading`] = true;

const reducerDescription = {
  primaryActions: [
    actions.PICK_UP,
    actions.DELIVERY,
    actions.GET_USER_ADDRESS,
    actions.GET_STORES,
    actions.GET_NEAR,
    actions.GET_SCHEDULED_DATE_AVAILABILITY,
    actions.GET_ALL_STORES,
    actions.GET_STORE_DATA
  ],
  override: {
    [actions.SET_VALUE]: onReadValue(),
    [actions.GET_STORES_SUCCESS]: onGetStores,
    [actions.GET_ALL_STORES_SUCCESS]: onGetAllStores,
    [actions.GET_STORE_DATA_SUCCESS]: (state, { payload: { store } }) =>
      state.merge({ ...state, currentSubsidiary: store })
  }
};

const reducer = createReducer(new Immutable(initialState), completeReducer(reducerDescription));

export default reducer;
