import i18next from 'i18next';

i18next.addResources('es_CL', 'ModifierGroup', {
  outOfStock: 'Agotado',
  noStock: 'Sin stock',
  required: 'Requerido',
  pick: 'Elige {{pick}}',
  optional: 'Opcional',
  outOfStockUntil: 'Reservar',
  willBeAbleOn: 'Estará disponible desde el {{ableOn}}. Programa tu pedido para comprarlo.',
  date: '{{dayName}}, {{day}} de {{month}}, {{time}}',
  availableOnlyOn: 'Este producto solo está disponible los {{days}}. Programa tu pedido para comprarlo.',
  availableOnlyOnCurrentOutOfStock:
    'Este producto solo está disponible para el {{dayName}} {{day}} de {{month}}. Programa tu pedido para ese día para comprarlo.'
});
