import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { number, func } from 'prop-types';

import { DATA_TEST_ID } from '~constants/tests';
import Text from '~components/Text';

import styles from './styles.module.scss';

const className = (condition) =>
  cn(`material-icons-outlined row middle center`, styles.countButton, {
    [styles.disabled]: condition
  });

function ItemQuantity({ initialQuantity, maxQuantityCondition, handleChange, setQuantityItemChecked }) {
  const [quantity, setQuantity] = useState(initialQuantity || 1);
  const changeQuantity = (num) => {
    setQuantity(quantity + num);
    handleChange(quantity + num)();
  };
  useEffect(() => {
    if (quantity === 0) {
      setQuantityItemChecked(false);
    }
  });

  return (
    <div className={`${styles.countContainer} row middle`}>
      <button
        data-testid={DATA_TEST_ID.removeItemQuantityButton}
        className="m-right-2"
        disabled={quantity === 0}
        type="button"
        onClick={() => changeQuantity(-1)}>
        <span className={className(quantity === 0)}>remove</span>
      </button>
      <Text className="m-right-2">{quantity}</Text>
      <button
        data-testid={DATA_TEST_ID.addItemQuantityButton}
        disabled={maxQuantityCondition(quantity)}
        type="button"
        onClick={() => changeQuantity(1)}>
        <span className={className('plus', maxQuantityCondition(quantity))}>add</span>
      </button>
    </div>
  );
}

ItemQuantity.propTypes = {
  handleChange: func.isRequired,
  initialQuantity: number.isRequired,
  maxQuantityCondition: func.isRequired,
  setQuantityItemChecked: func.isRequired
};

export default ItemQuantity;
