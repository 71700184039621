import i18next from 'i18next';
import { bool, func, string } from 'prop-types';
import React from 'react';

import styles from './styles.module.scss';

function LoginDiscounts({ onClick, rewardPointsName, className, isLoyaltyActivated }) {
  return (
    <button type="button" onClick={onClick} className={styles.button}>
      <div className={`${styles.login} ${className}`}>
        <p>
          <i>{i18next.t('CompleteProfile:couponLogin')}</i>
          {i18next.t(
            `CompleteProfile:${isLoyaltyActivated ? 'couponLoginDescription' : 'defaultLoginDescription'}`,
            {
              rewardPointsName
            }
          )}
        </p>
      </div>
    </button>
  );
}

LoginDiscounts.propTypes = {
  className: string,
  isLoyaltyActivated: bool,
  rewardPointsName: string,
  onClick: func
};

export default LoginDiscounts;
