import i18next from 'i18next';

import { PAYMENT_METHODS } from '~constants/paymentMethods';

export const CREDIT_CARDS_TYPES = {
  Visa: 'icon-cc-visa-solid',
  Mastercard: 'icon-cc-mastercard-solid',
  Debit: 'icon-payment-debit-card'
};

export const ADD_CREDIT_CARD = {
  name: i18next.t('PaymentMethods:addCard'),
  icon: CREDIT_CARDS_TYPES.Debit
};
export const PAYMENT_METHOD = 'paymentMethod';
export const ID_CARD = 'idCard';
export const CARD_TOKEN = 'cardToken';
export const CREDIT_CARD = PAYMENT_METHODS.card.name;
