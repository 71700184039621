import { snakeCase } from 'snake-case';
import i18next from 'i18next';

import { API_ERRORS } from '~constants/apiErrors';
import { CODE_COUNTRY } from '~constants/environment';
import { PAYMENT_METHODS } from '~constants/paymentMethods';
import { STATUS_CODES } from '~constants/statusCodes';
import { mapUser } from '~redux/Auth/mappers';
import { DISPATCH_METHODS } from '~constants/address';

const itemsMapper = (items) =>
  items.map(({ product: { id, name }, count, items: modifiers, instructions }) => ({
    productId: id,
    name,
    quantity: count,
    instructions,
    ...(Object.values(modifiers).length > 0 && {
      modifiers: Object.values(modifiers)
        .flat()
        .map(({ id: modifierId, name: modifierName, quantity, parentId }) => ({
          id: modifierId,
          name: modifierName,
          action: 'add',
          quantity,
          parentId
        }))
    })
  }));

export const guestUserMapper = ({ name, email, phone, auth }) => {
  const {
    address: {
      current: { textAddress, latitude, longitude }
    }
  } = auth;
  const userData = {
    name,
    email,
    phone: `${CODE_COUNTRY}${phone}`,
    externalId: null,
    loginType: 'email'
  };
  const addressData = { textAddress, latitude, longitude };
  return { user: userData, address: addressData };
};

const mapDispatchNote = (dispatchNote, deliveryOption, dispatchType) => {
  if (dispatchType === DISPATCH_METHODS.DELIVERY) {
    return `${dispatchNote ? `${dispatchNote} - ` : ''}${i18next.t(
      `DeliveryOptions:${deliveryOption?.[0]?.name}`
    )}`;
  }

  return '';
};

const uberMapper = ({ uberEstimateId, uberEstimateExpiresAt }) =>
  uberEstimateId && uberEstimateExpiresAt ? { uberEstimateId, uberEstimateExpiresAt } : {};

export const orderCreationMapper = ({
  email,
  name,
  phone,
  paymentMethod,
  deliveryOption,
  coupon,
  tip,
  storeTip,
  checkoutAnswers,
  emailSubscription,
  loyaltyPoints
}) => (state) => {
  const {
    auth: {
      address: {
        current: { dispatchNote }
      }
    },
    searchStore: {
      dispatchType,
      currentSubsidiary: { id: storeId, timeZone }
    },
    cart: { cart },
    order: { futureOrder }
  } = state;

  return {
    order: {
      ...mapUser({ email, name, phone }, null, state),
      paymentMethod: PAYMENT_METHODS[paymentMethod]?.isOnline ? 'online' : paymentMethod,
      dispatchNote: mapDispatchNote(dispatchNote, deliveryOption, dispatchType),
      storeId,
      dispatchMethod: dispatchType,
      itemsAttributes: itemsMapper(cart),
      coupon: { code: coupon, paymentMethod: snakeCase(paymentMethod) },
      tip,
      storeTip,
      checkoutAnswers,
      pointsToUse: loyaltyPoints
    },
    timeZone,
    emailSubscription,
    onlinePayment: PAYMENT_METHODS[paymentMethod]?.isOnline,
    ...(futureOrder && {
      requestedTime: `${futureOrder.date} ${futureOrder.time}`
    }),
    ...uberMapper(state.cart)
  };
};

export const orderCreationErrorsMapper = (order) => ({ data }) => {
  const error = data?.errors || data || { code: API_ERRORS.DEFAULT_ERROR };
  if (error.code === STATUS_CODES.TIMEOUT) {
    return i18next.t('RequestErrors:timeout');
  } else if (API_ERRORS.UNPROCESSABLE_ITEMS === error.code) {
    const items = order.shoppingCart.items
      .filter((item) => error.message.items.find((errorItem) => errorItem.menuable_id === item.menuableId))
      .map((item) => item.name)
      .join(', ');
    return i18next.t(`APIErrors:e${error.code}`, { items });
  }
  return i18next.t(`APIErrors:e${error.code}`, { ...error.metadata });
};

export const mapKushkiError = (code) => {
  const kushkiErrorCodes = {
    K001: i18next.t('KushkiCardForm:errorForm'),
    default: i18next.t('KushkiCardForm:error')
  };

  return kushkiErrorCodes[code] ? kushkiErrorCodes[code] : kushkiErrorCodes.default;
};
