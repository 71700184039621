import i18next from 'i18next';
import countries from 'i18n-iso-countries';

import {
  GOOGLE_MAPS_URL,
  STREET_NUMBER,
  ADDRESS_TYPES,
  DEFAULT_ANCHOR_ICON_SIZE,
  DEFAULT_SCALED_ICON_SIZE,
  CITY,
  GOOGLE_MAPS_SEARCH_URL
} from '~constants/maps';
import { REQUEST_OK_GOOGLE } from '~constants/search';
import { ISO_COUNTRY, LANGUAGE } from '~constants/environment';
import { deserializer } from '~services/baseSerializers';
import { USE_PLACES_ADDRESS } from '~constants/features';

export const getStoreMapURL = (location, store) =>
  `${GOOGLE_MAPS_URL}&origin=${location.lat}%2C${location.lng}&destination=${store.latitude}%2C${store.longitude}`;

export const getStoreMap = (store) =>
  `${GOOGLE_MAPS_SEARCH_URL}&query=${store.latitude}%2C${store.longitude}`;

export const createPosition = (item) =>
  item?.latitude && item?.longitude ? { lat: item.latitude, lng: item.longitude } : null;

const getAddressObject = (addressComponents) => {
  const result = {};

  addressComponents.forEach((component) => {
    const componentType = component.types[0];

    Object.keys(ADDRESS_TYPES).forEach((item) => {
      if (ADDRESS_TYPES[item].find((type) => type === componentType) && !result[item]) {
        result[item] = component.longName;
      }
    });
  });

  return result;
};

const getFormattedAddress = ({ addressComponents }) => {
  const addressObject = getAddressObject(addressComponents);

  const regionOrCommune = addressObject.commune || addressObject.region;
  const street = addressObject.street || regionOrCommune;
  const streetNumber = addressObject.streetNumber || '';

  const formattedAddress = `${street}${streetNumber ? ` ${streetNumber}` : ''}`;

  return formattedAddress;
};

const hasType = (result, TYPE) =>
  result?.addressComponents?.some((addressComponent) =>
    addressComponent?.types?.some((type) => type === TYPE)
  );

const findType = (result, TYPE) =>
  result?.addressComponents?.find((addressComponent) =>
    addressComponent?.types?.find((type) => type === TYPE)
  );

const parseGeocodeResult = (rawData, location) => {
  const result = deserializer.serialize(rawData);
  const hasStreetNumber = USE_PLACES_ADDRESS || hasType(result, STREET_NUMBER);
  let textAddress = '';

  if (USE_PLACES_ADDRESS) {
    textAddress =
      location?.description ||
      result.formattedAddress.split(countries.getName(ISO_COUNTRY, LANGUAGE, { select: 'official' }))[0];
  } else {
    textAddress = getFormattedAddress(result);
  }

  const city = hasType(result, CITY) && findType(result, CITY)?.longName;

  return {
    placeId: result.placeId,
    textAddress,
    latitude: result.geometry.location.lat(),
    longitude: result.geometry.location.lng(),
    hasStreetNumber,
    city
  };
};

export const normalizeCoordinates = (coords) => {
  if (coords.lat && coords.lng) {
    return { lat: Number(coords.lat), lng: Number(coords.lng) };
  } else if (coords?.geometry?.location) {
    return {
      lat: Number(coords.geometry.location.lat()),
      lng: Number(coords.geometry.location.lng())
    };
  } else if (coords.latitude && coords.longitude) {
    return { lat: Number(coords.latitude), lng: Number(coords.longitude) };
  }

  return { lat: null, lng: null };
};

export const parseLocation = ({ location, placeId }, callback) => {
  if (!window.google) {
    throw new Error(i18next.t('Maps:NoGoogleObject'));
  }

  const geocoder = new window.google.maps.Geocoder();

  if (placeId) {
    geocoder.geocode({ placeId }, (results, status) => {
      if (status === REQUEST_OK_GOOGLE && results[0]) {
        callback(parseGeocodeResult(results[0], location));
      }

      return null;
    });
  } else if (location) {
    const coords = normalizeCoordinates(location);
    const LatLng = new window.google.maps.LatLng(coords);

    geocoder.geocode({ location: LatLng }, (results, status) => {
      if (status === REQUEST_OK_GOOGLE && results[0]) {
        callback(parseGeocodeResult(results[0]));
      }

      return null;
    });
  }
};

export const filterUserAddresses = (addresses, max) => {
  if (!addresses) {
    return [];
  }
  const filteredAddresses = {
    home: [],
    work: [],
    couple: [],
    other: []
  };

  addresses.forEach((address) =>
    filteredAddresses[address.kind]?.push({
      ...address,
      description: address.textAddress
    })
  );

  return filteredAddresses.home
    .concat(filteredAddresses.work, filteredAddresses.couple, filteredAddresses.other)
    .slice(0, max);
};

export const createMapsIcon = (
  icon,
  anchorSize = DEFAULT_ANCHOR_ICON_SIZE,
  scaledSize = DEFAULT_SCALED_ICON_SIZE
) =>
  window.google && {
    url: icon,
    anchor: new window.google.maps.Point(anchorSize, anchorSize),
    scaledSize: new window.google.maps.Size(scaledSize, scaledSize)
  };
