import i18next from 'i18next';

i18next.addResources('es_CL', 'FutureOrder', {
  whenYouWantYourOrder: '¿Para cuando quieres el pedido?',
  asap: 'Cuanto antes',
  scheduleOrder: 'Programar horario',
  date: 'Fecha',
  time: 'Hora',
  notAvailableHours: 'Lamentablemente la tienda no cuenta con horarios disponibles para programar envíos.',
  selectOtherOption:
    'Debido al tiempo de demora del producto agregado, debes elegir un nuevo horario para programar tu orden',
  cancel: 'Cancelar'
});
