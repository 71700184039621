import i18next from 'i18next';

i18next.addResources('es_CL', 'OrderModalStoreInfo', {
  cash: 'Pago efectivo',
  card: 'Pago con tarjeta',
  online: 'Pago online',
  oneclick: 'Pago con Oneclick',
  address: 'Dirección:',
  storeInfo: 'Datos del local',
  deliveryDetail: 'Detalle de la entrega'
});
