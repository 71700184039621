export const NEAR_TARGET = 'near';
export const DELIVERY_TARGET = 'delivery';
export const CURRENT_SUBSIDIARY_TARGET = 'currentSubsidiary';
export const DISPATCH_METHOD_TARGET = 'dispatchType';
export const DISPATCH_TYPE_TAB_TARGET = 'dispatchTypeTab';
export const USER_ADDRESS = 'userAddress';
export const USER_ADRESSES = 'userAddresses';
export const USED_COUPONS = 'usedCoupons';
export const COMPANIES_TARGET = 'companies';
export const STORES_TARGET = 'stores';
export const EXTERNAL_STORE_TARGET = 'externalStoreId';
export const NPS_URL = '/nps';
export const DONT_SHOW_ALERTS = [NPS_URL];
export const FUTURE_ORDERS_ENABLED = 'futureOrdersEnabled';
export const SCHEDULED_DATE_AVAILABILITY = 'scheduledDateAvailability';
export const STORES_BY_COMMUNE_TARGET = 'storesByCommune';
export const ALL_STORES = 'allStores';
