import React from 'react';
import Rollbar from 'rollbar';
import { Provider, ErrorBoundary } from '@rollbar/react';

import { rollbarConfig } from './config';

const rollbar = new Rollbar(rollbarConfig);

function RollbarProvider({ children }) {
  if (!rollbar.options.accessToken) {
    return children;
  }

  return (
    <Provider instance={rollbar}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
  );
}

export default RollbarProvider;
