import i18next from 'i18next';

i18next.addResources('es_CL', 'OrderTrackingDetail', {
  title: 'Detalles de tu pedido',
  estimatedTimeTitle: 'Tiempo estimado para la entrega',
  destination: 'Envío a',
  driver: 'Repartidor',
  pickUp: 'Retiro en',
  from: 'Desde',
  minute: '{{minutes}} minuto',
  minutes: '{{minutes}} minutos',
  nearDropoff: 'Llegando a entregar tu pedido'
});
