export const ICONS = {
  delivery: 'delivery_dining',
  pickup: 'storefront',
  pin: 'icon-location2',
  arrowLeft: 'icon-arrow-left',
  arrowRight: 'icon-arrow-right',
  angleUp: 'icon-angle-up',
  clock: 'clock'
};

export const CART_ICONS = {
  bag: 'shopping_bag',
  cart: 'shopping_cart'
};
