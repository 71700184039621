import React from 'react';
import { func, shape } from 'prop-types';
import cn from 'classnames';
import i18next from 'i18next';

import styles from './styles.module.scss';
import { MAX_LENGTH_ANSWER } from './constants';
import useQuestions from './hooks/useQuestions';

function Questions({ setFieldValue, values }) {
  const { answers, handleOnChange } = useQuestions({ setFieldValue, values });

  return (
    <>
      {answers.map(({ answerFieldName, ...question }) => (
        <div key={question.checkoutQuestionId} className={`full-width ${styles.question}`}>
          <div className="font-size-15 text-boulder bold">{question.checkoutQuestion}</div>
          <div className="full-width m-top-2">
            <textarea
              name={answerFieldName}
              onChange={handleOnChange(answerFieldName, question)}
              className={cn('font-size-14 text-emperor full-width', styles.answer)}
              placeholder={i18next.t('Questions:answerPlaceholder')}
              value={question.answer}
              maxLength={MAX_LENGTH_ANSWER}
            />
          </div>
        </div>
      ))}
    </>
  );
}

Questions.propTypes = {
  setFieldValue: func.isRequired,
  values: shape({}).isRequired
};

export default Questions;
