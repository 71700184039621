import i18next from 'i18next';

export const FIRST_PAGE = 1;

export const PROCESSING_ADD_CARD_PATHS = ['/processing-oneclick', '/processing-front-card-subscription'];

export const CLIENT_PAGES = {
  HOME: {
    exact: false,
    noCartFooter: false,
    path: ['/product/:category?/:name?', '/:category?/:name?'],
    basePath: ''
  },
  CHECKOUT: {
    exact: true,
    noCartFooter: true,
    noNavbar: true,
    path: ['/checkout/product/:category?/:name?', '/checkout/:category?/:name?'],
    basePath: '/checkout',
    title: i18next.t('Commerce:checkout'),
    description: i18next.t('Commerce:checkoutDescription')
  },
  PURCHASE_SUCCESS: {
    exact: true,
    noCartFooter: true,
    path: '/purchase-success',
    title: i18next.t('Commerce:purchaseSuccess'),
    description: i18next.t('Commerce:purchaseSuccessDescription')
  },
  PURCHASE_FAILURE: {
    exact: true,
    noCartFooter: true,
    path: '/purchase-failure',
    title: i18next.t('Commerce:purchaseFailure'),
    description: i18next.t('Commerce:purchaseFailureDescription')
  },
  PROCESSING_PURCHASE: {
    exact: true,
    noCartFooter: true,
    path: '/processing-purchase',
    title: i18next.t('Commerce:processingPurchase'),
    description: i18next.t('Commerce:processingPurchaseDescription')
  },
  ORDERS: {
    exact: true,
    noCartFooter: true,
    path: '/orders',
    title: i18next.t('Commerce:orders'),
    description: i18next.t('Commerce:ordersDescription')
  },
  TERMS_AND_CONDITIONS: {
    exact: true,
    noCartFooter: true,
    path: '/terms-and-conditions',
    title: i18next.t('Commerce:termsAndConditions'),
    description: i18next.t('Commerce:termsAndConditionsDescription')
  },
  MERCAT_TERMS_AND_CONDITIONS: {
    exact: true,
    noCartFooter: true,
    path: '/mercat-terms-and-conditions',
    title: i18next.t('Commerce:mercatTermsAndConditions'),
    description: i18next.t('Commerce:mercatTermsAndConditions')
  },
  UNAVAILABLE: {
    exact: true,
    path: i18next.t('Commerce:notAvailable'),
    noNavbar: true,
    noContainer: true,
    noFooter: true
  },
  PROFILE: {
    exact: true,
    path: '/profile',
    title: i18next.t('Commerce:profile'),
    description: i18next.t('Commerce:profileDescription')
  },
  PROCESSING_ADD_CARD: {
    exact: true,
    noCartFooter: true,
    path: PROCESSING_ADD_CARD_PATHS,
    title: i18next.t('Commerce:processingAddCard'),
    description: i18next.t('Commerce:processingAddCard')
  },
  ADD_CARD_SUCCESS: {
    exact: true,
    path: '/add-card-success',
    title: i18next.t('AddCardSuccess:title'),
    description: i18next.t('AddCardSuccess:message')
  },
  ADD_CARD_FAILURE: {
    exact: true,
    path: '/add-card-failure',
    title: i18next.t('AddCardFailure:title'),
    description: i18next.t('AddCardFailure:message')
  },
  NPS: {
    exact: true,
    path: '/nps',
    description: i18next.t('AddCardFailure:message'),
    noFooter: true,
    noNavbar: true
  },
  MENU: {
    exact: true,
    path: '/menu/:id',
    description: i18next.t('PublicMenu:message')
  }
};
