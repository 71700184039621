import { createTypes, completeTypes } from 'redux-recompose';

import ProductService from '~services/Product/service';

export const actions = createTypes(completeTypes(['GET_PRODUCT_BY_ID'], ['CLEAN_PRODUCT']), '@@PRODUCT');

const targets = {
  PRODUCT: 'product'
};

const actionCreators = {
  getProductById: (id) => ({
    type: actions.GET_PRODUCT_BY_ID,
    target: targets.PRODUCT,
    payload: id,
    service: ProductService.getProductById
  }),
  cleanProduct: () => ({
    type: actions.CLEAN_PRODUCT,
    target: targets.PRODUCT,
    payload: {}
  })
};

export default actionCreators;
