import i18next from 'i18next';

i18next.addResources('es_CL', 'NPS', {
  icJB: 'Just Burger icon',
  title: 'Valoramos tu opinión, queremos mejorar.',
  questionsTitle:
    'Nos interesa tu experiencia con nuestro servicio. Ayúdanos a mejorarlo respondiendo estas preguntas:',
  recommendation: '¿Qué tan probable es que nos recomiendes a tus amigos y familiares?',
  tasteQuestion: '¿Qué te pareció el sabor de nuestros productos?',
  deliveryQuestion: '¿Qué te pareció la atención de nuestro repartidor?',
  additionalComment: '¿Tienes un comentario adicional?',
  additionalCommentPlaceholder: 'Cuéntanos tu experiencia de hoy....',
  answerError: 'Error al crear la encuesta',
  answerOk: '¡Gracias por responder la encuesta!',
  alreadyOk: 'Encuesta ya respondida'
});
