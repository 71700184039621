import i18next from 'i18next';

i18next.addResources('es_CL', 'DeliveryOptions', {
  title: 'Opciones de entrega',
  deliverDoor: 'Entregar en la puerta',
  waitOutside: 'Esperar afuera',
  leaveInDoor: 'Dejar el pedido en la puerta',
  instructions: 'Agregar instrucciones',
  writeHere: 'Escribe acá.'
});
