import ScheduledOrderMessage from './components/ScheduledOrderMessage';

export const KINDS = {
  INFO: 'info',
  WARNING: 'warning',
  ALERT: 'alert',
  SUCCESS: 'success'
};

export const MESSAGES_TYPES = {
  scheduledOrder: 'scheduledOrder'
};

export const MESSAGES_COMPONENTS = {
  [MESSAGES_TYPES.scheduledOrder]: ScheduledOrderMessage
};
