import moment from 'moment';

import { dayFormat } from '~utils/time';

export const mapDateToOptions = (dates) =>
  Object.keys(dates).reduce(
    (ac, el) => ({
      ...ac,
      ...(dates[el][0] && {
        [dates[el][0].split(' ')[0]]: {
          label: dayFormat(dates[el][0].split(' ')[0], true, true),
          value: dates[el][0].split(' ')[0],
          times: dates[el].reduce((aacc, eell) => [...aacc, { label: eell.split(' ')[1] }], [])
        }
      })
    }),
    {}
  );

export const getSelectedDateTime = ({ dateOptions, preselectedFutureOrder, nextAvailableDay }) =>
  dateOptions[preselectedFutureOrder.date].times.reduce((selectedItem, time, index) => {
    const preselectedTime = moment(preselectedFutureOrder.time, 'HH:mm');
    const comparingTime = moment(time.label, 'HH:mm');

    if (comparingTime.isAfter(preselectedTime) && !selectedItem.time) {
      return { date: preselectedFutureOrder.date, time: time.label };
    }

    if (dateOptions[preselectedFutureOrder.date]?.times.length - 1 === index && !selectedItem.time) {
      return {
        date: nextAvailableDay,
        time: dateOptions[nextAvailableDay].times[0].label
      };
    }

    return selectedItem;
  }, {});
