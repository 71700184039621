import { useState } from 'react';
import { useDispatch } from 'react-redux';

import LocalStorageService from '~services/LocalStorageService';
import { actionCreators as authActions } from '~redux/Auth/actions';
import ModalActions from '~redux/Modal/actions';
import actionCreators from '~redux/Order/actions';
import { MODALS } from '~redux/Modal/constants';

import { cardSubscriptionMapper } from './constants';

const useKushkiSubscription = () => {
  const dispatch = useDispatch();
  const accessToken = LocalStorageService.getAccessToken();
  const [kushkiSubscriptionError, setKushkiSubscriptionError] = useState(false);
  const [kushkiSubscriptionLoading, setKushkiSubscriptionLoading] = useState(false);

  const kushkiSubscriptionSuccess = () => {
    setKushkiSubscriptionLoading(false);
    dispatch(ModalActions.closeModal(MODALS.KUSHKI));
    dispatch(authActions.getCreditCards(accessToken));
  };

  const kushkiSubscriptionFailure = () => {
    setKushkiSubscriptionError(true);
    setKushkiSubscriptionLoading(false);
  };

  const handleCardSubscriptionSubmit = (values) => {
    setKushkiSubscriptionLoading(true);
    setKushkiSubscriptionError(false);
    const cardDetails = cardSubscriptionMapper(values);
    dispatch(
      actionCreators.getKushkiSubscriptionToken(
        cardDetails,
        kushkiSubscriptionSuccess,
        kushkiSubscriptionFailure
      )
    );
  };

  return {
    kushkiSubscriptionLoading,
    kushkiSubscriptionError,
    handleCardSubscriptionSubmit
  };
};

export default useKushkiSubscription;
