import { func, number, shape } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import i18next from 'i18next';

import { checkoutValuesPropTypes } from '~propTypes/checkoutValues';
import { pluralize, capitalize } from '~utils/string';

import CouponDiscount from './components/CouponDiscount';
import GuestUser from './components/GuestUser';
import RewardDisclaimer from './components/RewardDisclaimer';
import RewardPoints from './components/RewardPoints';
import styles from './styles.module.scss';

export default function Discounts({ values, setFieldValue }) {
  const { currentUser } = useSelector((state) => state.auth);
  const moneyAvailable = useSelector((state) => state?.auth?.currentUser?.points?.moneyAvailable);
  const { minAmount, name } = useSelector((state) => state?.core?.settings?.loyaltyProgramParams || {});
  const isLoyaltyActivated = useSelector((state) => state?.core?.settings?.loyaltyProgram);
  const hasMoney = moneyAvailable >= minAmount;

  return (
    <div>
      {!currentUser ? (
        <GuestUser isLoyaltyActivated={isLoyaltyActivated} />
      ) : (
        <div className={styles.rewardContainer}>
          <div className={cn({ 'm-bottom-8': isLoyaltyActivated })}>
            <CouponDiscount
              setFieldValue={setFieldValue}
              {...(values?.paymentMethod && {
                paymentMethod: values?.paymentMethod
              })}
            />
          </div>
          {isLoyaltyActivated && (
            <>
              <div className="m-bottom-4">
                {hasMoney ? (
                  <RewardPoints
                    loyaltyProgramName={name}
                    setFieldValue={setFieldValue}
                    {...(values?.paymentMethod && {
                      paymentMethod: values?.paymentMethod
                    })}
                  />
                ) : (
                  <RewardDisclaimer
                    title={i18next.t('RewardPoints:disclaimerNotEnoughPoints', {
                      rewardPointsName: capitalize(pluralize(name))
                    })}
                    message={i18next.t('RewardPoints:disclaimerDescription')}
                  />
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

Discounts.propTypes = {
  errors: shape({}),
  setFieldValue: func,
  submitCount: number,
  values: checkoutValuesPropTypes.isRequireds
};
