import React from 'react';
import i18next from 'i18next';
import { string } from 'prop-types';

import { DISPATCH_METHODS } from '~constants/address';

function GetDispathMethodDetail({ dispatchType, currentSubsidiary, address, className }) {
  return (
    <div className={className}>
      {dispatchType === DISPATCH_METHODS.DELIVERY ? (
        <>
          <span className={className}>{address} </span>
          <br />
          <span className={className}>
            {`${i18next.t('Delivery:from', {
              value: currentSubsidiary?.name,
              interpolation: { escapeValue: false }
            })}`}
          </span>
        </>
      ) : (
        <span>
          {i18next.t('Delivery:inStore', {
            value: `${currentSubsidiary?.name}, ${currentSubsidiary.textAddress}`
          })}
        </span>
      )}
    </div>
  );
}

GetDispathMethodDetail.propTypes = {
  address: string,
  className: string,
  currentSubsidiary: string,
  dispatchType: string
};

export default GetDispathMethodDetail;
