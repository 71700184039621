export const replaceSpaceWithDashes = (string) => string.replace(/\s/g, '-');

export const slugify = (value) => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return value
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(p, (c) => b.charAt(a.indexOf(c)))
    .replace(/&/g, '')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};

export const camelize = (string) => {
  const newString = string.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
  return newString.substr(0, 1).toLowerCase() + newString.substr(1);
};

export const capitalize = (string) => string[0].toUpperCase() + string.substring(1).toLowerCase();

export const pluralize = (word) => (word[word.length - 1] != 's' ? `${word}s` : word);
