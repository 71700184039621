import { createTypes, completeTypes, withPostSuccess } from 'redux-recompose';

import LocalStorageService from '~services/LocalStorageService';
import CoreService from '~services/Core/service';

import { mapTenant } from './mappers';

export const actions = createTypes(
  completeTypes(
    ['GET_SETTINGS', 'GET_TENANT', 'GET_DEFAULT_STORE_ID'],
    ['SET_TEMPLATE', 'SET_DEFAULT_STORE_ID']
  ),
  '@@CORE'
);

const targets = {
  SETTINGS: 'settings',
  TENANT: 'tenant',
  TEMPLATE_MODEL: 'templateModel',
  DEFAULT_STORE_ID: 'defaultStoreId'
};

const actionCreators = {
  getSettings: () => ({
    type: actions.GET_SETTINGS,
    service: CoreService.getSettings,
    target: targets.SETTINGS,
    injections: [
      withPostSuccess((dispatch, response, state) => {
        dispatch(actionCreators.setTemplate(state.core.settings.templateId));
        LocalStorageService.setPrimaryColor(response.data.brandPrimary);
        LocalStorageService.setAnimationLoader(response.data.animationLoader);
      })
    ]
  }),
  setTemplate: (tenantID) => ({
    type: actions.SET_TEMPLATE,
    target: targets.TEMPLATE_MODEL,
    payload: mapTenant(tenantID)
  }),
  getDefaultStoreId: () => ({
    type: actions.GET_DEFAULT_STORE_ID,
    service: CoreService.getDefaultStoreId,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionCreators.setDefaultSoreId(response.data.id));
      })
    ]
  }),
  setDefaultSoreId: (id) => ({
    type: actions.SET_DEFAULT_STORE_ID,
    target: targets.DEFAULT_STORE_ID,
    payload: id
  })
};

export default actionCreators;
