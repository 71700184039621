import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { boolean } from 'prop-types';

import { MODALS } from '~redux/Modal/constants';
import CustomModal from '~components/CustomModal';
import ModalActions from '~redux/Modal/actions';

import styles from './styles.module.scss';

function KushkiModal({ children, loading }) {
  const dispatch = useDispatch();
  const handleClose = () => dispatch(ModalActions.closeModal(MODALS.KUSHKI));
  const open = useSelector((state) => state.modal[`${MODALS.CONFIRMATION_ORDER}ModalIsOpen`]);
  const isKushkiModalOpen = useSelector((state) => state.modal[`${MODALS.KUSHKI}ModalIsOpen`]);

  return (
    <CustomModal
      hideCloseButton
      className={styles.modal}
      modal={MODALS.KUSHKI}
      isOpen={isKushkiModalOpen}
      portalClassName={open ? styles.hidden : 'ReactModalPortal'}
      shouldCloseOnEsc={!loading}
      shouldCloseOnOverlayClick={!loading}>
      <div className={styles.modalContainer}>
        <div className={styles.buttonContainer}>
          <button type="button" onClick={handleClose} disabled={loading}>
            <i className="material-icons">close</i>
          </button>
        </div>
        <div>{children}</div>
      </div>
    </CustomModal>
  );
}

KushkiModal.propTypes = {
  loading: boolean
};

export default KushkiModal;
