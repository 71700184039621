import { ALL_STORES, STORES_TARGET } from './constants';

const makePage = (state, action, target) => {
  return state[target]?.page && action.payload.currentPage !== 1
    ? [...state[target].page, ...action.payload.page]
    : action.payload.page;
};

const addingLatAndLng = (data) => {
  const newDataStores = data.reduce((acc, store) => {
    const { latitude, longitude } = store;
    return {
      ...acc,
      ...store,
      lat: latitude,
      lng: longitude
    };
  }, []);
  return newDataStores;
};

const merger = (state, action, target, data) => {
  return state.merge({
    [`${target}Loading`]: false,
    [target]: {
      ...action.payload,
      data
    }
  });
};

export const onGetStores = (state, action) => {
  const page = makePage(state, action, STORES_TARGET);
  const newData = addingLatAndLng(page);
  return merger(state, action, STORES_TARGET, newData);
};

export const onGetAllStores = (state, action) => {
  const sortedData = [...action.payload.sort((a, b) => a.pickUpWaitTime - b.pickUpWaitTime)];
  return state.merge({
    [`${ALL_STORES}Loading`]: false,
    [ALL_STORES]: sortedData
  });
};
