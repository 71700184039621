import React from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';

import { getMaxTime } from '~utils/product';

import styles from './styles.module.scss';

const DeferredDelivery = () => {
  const { cart } = useSelector((state) => state.cart);
  const deferredProducts = cart.filter(({ product }) => product.deferred);
  const maxTime = getMaxTime(deferredProducts);
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        {i18next.t('Products:deferredDelivery')}
        <span className={styles.time}>
          {maxTime} {i18next.t('Products:hour')}
        </span>
        {i18next.t('Products:beReady')}
      </div>
    </div>
  );
};

export default DeferredDelivery;
