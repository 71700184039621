import React from 'react';
import { string, element, oneOfType, func } from 'prop-types';

import { Consumer } from '~components/FormWrapper';

function Field({ name, placeholder, type, icon, component: Component, ...props }) {
  return (
    <Consumer>
      {({
        handleFocus,
        handleBlur,
        handleChange,
        values,
        errors,
        submitCount,
        focusedInput,
        avoidErrorUntilSubmit,
        setFieldValue
      }) => (
        <Component
          name={name}
          placeholder={placeholder}
          icon={icon}
          value={values?.[name]}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChangeFormik={handleChange}
          error={(avoidErrorUntilSubmit || submitCount > 0) && errors[name]}
          isFocused={focusedInput === name}
          setFieldValue={setFieldValue}
          type={type}
          {...props}
        />
      )}
    </Consumer>
  );
}

Field.propTypes = {
  component: oneOfType([element, func]),
  icon: string,
  name: string,
  placeholder: string,
  type: string
};

export default Field;
