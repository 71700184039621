import i18next from 'i18next';

i18next.addResources('es_CL', 'Orders', {
  actualOrder: 'Pedido actual',
  completed: 'Orden cumplida',
  created: 'Orden recibida',
  firstOrder: 'Haz tu primer pedido!',
  goToMenu: 'Ir a menú',
  inTransit: 'En tránsito',
  myOrders: 'Mis órdenes',
  noOrders: 'No tienes pedidos actualmente',
  noUserToken: 'No se pudieron generar las credenciales para hacer el pedido',
  orderDetail: 'Detalles de la entrega',
  orderProducts: 'Productos',
  ordersHistory: 'Historial de pedidos',
  orderTime: 'Tu pedido estará listo para recoger en ',
  preparing: 'Orden confirmada',
  rateOrder: 'Dar opinión',
  ready: 'Lista para retiro',
  repeatOrder: 'Repetir pedido',
  scheduled: 'Orden Programada',
  scheduledOrderTitle: 'Fecha estimada de entrega',
  canceled: 'Orden cancelada',
  canceledDescription: 'Tu orden ha sido cancelada',
  seeDetail: 'Ver detalle',
  storeData: 'Datos del local',
  orderId: 'Orden #{{orderId}}',
  preparingDescription: 'Estamos preparando tu pedido',
  createdDescription: 'Esperando confirmación del restaurant',
  scheduledDescription: 'Fecha estimada de entrega',
  scheduledData: '{{dayName}} {{day}} de {{month}} {{time}}',
  readyDescription: '¡Ya puedes venir por tu pedido!',
  inTransitDescription: '¡Tu pedido va en camino!',
  total: 'Total',
  orderMin: '{{minutes}} minutos',
  orderApprox: 'aproximadamente'
});

const pickUpYourOrder = 'Camino a buscar tu pedido';
i18next.addResources('es_CL', 'DeliveryStatus', {
  CONFIRMED: pickUpYourOrder,
  IN_PROGRESS: pickUpYourOrder,
  NEAR_PICKUP: 'Recogiendo tu pedido',
  PICKED_UP: 'Camino a entregar tu pedido',
  NEAR_DROPOFF: 'Llegando a entregar tu pedido',
  COMPLETED: 'Pedido entregado'
});
