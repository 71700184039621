import { push } from 'connected-react-router';
import i18next from 'i18next';
import {
  createTypes,
  completeTypes,
  withPreFetch,
  withPostSuccess,
  withPostFailure,
  withSuccess
} from 'redux-recompose';

import { isPayingWithQr } from '~models/paymentMethods';
import ModalActions from '~redux/Modal/actions';
import CartActions from '~redux/Cart/actions';
import authActions from '~redux/Auth/actions';
import Auth from '~services/Auth/service';
import { MODALS } from '~redux/Modal/constants';
import { CLIENT_PAGES } from '~constants/pages';
import OrderService from '~services/Order/service';
import LocalStorageService from '~services/LocalStorageService';
import DataLayerService from '~services/DataLayer/service';
import { PAYMENT_METHODS_NAMES } from '~constants/paymentMethods';
import { actionCreators as NotificationActions } from '~redux/Notification/actions';
import { NOTIFICATION_DANGER } from '~redux/Notification/constants';
import { API_ERRORS } from '~constants/apiErrors';
import { isSmallDevice } from '~utils/screenSize';
import { FIELDS } from '~screens/Commerce/screens/Checkout/constants';
import { deserializer } from '~services/baseSerializers';
import { actionCreators as creditCardsActions } from '~redux/CreditCards/actions';

import { TARGETS, PAYMENT_STATUS, INTERVAL_REQUEST, REDIRECT_URL, ERROR_MODALS } from './constants';
import { orderCreationMapper, orderCreationErrorsMapper, guestUserMapper, mapKushkiError } from './mappers';
import { redirectPost, parseCredomaticForm } from './utils';

export const actions = createTypes(
  completeTypes([
    'CREATE_ORDER',
    'ONLINE_PAYMENT',
    'GET_ORDERS',
    'GET_ACTIVE_ORDERS',
    'GET_LAST_ORDER',
    'ADD_PURCHASE_TO_DATALAYER',
    'SET_TIP',
    'GET_QUESTIONS',
    'CREATE_ANSWERS',
    'SET_ORDERS_TAB',
    'SET_FUTURE_ORDER',
    'SET_PRESELECTED_FUTURE_ORDER_DATE',
    'GET_KUSHKI_TOKEN',
    'CLEAR_KUSHKI_TOKEN_ERROR',
    'GET_KUSHKI_SUBSCRIPTION_TOKEN',
    'GET_KUSHKI_DEVICE_TOKEN'
  ]),
  '@@ORDER'
);

export const privateActionCreators = {
  createOrderFailure: (payload) => ({
    type: actions.CREATE_ORDER_FAILURE,
    target: TARGETS.CURRENT_ORDER_TARGET,
    payload
  }),
  createOrderSuccess: (payload) => ({
    type: actions.CREATE_ORDER_SUCCESS,
    target: TARGETS.CURRENT_ORDER_TARGET,
    payload
  })
};

export const actionCreators = {
  setTip: (tip, target = FIELDS.TIP) => ({
    type: actions.SET_TIP,
    target: target,
    payload: tip
  }),
  clearCart: () => (dispatch) => {
    const EMPTY_CART = [];
    dispatch(CartActions.setCart(EMPTY_CART));
    dispatch(CartActions.setError(0));
    dispatch(CartActions.setDiscount(0));
    LocalStorageService.setCart(EMPTY_CART);
  },
  handleCreateOrder: (order) => (dispatch) => {
    const guestUser = LocalStorageService.getGuestUser();
    const accessToken = LocalStorageService.getAccessToken();

    const createOrderAction =
      guestUser?.token || accessToken ? actionCreators.createOrder : actionCreators.createGuestOrder;

    if (order.paymentMethod === PAYMENT_METHODS_NAMES.kushki) {
      dispatch(actionCreators.getKushkiToken(order, createOrderAction));
      return;
    }

    if (order.paymentMethod === PAYMENT_METHODS_NAMES.kushkiCardSubscription) {
      dispatch(actionCreators.getKushkiDeviceToken(order, createOrderAction));
      return;
    }

    dispatch(createOrderAction(order));
  },
  getKushkiToken: (order, createOrderAction) => (dispatch, getState) => {
    const state = getState();
    const { total } = state.cart;
    const { kushkiPublicMerchantId, country } = state.core.settings;
    const { isoCurrencyCode } = country || {};

    dispatch({
      type: actions.GET_KUSHKI_TOKEN,
      target: TARGETS.KUSHKI_TOKEN,
      payload: {
        total,
        kushkiPublicMerchantId,
        currency: isoCurrencyCode,
        order
      },
      service: OrderService.getKushkiToken,
      failureSelector: (data) => ({
        error: mapKushkiError(data.code),
        code: data.code
      }),
      injections: [
        withPostSuccess((_, data) => {
          dispatch(createOrderAction(order, data.token));
        }),
        withPostFailure(() => {
          dispatch(ModalActions.clearModals());
        })
      ]
    });
  },
  getKushkiDeviceToken: (order, createOrderAction) => (dispatch, getState) => {
    const state = getState();
    const { kushkiPublicMerchantId } = state.core.settings;

    dispatch({
      type: actions.GET_KUSHKI_DEVICE_TOKEN,
      target: TARGETS.KUSHKI_DEVICE_TOKEN,
      payload: { cardToken: order.cardToken, kushkiPublicMerchantId },
      service: OrderService.getKushkiDeviceToken,
      failureSelector: (data) => ({
        error: mapKushkiError(data.code),
        code: data.code
      }),
      injections: [
        withPostSuccess((_, data) => {
          dispatch(createOrderAction(order, data.token));
        }),
        withPostFailure(() => {
          dispatch(ModalActions.clearModals());
        })
      ]
    });
  },
  createOrder: (order, kushkiToken) => (dispatch) => {
    const guestUser = LocalStorageService.getGuestUser();
    const token = guestUser?.token || LocalStorageService.getAccessToken();
    dispatch({
      type: actions.CREATE_ORDER,
      target: TARGETS.CREATE_ORDER,
      payload: orderCreationMapper(order),
      service: OrderService.createOrder,
      failureSelector: orderCreationErrorsMapper(order),
      injections: [
        withPreFetch(() => {
          dispatch({
            type: actions.CREATE_ORDER,
            target: TARGETS.CREATE_ORDER
          });
        }),
        withPostSuccess((_, response) => {
          dispatch(CartActions.removeRemoteCart(response.data.id));

          if (order.paymentMethod === PAYMENT_METHODS_NAMES.webpay && response.data) {
            dispatch(actionCreators.webPayStartPayment(response.data));
          } else if (order.paymentMethod === PAYMENT_METHODS_NAMES.oneClick && response.data) {
            dispatch(
              actionCreators.oneClickStartPayment(
                order.idCard,
                order.installmentsNumber,
                response.data,
                token
              )
            );
          } else if (order.paymentMethod === PAYMENT_METHODS_NAMES.sodexo && response.data) {
            dispatch(actionCreators.sodexoStartPayment(response.data));
          } else if (order.paymentMethod === PAYMENT_METHODS_NAMES.mercadoPago && response.data) {
            dispatch(actionCreators.mercadoPagoStartPayment(response.data));
          } else if (
            [PAYMENT_METHODS_NAMES.chek, PAYMENT_METHODS_NAMES.mach, PAYMENT_METHODS_NAMES.fpay].includes(
              order.paymentMethod
            ) &&
            response.data
          ) {
            dispatch(actionCreators.qrStartPayment(response.data, order.paymentMethod));
          } else if (order.paymentMethod === PAYMENT_METHODS_NAMES.getnet && response.data) {
            dispatch(actionCreators.getnetStartPayment(response.data));
          } else if (order.paymentMethod === PAYMENT_METHODS_NAMES.kushki && response.data) {
            const { id } = response.data;
            dispatch(actionCreators.kushkiStartPayment(id, kushkiToken, order));
          } else if (order.paymentMethod === PAYMENT_METHODS_NAMES.kushkiCardSubscription && response.data) {
            dispatch(
              actionCreators.kushkiCardSubscriptionStartPayment(
                order.idCard,
                response.data,
                token,
                kushkiToken
              )
            );
          } else if (order.paymentMethod === PAYMENT_METHODS_NAMES.credomatic && response.data) {
            dispatch(ModalActions.closeModal(MODALS.CONFIRMATION_ORDER));
            dispatch(
              ModalActions.openModalWithData(`${MODALS.SET_CVC}ModalIsOpen`, {
                isOpen: true,
                orderId: response.data.id,
                paymentMethod: order.paymentMethod,
                redirectUrl: `${window.location.origin}${CLIENT_PAGES.PROCESSING_PURCHASE.path}`,
                cardId: order.idCard
              })
            );
          } else {
            dispatch(ModalActions.clearModals());
            dispatch(actionCreators.addPurchaseToDatalayer(response.data));
            dispatch(actionCreators.clearCart());
            dispatch(push(CLIENT_PAGES.PURCHASE_SUCCESS.path));
          }
        }),
        withPostFailure((_, response) => {
          dispatch(ModalActions.clearModals());
          dispatch(
            ModalActions.openModal(ERROR_MODALS[response?.data?.code] || MODALS.ORDER_CREATION_STATUS)
          );
          dispatch(authActions.getCurrentUser());
        })
      ]
    });
  },
  createGuestOrder: ({ name, email, phone, ...order }, kushkiToken) => (dispatch, getState) => {
    const store = getState();
    const data = guestUserMapper({ name, email, phone, auth: store.auth });
    dispatch(
      authActions.createGuestUser(
        { user: data.user, address: data.address },
        () => actionCreators.createOrder({ name, email, phone, ...order, isGuestOrder: true }, kushkiToken),
        (error) => privateActionCreators.createOrderFailure(error)
      )
    );
  },
  webPayStartPayment: (data) => ({
    type: actions.ONLINE_PAYMENT,
    target: TARGETS.ONLINE_PAYMENT_TARGET,
    service: OrderService.webPayStartPayment,
    payload: { id: data.id, redirectUrl: REDIRECT_URL },
    successSelector: (response) => response.data,
    injections: [
      withPostSuccess((dispatch, response) => {
        redirectPost(response.data.url, [{ token: response.data.token, inputName: 'token_ws' }]);
      }),
      withPostFailure((dispatch) => {
        dispatch(push(CLIENT_PAGES.PURCHASE_FAILURE.path));
        dispatch(ModalActions.closeModal(MODALS.CONFIRMATION_ORDER));
        dispatch(
          NotificationActions.show({
            message: i18next.t('PaymentMethods:messageErrorWebPay'),
            type: NOTIFICATION_DANGER
          })
        );
      })
    ]
  }),
  getnetStartPayment: (data) => ({
    type: actions.ONLINE_PAYMENT,
    target: TARGETS.ONLINE_PAYMENT_TARGET,
    service: OrderService.getnetStartPayment,
    payload: { id: data.id, redirectUrl: REDIRECT_URL },
    successSelector: (response) => response.data,
    injections: [
      withPostSuccess((dispatch, response) => {
        redirectPost(response.data.url);
      }),
      withPostFailure((dispatch) => {
        dispatch(push(CLIENT_PAGES.PURCHASE_FAILURE.path));
        dispatch(ModalActions.closeModal(MODALS.CONFIRMATION_ORDER));
        dispatch(
          NotificationActions.show({
            message: i18next.t('PaymentMethods:messageErrorGetnet'),
            type: NOTIFICATION_DANGER
          })
        );
      })
    ]
  }),
  oneClickStartPayment: (idCard, installmentsNumber, data, token) => ({
    type: actions.ONLINE_PAYMENT,
    target: TARGETS.ONLINE_PAYMENT_TARGET,
    service: OrderService.oneClickStartPayment,
    payload: { orderId: data.id, idCard, installmentsNumber, token },
    successSelector: (response) => response.data,
    injections: [
      withPostSuccess((dispatch) => {
        dispatch(ModalActions.clearModals());
        dispatch(actionCreators.clearCart());
        dispatch(push(CLIENT_PAGES.PROCESSING_PURCHASE.path));
      }),
      withPostFailure((dispatch) => {
        dispatch(ModalActions.clearModals());
        dispatch(push(CLIENT_PAGES.PURCHASE_FAILURE.path));
        dispatch(
          NotificationActions.show({
            message: i18next.t('PaymentMethods:messageErrorWebPay'),
            type: NOTIFICATION_DANGER
          })
        );
      })
    ]
  }),
  kushkiCardSubscriptionStartPayment: (idCard, data, token, kushkiDeviceToken) => ({
    type: actions.ONLINE_PAYMENT,
    target: TARGETS.ONLINE_PAYMENT_TARGET,
    service: OrderService.kushkiCardSubscriptionStartPayment,
    payload: { orderId: data.id, idCard, token, kushkiDeviceToken },
    successSelector: (response) => response.data,
    injections: [
      withPostSuccess((dispatch) => {
        dispatch(ModalActions.clearModals());
        dispatch(actionCreators.clearCart());
        dispatch(push(CLIENT_PAGES.PURCHASE_SUCCESS.path));
      }),
      withPostFailure((dispatch) => {
        dispatch(ModalActions.clearModals());
        dispatch(
          NotificationActions.show({
            message: i18next.t('PaymentMethods:messageErrorKushki'),
            type: NOTIFICATION_DANGER
          })
        );
      })
    ]
  }),
  credomaticStartPayment: ({ token, orderId, cardId, cvc }) => ({
    type: actions.ONLINE_PAYMENT,
    target: TARGETS.ONLINE_PAYMENT_TARGET,
    service: OrderService.credomaticStartPayment,
    payload: { token, orderId, cardId, cvc },
    successSelector: (response) => response.data,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionCreators.clearCart());
        dispatch(ModalActions.clearModals());
        if (response.data.html) {
          parseCredomaticForm(response.data.html);
        } else {
          dispatch(
            actionCreators.credomaticConfirmAMEX({
              orderId,
              result: response.data.result
            })
          );
        }
      }),
      withPostFailure((dispatch) => {
        dispatch(ModalActions.clearModals());
        dispatch(push(CLIENT_PAGES.PURCHASE_FAILURE.path));
        dispatch(
          NotificationActions.show({
            message: i18next.t('PaymentMethods:messageErrorWebPay'),
            type: NOTIFICATION_DANGER
          })
        );
      })
    ]
  }),
  credomaticConfirmAMEX: ({ orderId, result }) => ({
    type: actions.ONLINE_PAYMENT,
    target: TARGETS.ONLINE_PAYMENT_TARGET,
    service: OrderService.credomaticConfirmAMEX,
    payload: { orderId, result },
    successSelector: (response) => deserializer.serialize(response.data),
    injections: [
      withSuccess((dispatch) => {
        dispatch(push(CLIENT_PAGES.PURCHASE_SUCCESS.path));
      }),
      withPostFailure((dispatch) => {
        dispatch(ModalActions.closeModal(MODALS.CONFIRMATION_ORDER));
        dispatch(
          NotificationActions.show({
            message: i18next.t('PaymentMethods:messageErrorCreditCard'),
            type: NOTIFICATION_DANGER
          })
        );
      })
    ]
  }),
  sodexoStartPayment: (data) => ({
    type: actions.ONLINE_PAYMENT,
    target: TARGETS.ONLINE_PAYMENT_TARGET,
    service: OrderService.sodexoStartPayment,
    payload: { id: data.id, redirectUrl: REDIRECT_URL },
    successSelector: (response) => response.data,
    injections: [
      withPostSuccess((dispatch, response) => {
        redirectPost(response.data.url);
      }),
      withPostFailure((dispatch) => {
        dispatch(push(CLIENT_PAGES.PURCHASE_FAILURE.path));
        dispatch(ModalActions.closeModal(MODALS.CONFIRMATION_ORDER));
        dispatch(
          NotificationActions.show({
            message: i18next.t('PaymentMethods:messageErrorSodexo'),
            type: NOTIFICATION_DANGER
          })
        );
      })
    ]
  }),
  mercadoPagoStartPayment: (data) => ({
    type: actions.ONLINE_PAYMENT,
    target: TARGETS.ONLINE_PAYMENT_TARGET,
    service: OrderService.mercadoPagoStartPayment,
    payload: { id: data.id, redirectUrl: REDIRECT_URL },
    successSelector: (response) => response.data,
    injections: [
      withPostSuccess((dispatch, response) => {
        let prefId = response.data.url.split('=')[1];
        redirectPost(response.data.url, [{ token: prefId, inputName: 'pref_id' }]);
      }),
      withPostFailure((dispatch) => {
        dispatch(push(CLIENT_PAGES.PURCHASE_FAILURE.path));
        dispatch(ModalActions.closeModal(MODALS.CONFIRMATION_ORDER));
        dispatch(
          NotificationActions.show({
            message: i18next.t('PaymentMethods:messageErrorMercadoPago'),
            type: NOTIFICATION_DANGER
          })
        );
      })
    ]
  }),
  qrStartPayment: (data, payment) => ({
    type: actions.ONLINE_PAYMENT,
    target: TARGETS.ONLINE_PAYMENT_TARGET,
    service: OrderService.qrStartPayment,
    payload: {
      id: data.id,
      payment,
      ...([PAYMENT_METHODS_NAMES.chek, PAYMENT_METHODS_NAMES.fpay].includes(payment) && {
        redirectUrl: REDIRECT_URL
      })
    },
    successSelector: (response) => response.data,
    injections: [
      withPostSuccess((dispatch, response) => {
        const isSmall = isSmallDevice();
        if (payment !== PAYMENT_METHODS_NAMES.fpay && isSmall) {
          dispatch(ModalActions.clearModals());
          window.open(response?.data?.url, '_blank');
        } else {
          dispatch(ModalActions.clearModals());
          dispatch(
            ModalActions.openModalWithData(`${MODALS.PAYMENT_METHOD}ModalIsOpen`, {
              url: response?.data?.url,
              isLoading: false,
              paymentMethod: PAYMENT_METHODS_NAMES[payment]
            })
          );
        }
        const guestUser = LocalStorageService.getGuestUser();
        const accessToken = LocalStorageService.getAccessToken();
        dispatch(actionCreators.confirmPayment(guestUser?.token || accessToken));
      }),
      withPostFailure((dispatch) => {
        dispatch(push(CLIENT_PAGES.PURCHASE_FAILURE.path));
        dispatch(ModalActions.closeModal(MODALS.CONFIRMATION_ORDER));
      })
    ]
  }),
  kushkiStartPayment: (id, paymentToken, order) => ({
    type: actions.ONLINE_PAYMENT,
    target: TARGETS.ONLINE_PAYMENT_TARGET,
    service: OrderService.kuskiStartPayment,
    payload: { id, paymentToken },
    successSelector: (response) => response.data,
    injections: [
      withPostSuccess((dispatch) => {
        if (!order.isGuestOrder) {
          dispatch(actionCreators.getKushkiSubscriptionToken(order));
        }
        dispatch(ModalActions.clearModals());
        dispatch(actionCreators.clearCart());
        dispatch(push(CLIENT_PAGES.PURCHASE_SUCCESS.path));
      }),
      withPostFailure((dispatch) => {
        dispatch(ModalActions.clearModals());
        dispatch(push(CLIENT_PAGES.PURCHASE_FAILURE.path));
        dispatch(
          NotificationActions.show({
            message: i18next.t('PaymentMethods:messageErrorKushki'),
            type: NOTIFICATION_DANGER
          })
        );
      })
    ]
  }),
  getKushkiSubscriptionToken: (order, successCb, failureCb) => (dispatch, getState) => {
    const state = getState();
    const { currency, kushkiPublicMerchantId } = state.core.settings;
    const accessToken = LocalStorageService.getAccessToken();

    dispatch({
      type: actions.GET_KUSHKI_SUBSCRIPTION_TOKEN,
      target: TARGETS.KUSHKI_SUBSCRIPTION_TOKEN,
      payload: { kushkiPublicMerchantId, currency, order },
      service: OrderService.getKushkiSubscripionToken,
      injections: [
        withPostSuccess((_, data) => {
          dispatch(
            actionCreators.storeKushkiCard(
              accessToken,
              PAYMENT_METHODS_NAMES.kushkiCardSubscription,
              data.token,
              successCb,
              failureCb
            )
          );
        }),
        withPostFailure(() => {
          if (failureCb) {
            failureCb();
          }
        })
      ]
    });
  },
  storeKushkiCard: (token, subscriptionMethodEnabled, kushkiToken, successCb, failureCb) => ({
    type: actions.STORE_KUSHKI_CARD,
    payload: { token, subscriptionMethodEnabled, kushkiToken },
    service: Auth.addCreditCard,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(creditCardsActions.checkKushkiCardSubscription(response.data.token, successCb, failureCb));
      })
    ]
  }),
  confirmPayment: (token, sendToDataLayer = false) => ({
    type: actions.ONLINE_PAYMENT,
    target: TARGETS.ONLINE_PAYMENT_TARGET,
    service: OrderService.getLastOrder,
    payload: token,
    injections: [
      withPostSuccess((dispatch, response) => {
        const paymentStatus = response.data?.paymentStatus;
        const paymentMethod = response.data?.paymentMethod;
        if (paymentStatus && paymentStatus === PAYMENT_STATUS.COMPLETED && isPayingWithQr(paymentMethod)) {
          dispatch(push(CLIENT_PAGES.PROCESSING_PURCHASE.path, PAYMENT_STATUS.COMPLETED));
        } else if (paymentStatus && paymentStatus === PAYMENT_STATUS.COMPLETED) {
          if (sendToDataLayer) {
            dispatch(actionCreators.addPurchaseToDatalayer(response.data));
          }
          dispatch(push(CLIENT_PAGES.PURCHASE_SUCCESS.path));
          dispatch(ModalActions.clearModals());
          dispatch(actionCreators.clearCart());
          dispatch(actionCreators.setFutureOrderDate(null));
          LocalStorageService.removeFutureOrderDate();
        } else if (paymentStatus === PAYMENT_STATUS.UNKNOWN || paymentStatus === PAYMENT_STATUS.CREATED) {
          setTimeout(() => dispatch(actionCreators.confirmPayment(token)), INTERVAL_REQUEST);
        } else {
          dispatch(ModalActions.clearModals());
          dispatch(push(CLIENT_PAGES.PURCHASE_FAILURE.path));
        }
      }),
      withPostFailure((dispatch) => {
        dispatch(ModalActions.clearModals());
        dispatch(push(CLIENT_PAGES.PURCHASE_FAILURE.path));
      })
    ]
  }),
  getOrders: () => ({
    type: actions.GET_ORDERS,
    target: TARGETS.ORDERS,
    service: OrderService.getOrders
  }),
  getLastOrder: (token) => ({
    type: actions.GET_LAST_ORDER,
    target: TARGETS.LAST_ORDER,
    payload: token,
    service: OrderService.getLastOrder
  }),
  getActiveOrders: () => ({
    type: actions.GET_ACTIVE_ORDERS,
    target: TARGETS.ACTIVE_ORDERS,
    service: OrderService.getActiveOrders
  }),
  addPurchaseToDatalayer: (data) => ({
    type: actions.ADD_PURCHASE_TO_DATALAYER,
    target: TARGETS.ADD_PURCHASE_TO_DATALAYER,
    payload: data,
    service: DataLayerService.addPurchase
  }),
  getQuestions: (orderId, userId) => ({
    type: actions.GET_QUESTIONS,
    target: TARGETS.QUESTIONS,
    payload: { orderId, userId },
    service: OrderService.getQuestions
  }),
  createAnswers: (answers, userId, setWasSended) => ({
    type: actions.CREATE_ANSWERS,
    payload: { answers, userId },
    service: OrderService.createAnswers,
    injections: [
      withPostSuccess((dispatch) => {
        setWasSended(true);
        dispatch(
          ModalActions.openModalWithData(MODALS.TOP_SITE_MESSAGE, {
            kind: 'success',
            message: i18next.t('NPS:answerOk')
          })
        );
      }),
      withPostFailure((dispatch, response) => {
        dispatch(
          ModalActions.openModalWithData(MODALS.TOP_SITE_MESSAGE, {
            kind: 'alert',
            message:
              response.data.code === API_ERRORS.INVALID_PARAMETER
                ? i18next.t('NPS:alreadyOk')
                : i18next.t('NPS:answerError')
          })
        );
      })
    ]
  }),
  setOrdersTab: (tab) => ({
    type: actions.SET_ORDERS_TAB,
    target: TARGETS.ORDERS_TAB,
    payload: tab
  }),
  setFutureOrderDate: (params) => ({
    type: actions.SET_FUTURE_ORDER,
    target: TARGETS.FUTURE_ORDER,
    payload: params
  }),
  setPreselectedFutureOrderDate: (date) => ({
    type: actions.SET_PRESELECTED_FUTURE_ORDER_DATE,
    target: TARGETS.PRESELECTED_DATE,
    payload: date
  }),
  clearKushkiTokenError: () => ({
    type: actions.CLEAR_KUSHKI_TOKEN_ERROR,
    target: TARGETS.KUSHKI_TOKEN_ERROR
  })
};

export default actionCreators;
