/* eslint-disable import/prefer-default-export */
import React, { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { boolean } from 'prop-types';
import ReactTooltip from 'react-tooltip';
import MaskedInput from 'react-text-mask';

import { MODALS } from '~redux/Modal/constants';
import ModalActions from '~redux/Modal/actions';
import AlertMessage from '~components/AlertMessage';
import OrderActions from '~redux/Order/actions';
import Input from '~app/components/Input';
import { VARIANT_ICONS } from '~app/components/AlertMessage/constants';

import { CARD_FIELDS } from './fields';
import styles from './styles.module.scss';

export const KushkiCardForm = ({ enableOptionSubscribeCard }) => {
  const dispatch = useDispatch();
  const kushkiTokenError = useSelector((state) => state.order.kushkiTokenError);
  const currentUser = useSelector((state) => state.auth?.currentUser);
  const { errors, touched } = useFormikContext();

  const openLogin = () => dispatch(ModalActions.openModal(MODALS.LOGIN));

  useEffect(() => {
    return () => {
      dispatch(OrderActions.clearKushkiTokenError());
    };
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <Field
        title={i18next.t('KushkiCardForm:name')}
        as={Input}
        containerStyle={cn('full-width', styles.row, styles.oneColumn)}
        spanStyle={styles.inputTitle}
        inputStyle={`${styles.cardInput} ${styles.inputEnabled}`}
        name={CARD_FIELDS.CARD_NAME}
        error={touched[CARD_FIELDS.CARD_NAME] && errors[CARD_FIELDS.CARD_NAME]}
        inputContainer={{
          id: CARD_FIELDS.CARD_NAME
        }}
      />
      <Field
        title={i18next.t('KushkiCardForm:card')}
        as={Input}
        containerStyle={cn('full-width', styles.row, styles.oneColumn)}
        spanStyle={styles.inputTitle}
        inputStyle={`${styles.cardInput} ${styles.inputEnabled}`}
        name={CARD_FIELDS.CARD_NUMBER}
        error={touched[CARD_FIELDS.CARD_NUMBER] && errors[CARD_FIELDS.CARD_NUMBER]}
        inputContainer={{
          id: CARD_FIELDS.CARD_NUMBER
        }}
      />
      <Field name={CARD_FIELDS.CARD_EXPIRATION_DATE}>
        {({ field, meta }) => (
          <div className={cn('space-between middle', styles.row)}>
            <div className={`m-bottom-2 ${styles.inputTitle}`}>
              {i18next.t('KushkiCardForm:expirationDate')}
            </div>
            <div
              className={cn(styles.cardInput, styles.inputEnabled, styles.inputContainer, {
                [styles.inputError]: meta.touched && meta.error
              })}>
              <MaskedInput
                {...field}
                mask={[/\d/, /\d/, '/', /\d/, /\d/]}
                placeholderChar=" "
                className={styles.input}
                id={CARD_FIELDS.CARD_EXPIRATION_DATE}
              />
            </div>
            {meta.touched && meta.error && <span className={styles.errorMessage}>{meta.error}</span>}
          </div>
        )}
      </Field>
      <Field
        title={
          <div>
            {i18next.t('KushkiCardForm:cvc')}{' '}
            <span
              data-tip="Número de 3 o 4 dígitos ubicado al reverso de tu tarjeta."
              className={cn('material-icons', styles.icon)}>
              {VARIANT_ICONS.info}
            </span>
            <ReactTooltip multiline className={styles.tooltip} />
          </div>
        }
        as={Input}
        spanStyle={styles.inputTitle}
        containerStyle={cn('full-width', styles.row)}
        inputStyle={`${styles.cardInput} ${styles.inputEnabled}`}
        name={CARD_FIELDS.CARD_CVC}
        error={touched[CARD_FIELDS.CARD_CVC] && errors[CARD_FIELDS.CARD_CVC]}
        maxlength="4"
        inputContainer={{
          id: CARD_FIELDS.CARD_CVC
        }}
        type="password"
      />
      {enableOptionSubscribeCard ? (
        <>
          {!currentUser?.id ? (
            <span className={cn('full-width m-right-1 m-top-2', styles.oneColumn)}>
              <button type="button" className={styles.loginLink} onClick={openLogin}>
                {i18next.t('KushkiCardForm:signIn')}
              </button>
              {i18next.t('KushkiCardForm:signInMessage')}
            </span>
          ) : null}
        </>
      ) : null}
      {kushkiTokenError ? (
        <div className={cn('full-width', styles.oneColumn)}>
          <AlertMessage variant="error" text={kushkiTokenError.error} />
        </div>
      ) : null}
    </div>
  );
};

KushkiCardForm.propTypes = {
  enableOptionSubscribeCard: boolean
};
