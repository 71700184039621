import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingWrapper from '~components/Spinner/LoadingWrapper';
import CoreActions from '~redux/Core/actions';
import PagesActions from '~redux/Pages/actions';
import { actionCreators as authActions } from '~redux/Auth/actions';
import AnalyticsService from '~services/Analytics';
import { FIRST_PAGE } from '~constants/pages';
import { startIntercom } from '~services/IntercomService';
import { useScript } from '~hooks/script';
import IntercomScript from '~scripts/intercom';
import { getScripts } from '~scripts/gtm';
import { setPrimaryColors, setPrimaryFonts, setGTM } from '~utils/document';
import { loadFonts } from '~utils/fonts';
import { setTenant } from '~config/api';
import { TENANT } from '~constants/environment';

import styles from './styles.module.scss';

function CoreSettingsWrapper({ children }) {
  const dispatch = useDispatch();
  setTenant(TENANT);
  useEffect(() => {
    dispatch(CoreActions.getSettings());
    dispatch(CoreActions.getDefaultStoreId());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  dispatch(authActions.getLocalStorageData());
  const { settingsLoading, settings } = useSelector((state) => state.core);
  const { pagesLoading } = useSelector((state) => state.pages);
  useEffect(() => {
    if (settings) {
      const { brandPrimary, brandSecondary, gtmId, fonts } = settings;
      const { body, categoryBar, subtitle, title } = fonts;
      loadFonts(body, categoryBar, subtitle, title);
      setPrimaryColors({ primary: brandPrimary, secondary: brandSecondary });
      setPrimaryFonts({
        body,
        categoryBar,
        subtitle,
        title
      });
      setGTM(getScripts(gtmId));
      AnalyticsService.setUp({
        analyticsUA: settings.commerceAnalyticsUa,
        analyticsGA4: settings.commerceAnalyticsGa4,
        pixel: settings.facebookPixelId
      });
    }
  }, [settings]);

  useScript(IntercomScript);
  useEffect(() => {
    if (settings) {
      startIntercom(settings.intercomId);
    }
  }, [settings]);
  useEffect(() => {
    if (settings) {
      dispatch(PagesActions.getPages(FIRST_PAGE));
    }
  }, [dispatch, settings]);
  return (
    <LoadingWrapper className={styles.container} loading={pagesLoading || settingsLoading || !settings}>
      {children}
    </LoadingWrapper>
  );
}

export default CoreSettingsWrapper;
