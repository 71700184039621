import DOMPurify from 'dompurify';

export const redirectPost = (url, parameters) => {
  const form = document.createElement('form');

  if (parameters) {
    parameters.forEach(({ token, inputName }) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.value = token;
      input.name = inputName;
      form.appendChild(input);
    });
  }
  // create mock form
  document.body.appendChild(form);
  form.method = 'get';
  form.action = url;

  form.submit();
  document.body.removeChild(form);
};

export const parseCredomaticForm = (html) => {
  const formString = DOMPurify.sanitize(html, {
    ALLOWED_TAGS: ['form', 'input'],
    ALLOWED_ATTR: ['action', 'method', 'value', 'name'],
    KEEP_CONTENT: false
  });

  const div = document.createElement('div');

  div.innerHTML = formString;

  const formHtml = div.children[0];

  document.body.appendChild(div);
  formHtml.submit();
  document.body.removeChild(div);
};
