import { DISPATCH_METHODS } from '~constants/address';

export const DELIVERY_TYPES = {
  PEYA_DELIVERY: 'peya_delivery',
  UBER_DELVERY: 'uber_delivery',
  CABIFY_DELIVERY: 'cabify_delivery',
  OWN_DELIVERY: 'own_delivery'
};

const CAMEL_CASE_DELIVERY_TYPES = {
  [DELIVERY_TYPES.PEYA_DELIVERY]: 'peyaDelivery',
  [DELIVERY_TYPES.UBER_DELVERY]: 'uberDelivery',
  [DELIVERY_TYPES.CABIFY_DELIVERY]: 'cabifyDelivery',
  [DELIVERY_TYPES.OWN_DELIVERY]: 'ownDelivery'
};

const isDelivery = (dispatchType, deliveryTypeSelected, deliveryType) =>
  dispatchType === DISPATCH_METHODS.DELIVERY && deliveryTypeSelected === deliveryType;

export const isPeyaDelivery = (dispatchType, deliveryType) =>
  isDelivery(dispatchType, deliveryType, DELIVERY_TYPES.PEYA_DELIVERY);

export const isUberDelivery = (dispatchType, deliveryType) =>
  isDelivery(dispatchType, deliveryType, DELIVERY_TYPES.UBER_DELVERY);

export const isCabifyDelivery = (dispatchType, deliveryType) =>
  isDelivery(dispatchType, deliveryType, DELIVERY_TYPES.CABIFY_DELIVERY);

export const isOwnDelivery = (dispatchType, deliveryType) =>
  isDelivery(dispatchType, deliveryType, DELIVERY_TYPES.OWN_DELIVERY);

export const DELIVERY_FLEET_TIPS = {
  [CAMEL_CASE_DELIVERY_TYPES.OWN_DELIVERY]: true,
  [CAMEL_CASE_DELIVERY_TYPES.UBER_DELVERY]: true,
  [CAMEL_CASE_DELIVERY_TYPES.CABIFY_DELIVERY]: false,
  [CAMEL_CASE_DELIVERY_TYPES.PEYA_DELIVERY]: false
};
