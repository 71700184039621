import i18next from 'i18next';

const PAYMENT_TYPES_MAPPER = {
  chek: 'Chek',
  credomatic: 'Tarjeta de crédito',
  getnet: 'Tarjeta de crédito, débito o prepago',
  kushkiCard: 'Crédito o débito',
  mach: 'Mach',
  mercadoPago: 'Mercado Pago',
  oneclick: 'Webpay OneClick',
  sodexo: 'Pluxee - Sodexo',
  webpay: 'Webpay',
  fpay: 'Fpay'
};

i18next.addResources('es_CL', 'PaymentMethods', {
  addCard: 'Agregar tarjeta',
  addNewCard: 'Agregar nueva tarjeta',
  available: 'Disponibles',
  card: 'Tarjeta al recibir',
  cash: 'Efectivo',
  cashDisabled: '*Por motivos de seguridad hemos desactivado el pago en efectivo.',
  messageErrorSodexo: 'Problema al generar el pago con Pluxee - Sodexo',
  messageErrorWebPay: 'Problema al generar el pago con Webpay',
  messageErrorChek: 'Problema al generar el pago con Chek',
  messageErrorGetnet: 'Problema al generar el pago con Getnet',
  messageErrorMercadoPago: 'Problema al generar el pago con Mercado Pago',
  messageErrorMach: 'Problema al generar el pago con Mach',
  messageErrorKushki: 'Problema al generar el pago con Kushki',
  messageErrorCredomatic: 'Problema al generar el pago con Credomatic',
  messageErrorCreditCard: 'Problema al generar el pago con tarjeta de crédito',
  myCreditCards: 'Mis tarjetas',
  oneClick: PAYMENT_TYPES_MAPPER.oneclick,
  OneclickPayment: PAYMENT_TYPES_MAPPER.oneclick,
  online: PAYMENT_TYPES_MAPPER.webpay,
  WebpayPayment: PAYMENT_TYPES_MAPPER.webpay,
  payment: 'Pago: ',
  sodexo: PAYMENT_TYPES_MAPPER.sodexo,
  SodexoPayment: PAYMENT_TYPES_MAPPER.sodexo,
  title: 'Métodos de pago',
  ChekPayment: PAYMENT_TYPES_MAPPER.chek,
  chek: PAYMENT_TYPES_MAPPER.chek,
  MercadoPagoPayment: PAYMENT_TYPES_MAPPER.mercadoPago,
  mercadoPago: PAYMENT_TYPES_MAPPER.mercadoPago,
  MachPayment: PAYMENT_TYPES_MAPPER.mach,
  mach: PAYMENT_TYPES_MAPPER.mach,
  FpayPayment: PAYMENT_TYPES_MAPPER.fpay,
  fpay: PAYMENT_TYPES_MAPPER.fpay,
  KushkiCardPayment: PAYMENT_TYPES_MAPPER.kushkiCard,
  kushkiCard: PAYMENT_TYPES_MAPPER.kushkiCard,
  KushkiCardSubscriptionPayment: PAYMENT_TYPES_MAPPER.kushkiCard,
  Credomatic: PAYMENT_TYPES_MAPPER.credomatic,
  cardNumber: 'Número en la tarjeta',
  expiryDate: 'Vencimiento',
  cardNumberPlaceholder: '1234 1234 1234 1234',
  expiryDatePlaceholder: 'mm/yy',
  invalidCard: 'El número de tarjeta de credito utilizado es inválido',
  invalidMonth: 'El mes de vencimiento es incorrecto',
  expireDate: 'La fecha de vencimiento es incorrecta',
  addNewCardDescription:
    'Aceptamos tarjetas de crédito o débito Visa o Mastercard. Por el momento no aceptamos American Express en Pago Online.',
  save: 'Guardar',
  saving: 'Guardando',
  confirm: 'Confirmar',
  confirmYourOrder: 'Confirma tu pedido',
  insertCVCDescription: 'Ingresa el código de seguridad de tu tarjeta (CVC) para confirmar tu compra.',
  cardCVCLabel: 'CVC',
  cardCVCPlaceholder: '123',
  cardSingleWord: 'Tarjeta',
  getnet: PAYMENT_TYPES_MAPPER.getnet,
  GetnetPayment: PAYMENT_TYPES_MAPPER.getnet
});
