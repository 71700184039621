export const startIntercom = (intercomId) => {
  if (intercomId && window.Intercom) {
    window.Intercom('boot', {
      app_id: intercomId,
      custom_launcher_selector: '.intercomTriggerShow'
    });
  }
};

export const shutdownIntercom = (intercomId) => {
  if (intercomId) {
    window.Intercom('shutdown');
  }
};

export const onUnreadCountChange = (handler) => {
  if (intercomId) {
    window.Intercom('onUnreadCountChange', handler);
  }
};
