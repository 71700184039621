import i18next from 'i18next';

i18next.addResources('es_CL', 'Footer', {
  knowFromUs: 'Conócenos',
  dispatchZone: 'Zona de despacho',
  contact: 'Contacto',
  faq: 'Preguntas frecuentes',
  myAccount: 'Mi cuenta',
  myOrders: 'Mis pedidos',
  myAddresses: 'Mis direcciones',
  changePassword: 'Cambiar contraseña',
  poweredBy: 'Powered by',
  termsAndConditions: 'Términos y condiciones Mercat'
});

i18next.addResources('es_CL', 'App', {
  merkatLogo: 'Merkat Logo'
});

i18next.addResources('es_CL', 'Home', {
  notAvailable: '/no-disponible'
});
