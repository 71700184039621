import { createTypes, completeTypes } from 'redux-recompose';

import CDNService from '~services/CDN/service';

export const actions = createTypes(completeTypes(['GET_TERMS_AND_CONDITIONS'], []), '@@CDN');

const targets = {
  TERMS_AND_CONDITIONS: 'termsAndConditions'
};

const actionCreators = {
  getTermsAndConditions: () => ({
    type: actions.GET_TERMS_AND_CONDITIONS,
    service: CDNService.getTermsAndConditions,
    target: targets.TERMS_AND_CONDITIONS
  })
};

export default actionCreators;
