import { PAYMENT_METHODS, SUBSCRIPTION_METHODS } from '~constants/paymentMethods';
import { camelize } from '~utils/string';

export const getPaymentMethodsByDispatch = (paymentMethods, dispatchType) =>
  paymentMethods?.[camelize(dispatchType)] || {};

export const availableMethods = (paymentMethods, dispatchType) => {
  const paymentMethodsByDispatch = getPaymentMethodsByDispatch(paymentMethods, dispatchType);
  return Object.keys(paymentMethodsByDispatch).reduce(
    (newPaymentMethods, paymentMethodname) =>
      PAYMENT_METHODS[paymentMethodname] && paymentMethodsByDispatch[paymentMethodname]
        ? [...newPaymentMethods, PAYMENT_METHODS[paymentMethodname]]
        : newPaymentMethods,
    []
  );
};

export const isPaymentMethodEnabled = (paymentMethodName, paymentMethods) =>
  paymentMethods.some((method) => method.name === paymentMethodName);

export const paymentPriorityMapper = ({ oneclick, kushkiCardSubscription, credomatic }) => {
  if (kushkiCardSubscription) return SUBSCRIPTION_METHODS.kushkiCardSubscription;
  if (oneclick) return SUBSCRIPTION_METHODS.oneclick;
  if (credomatic) return SUBSCRIPTION_METHODS.credomatic;
  return false;
};
