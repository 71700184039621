import i18next from 'i18next';

// TODO define this titles
i18next.addResources('es_CL', 'Commerce', {
  checkout: 'Checkout',
  checkoutDescription: 'Checkout',
  purchaseSuccess: 'Compra exitosa',
  purchaseSuccessDescription: 'Compra exitosa',
  purchaseFailure: 'Compra fallida',
  purchaseFailureDescription: 'Compra fallida',
  processingPurchase: 'Procesando la compra',
  processingPurchaseDescription: 'Procesando la compra',
  profile: 'Información de tu cuenta',
  profileDescription: 'Mi perfil - Datos personales, Métodos de pago, Newsletters y Promociones',
  termsAndConditions: 'Términos y condiciones',
  mercatTermsAndConditions: 'Términos y condiciones Mercat',
  termsAndConditionsDescription: 'Términos y condiciones',
  processingAddCard: 'Procesando tarjeta',
  orders: 'Mis órdenes',
  downloadOurApp: 'Descarga nuestra aplicación',
  download: 'Descargar'
});

i18next.addResources('es_CL', 'Months', {
  january: 'Enero',
  february: 'Febrero',
  march: 'Marzo',
  april: 'Abril',
  may: 'Mayo',
  june: 'Junio',
  july: 'Julio',
  august: 'Agosto',
  september: 'Septiembre',
  october: 'Octubre',
  november: 'Noviembre',
  december: 'Diciembre'
});
