import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import Lottie from 'react-lottie-segments';
import { bool, number, shape, string, arrayOf } from 'prop-types';

import getOptions from '~utils/lottie';
import LocalStorageService from '~services/LocalStorageService';

import styles from './styles.module.scss';
import { VARIANTS, DEFAULT_HEIGHT, DEFAULT_WIDTH } from './constants';

function LottieIcon({
  isLoading,
  width,
  height,
  icon,
  loop,
  autoplay,
  rendererSettings,
  className,
  primaryColor,
  sequence
}) {
  const brandPrimaryFromStorage = LocalStorageService.getPrimaryColor();
  const animationLoaderFromStorage = LocalStorageService.getAnimationLoader();
  const brandPrimary = useSelector((state) => state.core.settings?.brandPrimary);

  const options = useMemo(
    () =>
      getOptions({
        VARIANTS,
        ...((icon || animationLoaderFromStorage) && {
          icon: icon || animationLoaderFromStorage
        }),
        loop,
        autoplay,
        rendererSettings,
        ...((primaryColor || brandPrimary || brandPrimaryFromStorage) && {
          primaryColor: primaryColor || brandPrimary || brandPrimaryFromStorage
        })
      }),
    [
      animationLoaderFromStorage,
      autoplay,
      brandPrimary,
      brandPrimaryFromStorage,
      icon,
      loop,
      primaryColor,
      rendererSettings
    ]
  );

  return (
    <div
      className={cn(className, styles.spinnerContainer, {
        'row center middle': isLoading
      })}>
      <Lottie width={width} height={height} options={options} playSegments={sequence} />
    </div>
  );
}

export const LottieIconPropTypes = {
  autoplay: bool,
  className: string,
  height: number,
  icon: string,
  isLoading: bool,
  loop: bool,
  primaryColor: string,
  rendererSettings: shape({ preserveAspectRatio: string }),
  sequence: shape({ segments: arrayOf(number) }),
  width: number
};

LottieIcon.propTypes = LottieIconPropTypes;

LottieIcon.defaultProps = {
  height: DEFAULT_HEIGHT,
  width: DEFAULT_WIDTH
};

export default LottieIcon;
