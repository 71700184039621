import immutable from 'seamless-immutable';
import { createReducer, completeReducer, completeState } from 'redux-recompose';

import { actions } from './actions';

const defaultState = completeState({
  termsAndConditions: ''
});

const reducerDescription = {
  primaryActions: [actions.GET_TERMS_AND_CONDITIONS]
};

const reducer = createReducer(immutable(defaultState), completeReducer(reducerDescription));
export default reducer;
