export const targets = {
  CART: 'cart',
  REMOTE_CART: 'remoteCart',
  ITEM: 'item',
  FINAL_PRICE: 'total',
  SUBTOTAL_PRICE: 'subtotal',
  DELIVERY: 'delivery',
  DELIVERY_METHOD: 'deliveryMethod',
  DISCOUNT: 'discount',
  ERROR: 'error',
  UBER_ESTIMATE_ID: 'uberEstimateId',
  UBER_ESTIMATE_EXPIRES_AT: 'uberEstimateExpiresAt',
  DELIVERY_DETAILS: 'deliveryDetails',
  COUPON: 'coupon',
  LOYALTY_POINTS: 'loyaltyPoints',
  MAX_LOYALTY_MONEY_TO_USE: 'maxLoyaltyMoneyToUse',
  LOYALTY_PROGRAM: 'loyaltyProgram'
};

export const FAKE_DATA_FOR_USER = {
  email: '',
  name: 'asd'
};
