import React from 'react';
import { string } from 'prop-types';

import GiftIcon from '~components/GiftIcon';

import styles from './styles.module.scss';

function RewardDisclaimer({ title, message }) {
  return (
    <div className={styles.container}>
      <div>
        <GiftIcon primaryColor={styles.giftIconPrimary} secondaryColor={styles.giftIconSecondary} />
      </div>
      <div className="m-left-3 m-top-3">
        <div className={styles.description}>
          <p>
            <strong>{title}</strong>
            {message}
          </p>
        </div>
      </div>
    </div>
  );
}

RewardDisclaimer.propTypes = {
  message: string,
  title: string
};

export default RewardDisclaimer;
