import i18next from 'i18next';

import { CURRENCY_SYMBOL } from '~constants/environment';

i18next.addResources('es_CL', 'Field', {
  email: 'Email',
  name: 'Nombre y Apellido',
  surname: 'Apellido',
  phone: 'Teléfono',
  paymentMethod: 'Medio de pago',
  deliveryOption: 'Opciones de entrega',
  invalid: 'El campo {{ field }} es inválido',
  required: 'El campo {{ field }} es requerido',
  matches: '{{field}} no cumple con el formato',
  codeCountry: 'El {{field}} debe incluir el código del país {{code}}',
  length: '{{field}} debe tener {{length}} caracteres',
  min: '{{field}} debe tener al menos {{min}} caracteres',
  max: '{{field}} debe tener máximo {{max}} caracteres',
  spaceAtBeginOrEnd: 'Espacios al inicio o al final no son admitidos',
  termsAndConditions: 'Los términos y condiciones deben ser aceptados',
  onlyDigits: 'Sólo admite números'
});

i18next.addResources('es_CL', 'RequestErrors', {
  STORE_CLOSED: 'El local se encuentra cerrado en este momento',
  NOT_AUTHORIZED: 'Ha ocurrido un error con su sesión. Por favor, intenta iniciar sesión nuevamente',
  DELIVERY_MINIMUM_NOT_REACHED: 'El valor del pedido es menor al mínimo requerido',
  ORDER_CREATION_BLOCKED: 'No se pudo generar la orden porque hay una en curso',
  NETWORK_ERROR: 'Ha ocurrido un error, por favor intente nuevamente más tarde',
  Unauthorized: 'Ha ocurrido un error con su sesión. Por favor, intenta iniciar sesión nuevamente',
  timeout: 'El pedido ha superado el tiempo máximo. Por favor intente nuevamente en unos minutos.'
});

i18next.addResources('es_CL', 'APIErrors', {
  e1001: 'Ha ocurrido un error con los campos ingresados, por favor revise los datos',
  e1007: 'Ha ocurrido un error con el carrito.',
  e1010: '¡Ups! Tuvimos un problema para procesar tu pedido. Comunícate con la tienda para tomar tu orden',
  e1011: 'Algunos elementos presentes en la orden no están disponibles: {{items}}',
  e1024: 'La tienda está cerrada',
  e1026: `El monto mínimo permitido para este método de despacho es de ${CURRENCY_SYMBOL}{{value}}`,
  e1038: 'No se puede crear la orden porque ya hay una en ejecución',
  e1044: `El monto máximo permitido para este método de envío es de ${CURRENCY_SYMBOL}{{value}}`,
  e1072: 'Al quitar el producto perderás el beneficio de crear tu combo, aumentando tu monto total.',
  e1108: 'El cupón ingresado no es compatible con el medio de pago seleccionado.',
  e9999: 'Por favor intenta más tarde'
});

i18next.addResources('es_CL', 'APIErrorFields', {
  phoneTaken: 'Este teléfono ya está registrado, por favor usa uno distinto.'
});

i18next.addResources('es_CL', 'Products', {
  availableAfter: 'Disponible',
  afterBuy: 'después de tu compra',
  day: 'día/s',
  hour: 'hora/s',
  deferredDelivery: 'Tu pedido tomará',
  beReady: 'en estar listo.'
});
