import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { DISPATCH_METHOD_TARGET } from '~redux/SearchStore/constants';
import { PAYMENT_METHODS_NAMES, SUBSCRIPTION_METHODS } from '~constants/paymentMethods';
import { DISPATCH_METHODS } from '~constants/address';
import { isOwnDelivery } from '~constants/delivery';
import OrderActions from '~redux/Order/actions';
import { camelize } from '~utils/string';

import { availableMethods, isPaymentMethodEnabled, paymentPriorityMapper } from '../utils';
import { PAYMENT_METHOD, ID_CARD, CARD_TOKEN } from '../constants';

const filterPaymentMethodsByDispatchMethod = (dispatchType, deliveryMethod) => (paymentMethod) =>
  dispatchType === DISPATCH_METHODS.DELIVERY && !isOwnDelivery(dispatchType, deliveryMethod)
    ? paymentMethod.isOnline
    : true;

const usePaymentMethod = (paymentMethods, setFieldValue) => {
  const dispatch = useDispatch();
  const { deliveryMethod } = useSelector((state) => state.cart);
  const dispatchType = useSelector((state) => state.searchStore[DISPATCH_METHOD_TARGET]);
  const userCreditCards = useSelector((state) => state.auth.creditCards);
  const methods = useMemo(() => availableMethods(paymentMethods, dispatchType), [
    paymentMethods,
    dispatchType
  ]);

  const methodsWithoutSubscriptionCards = useMemo(
    () =>
      methods
        .filter((method) => !Object.keys(SUBSCRIPTION_METHODS).includes(method.name))
        .filter(filterPaymentMethodsByDispatchMethod(dispatchType, deliveryMethod)),
    [deliveryMethod, dispatchType, methods]
  );
  const isOneClickEnabled = isPaymentMethodEnabled(PAYMENT_METHODS_NAMES.oneClick, methods);
  const isKushkiEnabled = isPaymentMethodEnabled(PAYMENT_METHODS_NAMES.kushkiCardSubscription, methods);
  const isCredomaticEnabled = isPaymentMethodEnabled(PAYMENT_METHODS_NAMES.credomatic, methods);
  const isCashEnabled = isPaymentMethodEnabled(PAYMENT_METHODS_NAMES.cash, methods);

  useEffect(() => {
    if (methodsWithoutSubscriptionCards.length === 1) {
      setFieldValue(PAYMENT_METHOD, methodsWithoutSubscriptionCards[0].name);
    }
  }, [setFieldValue, methodsWithoutSubscriptionCards]);

  const handleSelect = (paymentMethod) => () => {
    setFieldValue(PAYMENT_METHOD, paymentMethod);
    setFieldValue(ID_CARD, null);
    setFieldValue(CARD_TOKEN, null);
    dispatch(OrderActions.setTip(0));
  };
  const handleSelectCreditCard = (paymentMethod, idCard, cardToken) => () => {
    setFieldValue(PAYMENT_METHOD, camelize(paymentMethod));
    setFieldValue(ID_CARD, idCard);
    setFieldValue(CARD_TOKEN, cardToken);
  };

  const subscriptionMethodEnabled = paymentPriorityMapper({
    oneclick: isOneClickEnabled,
    kushkiCardSubscription: isKushkiEnabled,
    credomatic: isCredomaticEnabled
  });

  const isSubscriptionMethodsEnabled = isOneClickEnabled || isKushkiEnabled || isCredomaticEnabled;

  useEffect(() => {
    const [priorityPaymentMethod] = methodsWithoutSubscriptionCards.sort(
      (prev, curr) => prev.priority - curr.priority
    );
    const userCreditCardsType = userCreditCards?.filter((card) => card?.type === subscriptionMethodEnabled);
    if (isSubscriptionMethodsEnabled && userCreditCardsType?.length > 0) {
      setFieldValue(PAYMENT_METHOD, camelize(subscriptionMethodEnabled));
      setFieldValue(ID_CARD, userCreditCardsType[userCreditCardsType?.length - 1].id);
      setFieldValue(CARD_TOKEN, userCreditCardsType[userCreditCardsType?.length - 1].token);
    } else if (priorityPaymentMethod) {
      setFieldValue(PAYMENT_METHOD, priorityPaymentMethod.name);
    }
  }, [
    setFieldValue,
    methodsWithoutSubscriptionCards,
    isSubscriptionMethodsEnabled,
    userCreditCards,
    subscriptionMethodEnabled
  ]);

  return {
    methodsWithoutSubscriptionCards,
    isSubscriptionMethodsEnabled,
    isCashEnabled,
    handleSelect,
    handleSelectCreditCard,
    subscriptionMethodEnabled
  };
};

export default usePaymentMethod;
