import { create } from 'apisauce';
import { CamelcaseSerializer, SnakecaseSerializer } from 'cerealizr';

import { TENANT } from '~constants/environment';

import { errorNormalizer } from './apiTransforms';

const camelSerializer = new CamelcaseSerializer();
const snakeSerializer = new SnakecaseSerializer();

export const HEADERS = {
  PLATFORM: 'X-PLATFORM',
  TENANT: 'X-TENANT',
  CREATE_USER_TOKEN: 'X-CREATE-USER-TOKEN',
  AUTHORIZATION: 'Authorization',
  JOB_AUTHORIZATION: 'X-JOB-AUTHORIZATION',
  USER_ID: 'X-USERID',
  TIMEZONE: 'X-TIMEZONE'
};

export const api = create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    [HEADERS.PLATFORM]: 'web'
  }
});

export const apiAry = create({
  baseURL: process.env.REACT_APP_CORE_HOST
});

export const cdnApi = create({
  baseURL: `${process.env.REACT_APP_PUBLIC_CDN}${TENANT}`
});

export const globalCdnApi = create({
  baseURL: `${process.env.REACT_APP_PUBLIC_CDN}`
});

export const setAuthHeader = (token) => api.setHeader(HEADERS.AUTHORIZATION, token);

export const setTenant = (tenant) => {
  api.setHeaders({
    [HEADERS.TENANT]: tenant
  });
};

export const apiSetup = (baseApi) => {
  baseApi.addResponseTransform(errorNormalizer);

  baseApi.addResponseTransform((response) => {
    if (response.data) {
      response.data = camelSerializer.serialize(response.data);
    }
  });

  baseApi.addRequestTransform((request) => {
    if (request.params) {
      request.params = snakeSerializer.serialize(request.params);
    }
    if (request.data) {
      request.data = snakeSerializer.serialize(request.data);
    }
  });
};

apiSetup(api);
apiSetup(apiAry);
apiSetup(cdnApi);
apiSetup(globalCdnApi);

export default api;
