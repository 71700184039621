import { completeTypes, createTypes, withPostSuccess, withPostFailure, withPreFetch } from 'redux-recompose';
import { push, replace } from 'connected-react-router';
import i18next from 'i18next';

import LocalStorageService from '~services/LocalStorageService';
import ModalActions from '~redux/Modal/actions';
import AuthService from '~services/Auth/service';
import { authErrorFieldsCatcher, MAX_LAST_ADDRESSES, TARGETS } from '~redux/Auth/constants';
import { USER_ADRESSES } from '~redux/SearchStore/constants';
import api, { HEADERS, setAuthHeader } from '~config/api';
import { MODALS } from '~redux/Modal/constants';
import { CLIENT_PAGES } from '~constants/pages';
import { actionCreators as orderActions } from '~redux/Order/actions';

import { registerWithEmailMapper, signInWithEmailMapper } from './mappers';

export const actions = createTypes(
  completeTypes(
    [
      'SET_ADDRESS',
      'CREATE_GUEST_USER',
      'GET_GUEST_USER',
      'GET_CURRENT_USER',
      'SEND_SIGN_IN_CODE_TO_EMAIL',
      'SIGN_IN_WITH_EMAIL_CODE',
      'REGISTER_USER',
      'GET_CREDIT_CARDS'
    ],
    ['SET_VALUE']
  ),
  '@@AUTH'
);

export const privateActionCreators = {
  setAddressFailure: (payload) => ({
    type: actions.SET_ADDRESS_FAILURE,
    target: TARGETS.ADDRESS,
    payload
  }),
  setAddressSuccess: (payload) => ({
    type: actions.SET_ADDRESS_SUCCESS,
    target: TARGETS.ADDRESS,
    payload
  }),
  createGuestUserFailure: (payload) => ({
    type: actions.CREATE_GUEST_USER_FAILURE,
    target: TARGETS.GUEST_USER,
    payload
  }),
  createGuestUserSuccess: (payload) => ({
    type: actions.CREATE_GUEST_USER_SUCCESS,
    target: TARGETS.GUEST_USER,
    payload
  }),
  getGuestUserFailure: (payload) => ({
    type: actions.GET_GUEST_USER_FAILURE,
    target: TARGETS.GUEST_USER,
    payload
  })
};

export const actionCreators = {
  getLocalStorageData: () => (dispatch, getState) => {
    const oobCode = new URLSearchParams(window.location.search).get('oobCode');
    const email = LocalStorageService.getLoginEmail();
    const addresses = LocalStorageService.getAddresses();
    const accessToken = LocalStorageService.getAccessToken();
    const guestUser = LocalStorageService.getGuestUser();
    const futureOrder = LocalStorageService.getFutureOrderDate();
    const { currentUser, currentUserLoading } = getState().auth;

    if (oobCode && email) {
      // Open email login flow
      dispatch(actionCreators.signInWithCode(email));
    } else if (accessToken) {
      // Normal flow
      setAuthHeader(accessToken);
      if (!currentUser && !currentUserLoading) {
        dispatch(actionCreators.getCurrentUser());
      }
    } else if (guestUser?.token) {
      setAuthHeader(guestUser.token);
    } else {
      dispatch(privateActionCreators.getGuestUserFailure());
      dispatch({
        type: actions.GET_CURRENT_USER_FAILURE,
        target: TARGETS.CURRENT_USER
      });
    }

    if (addresses) {
      dispatch(privateActionCreators.setAddressSuccess(addresses));
    } else {
      dispatch(privateActionCreators.setAddressFailure());
    }
    dispatch(orderActions.setFutureOrderDate(futureOrder));
  },
  setAddress: (address) => (dispatch, getState) => {
    const localStorageAddresses = LocalStorageService.getAddresses();
    const addresses = { current: address, history: [] };
    const userAddresses = getState().searchStore[USER_ADRESSES];
    const isAddressSaved = userAddresses?.filter((item) => item.textAddress === address.textAddress).length;

    if (!isAddressSaved) {
      addresses.history.push(address);
    }

    if (localStorageAddresses?.history) {
      localStorageAddresses.history.forEach((item) => {
        if (item.textAddress !== address.textAddress && addresses.history.length < MAX_LAST_ADDRESSES) {
          addresses.history.push(item);
        }
      });
    }

    if (!address) {
      dispatch(privateActionCreators.setAddressFailure());
      LocalStorageService.removeAddresses();

      return;
    }

    LocalStorageService.setAddresses(addresses);
    dispatch(privateActionCreators.setAddressSuccess(addresses));
  },
  clearTarget: (target, payload) => (dispatch) =>
    dispatch({
      type: actions.SET_VALUE,
      target,
      payload
    }),
  createGuestUser: (userData, onSuccess, onFailure) => ({
    type: actions.CREATE_GUEST_USER,
    target: TARGETS.GUEST_USER,
    service: AuthService.createGuestUser,
    payload: userData,
    injections: [
      withPostSuccess((dispatch, response) => {
        const token = response.data.externalId;

        if (!token) {
          dispatch(
            privateActionCreators.createGuestUserFailure({
              error: i18next.t('Orders:noUserToken')
            })
          );
          dispatch(onFailure({ error: i18next.t('Orders:noUserToken') }));

          return;
        }
        setAuthHeader(token);
        LocalStorageService.setGuestUser({ token });
        dispatch(privateActionCreators.createGuestUserSuccess(response.data));
        dispatch(onSuccess(response.data));
      }),
      withPostFailure((dispatch, response) => {
        dispatch(onFailure(i18next.t(`APIErrors:e${response.data?.code}`)));
      })
    ]
  }),
  setValue: (target, payload) => ({
    type: actions.SET_VALUE,
    target,
    payload
  }),
  sendSignInCodeToEmail: (email) => ({
    type: actions.SEND_SIGN_IN_CODE_TO_EMAIL,
    target: TARGETS.SEND_SIGN_IN_CODE_TO_EMAIL,
    payload: signInWithEmailMapper({ email }),
    service: AuthService.signIn,
    injections: [
      withPostSuccess(() => {
        LocalStorageService.setLoginEmail(email);
      })
    ]
  }),
  signInWithCode: (email, verificationCode) => ({
    type: actions.SIGN_IN_WITH_EMAIL_CODE,
    target: TARGETS.SIGN_IN_WITH_EMAIL_CODE,
    payload: signInWithEmailMapper({ email, verificationCode }),
    service: AuthService.verifyAccount,
    injections: [
      withPreFetch(() => {
        LocalStorageService.removeAccessToken();
      }),
      withPostSuccess((dispatch, response) => {
        const token = response.data;
        setAuthHeader(token);
        LocalStorageService.removeGuestUser();
        LocalStorageService.removeProfile();
        const successBehavior = () => {
          LocalStorageService.setAccessToken(token);
        };
        const failureBehavior = () => dispatch(ModalActions.openModal(MODALS.LOGIN));
        dispatch(actionCreators.getCurrentUser(successBehavior, failureBehavior, token));
        LocalStorageService.removeLoginEmail();
      })
    ]
  }),
  getCurrentUser: (successBehavior, failureBehavior, token) => ({
    type: actions.GET_CURRENT_USER,
    target: TARGETS.CURRENT_USER,
    service: AuthService.getCurrentUser,
    payload: token ? { [HEADERS.AUTHORIZATION]: token } : {},
    injections: [
      withPostSuccess((dispatch, response) => {
        const { email, name, phone } = response.data;
        if (successBehavior) {
          successBehavior();
        }
        if (!!email && !!name && !!phone) {
          dispatch(ModalActions.closeModal(MODALS.LOGIN));
        }
      }),
      withPostFailure(() => {
        if (failureBehavior) {
          failureBehavior();
        }
      })
    ]
  }),
  registerFromEmailCode: (values, onFailure) => ({
    type: actions.REGISTER_USER,
    target: TARGETS.CURRENT_USER,
    service: AuthService.registerUser,
    payload: registerWithEmailMapper(values),
    injections: [
      withPreFetch(() => api.setHeader(HEADERS.CREATE_USER_TOKEN, process.env.REACT_APP_CREATE_USER_TOKEN)),
      withPostFailure((dispatch, response) => {
        delete api.headers[HEADERS.CREATE_USER_TOKEN];
        dispatch(ModalActions.openModal(MODALS.LOGIN));
        onFailure(authErrorFieldsCatcher(response.data?.fields));
      }),
      withPostSuccess((dispatch) => {
        dispatch(ModalActions.closeModal(MODALS.LOGIN));
        delete api.headers[HEADERS.CREATE_USER_TOKEN];
      })
    ]
  }),
  signInEmaillCodeDismiss: () => (dispatch) => {
    dispatch(replace(window.location.pathname));
    LocalStorageService.removeLoginEmail();
    dispatch(actionCreators.setValue(TARGETS.SIGN_IN_WITH_EMAIL_CODE, null));
    dispatch(actionCreators.setValue(TARGETS.SEND_SIGN_IN_CODE_TO_EMAIL, null));
    dispatch(actionCreators.setValue(`${TARGETS.SIGN_IN_WITH_EMAIL_CODE}Error`, null));
  },
  closeSession: () => (dispatch) => {
    dispatch(actionCreators.setValue(TARGETS.CURRENT_USER, null));
    dispatch(actionCreators.setValue(TARGETS.CREDIT_CARDS, null));
    LocalStorageService.removeAccessToken();
    LocalStorageService.removeGuestUser();
    LocalStorageService.removeProfile();
    LocalStorageService.removeLoginEmail();
    dispatch(actionCreators.signInEmaillCodeDismiss());
    delete api.headers[HEADERS.AUTHORIZATION];
    dispatch(ModalActions.openModal(MODALS.SESSION_CLOSED));
    dispatch(push(CLIENT_PAGES.HOME.basePath));
  },
  getCreditCards: (externalId) => ({
    payload: externalId,
    type: actions.GET_CREDIT_CARDS,
    target: TARGETS.CREDIT_CARDS,
    service: AuthService.getCreditCards
  })
};

export default actionCreators;
