/* eslint-disable no-magic-numbers */
import { createTypes, completeTypes, withPostSuccess, withPostFailure, withFailure } from 'redux-recompose';
import moment from 'moment';

import MenuService from '~services/Menu/service';
import { checkCartDenormalize } from '~utils/product';
import CartActions from '~redux/Cart/actions';
import { history } from '~redux/store';
import { CLIENT_PAGES } from '~constants/pages';
import { sortBy } from '~utils/array';
import { SORT_KEYS } from '~constants/sorts';

import { storeMapper, defaultMenuData, menuMapper } from './mappers';

export const actions = createTypes(
  completeTypes(
    ['GET_MENU', 'GET_UPSELLINGS', 'GET_BANNERS', 'GET_PUBLIC_MENU', 'GET_STATIC_MENU'],
    ['SET_FIXED_BAR']
  ),
  '@@MENU'
);

const targets = {
  MENU: 'menu',
  UPSELLINGS: 'upsellings',
  BANNERS: 'banners',
  FIXED_BAR: 'fixedBar',
  PUBLIC_MENU: 'publicMenu',
  DISABLED_PRODUCTS: 'disabledProducts'
};

const actionCreators = {
  getMenu: () => ({
    type: actions.GET_MENU,
    target: targets.MENU,
    payload: storeMapper,
    service: MenuService.getMenu,
    successSelector: ({ data = [] }) => data.map(defaultMenuData),
    injections: withPostSuccess((dispatch) => dispatch(CartActions.checkItemsAvailability()))
  }),
  getUpsellings: () => ({
    type: actions.GET_UPSELLINGS,
    target: targets.UPSELLINGS,
    payload: checkCartDenormalize(),
    service: MenuService.getUpsellings,
    successSelector: ({ data = [] }) => data.map((product) => product)
  }),
  getBanners: () => ({
    type: actions.GET_BANNERS,
    target: targets.BANNERS,
    payload: storeMapper,
    service: MenuService.getBanners,
    successSelector: ({ data = [] }) => data
  }),
  setFixedBar: (fixedBar) => ({
    type: actions.SET_FIXED_BAR,
    target: targets.FIXED_BAR,
    payload: fixedBar
  }),
  getPublicMenu: (id) => ({
    type: actions.GET_PUBLIC_MENU,
    target: targets.PUBLIC_MENU,
    payload: id,
    service: MenuService.getPublicMenu,
    successSelector: ({ data }) => ({
      ...data,
      priceGroups: sortBy(data.priceGroups, SORT_KEYS.POSITION)
    }),
    injections: [
      withPostFailure(() => {
        history.push(CLIENT_PAGES.HOME.basePath);
      })
    ]
  }),
  getStaticMenu: (store, banners, disabledProducts) => (dispatch, getState) => {
    const state = getState();
    const { maxDaysAvailableFutureOrder = 0 } = state.core.settings;
    const { futureOrdersEnabled, menuId } = store;

    return dispatch({
      type: actions.GET_STATIC_MENU,
      target: targets.MENU,
      payload: menuId,
      service: MenuService.getStaticMenu,
      successSelector: ({ data }) => {
        const dayLimit = futureOrdersEnabled
          ? moment().add(maxDaysAvailableFutureOrder, 'd').endOf('day')
          : moment();

        return menuMapper(data, banners, disabledProducts, dayLimit);
      },
      injections: [
        withPostSuccess((dispatch) => dispatch(CartActions.checkItemsAvailability())),
        withFailure((dispatch) => {
          dispatch(actionCreators.getMenu());
        })
      ]
    });
  }
};

export default actionCreators;
