import React, { useState, useMemo, useEffect } from 'react';
import i18next from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { func, string } from 'prop-types';

import Input from '~components/Input';
import Field from '~components/Field';
import CartActions from '~redux/Cart/actions';
import Text from '~components/Text';
import icoDiscount from '~assets/ico-discount.svg';

import { FIELDS } from '../../../../constants';
import { COUPON_FIELD, DISCOUNT_ALT_DESCRIPTION } from '../../../OrderSummary/constants';

import styles from './styles.module.scss';
import { getCouponErrorMessage } from './utils';

function CouponDiscount({ setFieldValue, paymentMethod = null }) {
  const dispatch = useDispatch();
  const { discount, cartLoading, cartError } = useSelector((state) => state.cart);
  const [canApply, setCanApply] = useState(false);
  const [isCouponInputEmpty, setIsCouponInputEmpty] = useState(true);
  const [coupon, setCoupon] = useState('');
  const [hasError, setHasError] = useState(false);

  const loyaltyPoints = useSelector((state) => state.cart?.loyaltyPoints);
  const pointsQuantity = loyaltyPoints || null;

  const handleOnChange = (e) => {
    setHasError(false);
    setCoupon(e.target.value.trim());
    if (e.target.value.trim() === '') {
      setIsCouponInputEmpty(true);
      setCanApply(false);
    } else {
      setIsCouponInputEmpty(false);
      setCanApply(true);
    }
  };

  const handleApply = () => {
    if (cartLoading) return;
    new Promise((...params) => {
      dispatch(
        CartActions.addDiscount(
          {
            couponData: { coupon, paymentMethod: paymentMethod },
            loyaltyData: { cashToExchange: pointsQuantity }
          },
          params
        )
      );
    })
      .then(() => {
        setCanApply(false);
        setFieldValue(FIELDS.COUPON, coupon);
      })
      .catch(() => setHasError(true));
  };

  const handleRemove = () => {
    if (cartLoading) return;
    new Promise((...params) => {
      dispatch(
        CartActions.addDiscount(
          {
            couponData: { coupon: '', paymentMethods: paymentMethod },
            loyaltyData: { cashToExchange: pointsQuantity }
          },
          params
        )
      );
    }).then(() => {
      setFieldValue(FIELDS.COUPON, '');
      setCanApply(true);
    });
  };

  const couponIsOk = useMemo(() => discount > 0 && !canApply && !isCouponInputEmpty && !!paymentMethod, [
    discount,
    canApply,
    isCouponInputEmpty,
    paymentMethod
  ]);

  useEffect(() => {
    if (cartError) {
      setFieldValue(FIELDS.COUPON, '');
    }
  }, [cartError, couponIsOk, setFieldValue]);

  useEffect(() => {
    setFieldValue(FIELDS.LOYALTY_POINTS, pointsQuantity);
  }, [pointsQuantity, setFieldValue]);

  return (
    <div className={styles.container}>
      <div className="row text-emperor bold font-size-14 m-bottom-2">
        <div className="col">
          <img src={icoDiscount} alt={DISCOUNT_ALT_DESCRIPTION} className="m-right-2" />
        </div>
        <div>{i18next.t('OrderSummary:discountCoupon')}</div>
      </div>
      <div
        className={cn('row middle space-between', styles.containerStyle, {
          [styles.error]: hasError,
          [styles.disabled]: cartLoading || couponIsOk
        })}>
        {paymentMethod ? (
          <Field
            component={Input}
            value={coupon}
            name={COUPON_FIELD}
            placeholder={i18next.t('OrderSummary:writeCoupon')}
            spanStyle={styles.inputTitle}
            inputStyle={`full-width ${styles.profileInput} ${
              !cartLoading && !couponIsOk ? styles.inputEnabled : styles.inputDisabled
            }`}
            inputClassName={cn({ [styles.errorText]: hasError })}
            type="text"
            setFieldValue
            onChange={handleOnChange}
            disabled={cartLoading || couponIsOk}
            readOnly={cartLoading || couponIsOk}
          />
        ) : (
          <Text className={styles.warning}>{i18next.t('OrderSummary:mustSelectPaymentMethod')}</Text>
        )}
        <button
          disabled={isCouponInputEmpty}
          type="button"
          onClick={couponIsOk ? handleRemove : handleApply}
          className={cn('text-brand-primary', styles.discountText, {
            [styles.buttonDisabled]: couponIsOk
          })}>
          {i18next.t(`OrderSummary:${couponIsOk ? 'remove' : 'apply'}`)}
        </button>
      </div>
      {!cartLoading && !cartError && couponIsOk && (
        <span className="text-green-observatory light-text light m-top-1">
          <i className={`icon-check ${styles.iconCheck}`} />
          {i18next.t('OrderSummary:validCoupon')}
        </span>
      )}
      {hasError && (
        <span className="text-red light-text light m-top-1">
          <i className={`icon-clean-full ${styles.iconCleanFull}`} /> {getCouponErrorMessage(cartError)}
        </span>
      )}
    </div>
  );
}

CouponDiscount.propTypes = {
  paymentMethod: string,
  setFieldValue: func
};

export default CouponDiscount;
