// eslint-disable import/prefer-default-export
const { Base64 } = require('js-base64');

const { nowIsBeforeThan } = require('~utils/time');

const tempStorage = {};

const encodeKey = (key) => Base64.encode(key);

const decodeKey = (key) => Base64.decode(key);

const removeValue = (key, storage) => {
  const encodedKey = encodeKey(key);

  try {
    storage.removeItem(encodedKey);
  } catch (e) {
    tempStorage[encodedKey] = undefined;
  }
};

const getValue = (key, storage) => {
  const encodedKey = encodeKey(key);
  let encodedValue = undefined;
  try {
    encodedValue = storage.getItem(encodedKey);
  } catch (e) {
    encodedValue = tempStorage[encodedKey];
  }
  const stringValue = encodedValue && decodeKey(encodedValue);
  const parsedValue = stringValue && JSON.parse(stringValue);
  if (parsedValue && parsedValue.expiryTime) {
    if (!nowIsBeforeThan(parsedValue.expiryTime)) {
      // If the item is expired, delete the item from storage
      // and return null
      removeValue(key);
      return null;
    }
  }
  return parsedValue;
};

const setValue = (key, value, storage) => {
  const encodedKey = encodeKey(key);
  const stringValue = JSON.stringify(value);
  const encodedValue = encodeKey(stringValue);
  try {
    storage.setItem(encodedKey, encodedValue);
  } catch (e) {
    tempStorage[encodedKey] = encodedValue;
  }
};

const defineProperty = (prop, defaultKey = '', tag = '', storage = window.localStorage) => {
  const projectName = `mercat-react-${window.location.hostname}`.replace(/-/g, '_').toUpperCase();
  const capitalizedKey = `${prop[0].toUpperCase()}${prop.substring(1)}`;

  module.exports[`set${capitalizedKey}`] = (val, key = defaultKey) =>
    setValue(`@@${projectName}:${prop}${tag}${key}`, val, storage);
  module.exports[`get${capitalizedKey}`] = (key = defaultKey) =>
    getValue(`@@${projectName}:${prop}${tag}${key}`, storage);
  module.exports[`remove${capitalizedKey}`] = (key = defaultKey) =>
    removeValue(`@@${projectName}:${prop}${tag}${key}`, storage);
};

// ------------------------------ LOCAL STORAGE PROPERTIES ------------------------------
defineProperty('accessToken');
defineProperty('addresses');
defineProperty('animationLoader');
defineProperty('cart');
defineProperty('currentSubsidiary');
defineProperty('dispatchType');
defineProperty('finalPrice');
defineProperty('futureOrderDate');
defineProperty('guestUser');
defineProperty('loginEmail');
defineProperty('primaryColor');
defineProperty('animationLoader');
defineProperty('futureOrderDate');
defineProperty('showUpsellingModal', true, '', sessionStorage);
defineProperty('profile');
defineProperty('commune');
defineProperty('plus18', false, '', sessionStorage);
