export const STATUS_KEYS = {
  SCHEDULED: 'creation_scheduled'
};

export const ORDER_STATUS = (status) =>
  ({
    [STATUS_KEYS.SCHEDULED]: 'scheduled',
    created: 'created',
    preparing: 'preparing',
    ready: 'ready',
    // eslint-disable-next-line camelcase
    inTransit: 'ready',
    in_transit: 'ready',
    completed: 'completed',
    cancelled: 'cancelled',
    failed: 'failed',
    reversed: 'reversed',
    unknown: 'unknown',
    canceled: 'canceled'
  }[status] || '');

export const ORDERS_TABS = {
  ORDERS: 'orders',
  ORDERS_HISTORY: 'ordersHistory'
};

export const ORDER_METHODS = {
  NEITHER: 'neither',
  BOTH: 'both',
  STORE_DELIVERY: 'store_delivery',
  IN_STORE: 'in_store'
};
