import i18next from 'i18next';

import { COUPON_CODE } from './constants';

i18next.addResources('es_CL', 'CouponDiscount', {
  [COUPON_CODE.COUPON_EXPIRED]: 'Cupón expirado',
  [COUPON_CODE.COUPON_MAX_REACHED]: 'Cupón llegó a su límite máximo general',
  [COUPON_CODE.COUPON_NOT_AVAILABLE]: 'Cupón no disponible por fecha',
  [COUPON_CODE.MAX_COUPON_USES_BY_USER_REACHED]: 'Límite máximo por usuario superado',
  [COUPON_CODE.COUPON_DISPATCH_METHOD]:
    'Método de despacho no coincide con el método generado para dicho cupón',
  [COUPON_CODE.COUPON_CANNOT_BE_USED_BY_USER]: 'Cupón creado exclusivamente para otro usuario',
  [COUPON_CODE.COUPON_WITH_MINIMUM_BILL]:
    'El total de la orden debe ser mayor que el mínimo del cupón ({{minimum}}) para canjear',
  [COUPON_CODE.COUPON_PLATFORM_ERROR]: 'El cupón es solo valido para la {{available}}',
  [COUPON_CODE.NOT_FIRST_PURCHASE]: 'el cupón solo se puede usar para la primera compra',
  [COUPON_CODE.INVALID_INVITATION_CODE]: 'El código de invitación no pertenece a ningún usuario',
  [COUPON_CODE.INVALID_MINIMUM_START_TIME]: 'El cupón aún no comienza su horario de funcionamiento',
  [COUPON_CODE.INVALID_MAXIMUM_EXPIRY_TIME]: 'El cupón expiró por horario de funcionamiento',
  [COUPON_CODE.COUPON_COUPONIZABLE_ERROR]: 'El cupón no es valido para ese producto o categoría',
  [COUPON_CODE.INVALID_COUPON_CODE]: 'Cupón no valido'
});
