import { useSelector } from 'react-redux';

import { DISPATCH_METHODS } from '~constants/address';
import { ORDER_METHODS } from '~constants/orders';

function useCheckStoreOpen() {
  const { dispatchType, currentSubsidiary } = useSelector((state) => state.searchStore);

  const futureOrdersEnabled = currentSubsidiary?.futureOrdersEnabled;
  const deliveryOpen = currentSubsidiary?.deliveryOpen;
  const pickUpOpen = currentSubsidiary?.pickUpOpen;
  const orderMethod = currentSubsidiary?.orderMethod;

  const isDelivery = dispatchType === DISPATCH_METHODS.DELIVERY;
  const closedByAttribute = orderMethod === ORDER_METHODS.NEITHER;
  const currentDispatchDisabled = isDelivery ? !deliveryOpen : !pickUpOpen;
  const storeClosed = currentDispatchDisabled || closedByAttribute;
  const orderDisabled = storeClosed && !futureOrdersEnabled;
  const onlyScheduledOrders = storeClosed && futureOrdersEnabled;

  return {
    storeClosed,
    orderDisabled,
    onlyScheduledOrders,
    closedByAttribute,
    currentDispatchDisabled,
    futureOrdersEnabled,
    deliveryOpen,
    pickUpOpen
  };
}

export default useCheckStoreOpen;
