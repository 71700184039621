import smoothscroll from 'smoothscroll-polyfill';

import { TOP_BAR_SIZE } from '~constants/sizes';

smoothscroll.polyfill();

export const handleScrolling = (elementToScroll) => {
  const startingY = window.pageYOffset;
  const elementY = startingY + elementToScroll.getBoundingClientRect().top - TOP_BAR_SIZE;

  window.scrollTo({ top: elementY, behavior: 'smooth' });
};

export const noScroll = (function noScrollFunc() {
  let isOn = false;
  let scrollbarSize = 0;
  let scrollTop = 0;

  function getScrollbarSize() {
    if (typeof scrollbarSize !== 'undefined') {
      return scrollbarSize;
    }

    const doc = document.documentElement;
    const dummyScroller = document.createElement('div');

    dummyScroller.setAttribute(
      'style',
      'width:99px;height:99px;position:absolute;top:-9999px;overflow:scroll;'
    );
    doc.appendChild(dummyScroller);
    scrollbarSize = dummyScroller.offsetWidth - dummyScroller.clientWidth;
    doc.removeChild(dummyScroller);

    return scrollbarSize;
  }

  function hasScrollbar() {
    return document.documentElement.scrollHeight > window.innerHeight;
  }

  function on() {
    if (typeof document === 'undefined' || isOn) {
      return;
    }
    const doc = document.documentElement;

    scrollTop = window.pageYOffset;
    if (hasScrollbar()) {
      doc.style.width = `calc(100% - ${getScrollbarSize()}px)`;
    } else {
      doc.style.width = '100%';
    }
    doc.style.position = 'fixed';

    doc.style.top = `${-scrollTop}px`;
    // doc.style.overflow = 'hidden';
    isOn = true;
  }

  function off() {
    if (typeof document === 'undefined' || !isOn) {
      return;
    }
    const doc = document.documentElement;
    const root = document.getElementById('root');
    if (!root) {
      return;
    }
    root.style.position = '';

    doc.style.width = '';
    doc.style.position = '';
    doc.style.top = '';
    doc.style.overflow = '';
    window.scroll(0, scrollTop);
    isOn = false;
  }

  function toggle() {
    if (isOn) {
      off();

      return;
    }
    on();
  }

  return {
    on,
    off,
    toggle
  };
})();
