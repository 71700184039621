/* eslint-disable no-empty-function */
import { createContext } from 'react';

const RadioGroupContext = createContext({
  name: '',
  handleChange: () => {},
  groupDisabled: false,
  selectedValue: ''
});

export default RadioGroupContext;
