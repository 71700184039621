import i18next from 'i18next';

i18next.addResources('es_CL', 'Loyalty', {
  yourPoints: 'Tus Puntos',
  pointsMessage: 'Canjea tus puntos por descuentos al momento de comprar.',
  pointsYouGot: 'Tienes',
  pointsRedeemable: 'Canjeables por',
  pointsDiscount: 'de descuento',
  startBuy: '¡Realiza una compra para acumular puntos de forma automática!'
});
