import React from 'react';
import i18next from 'i18next';
import { func, object } from 'prop-types';

import Field from '~components/Field';
import RadioGroup from '~components/RadioGroup';
import ErrorMessage from '~components/ErrorMessage';
import RadioOption, { SIZES } from '~components/RadioGroup/components/RadioOption';

import styles from './styles.module.scss';
import { DELIVERY_OPTIONS, DELIVERY_OPTIONS_RADIO_NAME } from './constants';

function DeliveryOptions({ setFieldValue, errors }) {
  return (
    <div className="column space-around">
      <div className="column relative">
        <Field
          component={RadioGroup}
          name={DELIVERY_OPTIONS_RADIO_NAME}
          setFieldValue={setFieldValue}
          className={styles.radioContainer}>
          {DELIVERY_OPTIONS.map((checkItem) => (
            <RadioOption
              key={checkItem}
              name={checkItem}
              item={checkItem}
              label={<span className={styles.label}>{i18next.t(`DeliveryOptions:${checkItem.name}`)}</span>}
              id={checkItem.name}
              useCheck
              size={SIZES.LARGE}
            />
          ))}
        </Field>
        {errors.deliveryOption && <ErrorMessage className={styles.error} message={errors.deliveryOption} />}
      </div>
    </div>
  );
}

DeliveryOptions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  errors: object.isRequired,
  setFieldValue: func.isRequired
};

export default DeliveryOptions;
