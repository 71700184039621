import api, { cdnApi } from '~config/api';
import { mapCart } from '~redux/Cart/mappers';

const getMenu = (values) => api.get('v2/prices', values);

const getUpsellings = (state) =>
  api.post('v1/shopping_carts/suggestive_sells', mapCart(state)).then((res) => ({
    ok: res.ok,
    data: res.data
  }));

const getBanners = (values) => api.get('v2/banners', values);

const getPublicMenu = (id) => api.get(`v1/menus/${id}`);

const getStaticMenu = (id) => cdnApi.get(`menus/${id}.json?nocache=${Date.now()}`);

const getDisabledsProducts = (id) => api.get(`v1/disableds?store_id=${id}`);

export default {
  getMenu,
  getUpsellings,
  getBanners,
  getPublicMenu,
  getStaticMenu,
  getDisabledsProducts
};
