import { hot } from 'react-hot-loader/root';
import React from 'react';
import { Provider } from 'react-redux';
import Modal from 'react-modal';

import store from '~redux/store';

import Routes from './components/Routes';
import CoreSettingsWrapper from './components/CoreSettingsWrapper';

Modal.setAppElement('#root');

function App() {
  return (
    <Provider store={store}>
      <CoreSettingsWrapper>
        <Routes />
      </CoreSettingsWrapper>
    </Provider>
  );
}

export default hot(App);
