/* eslint-disable babel/no-unused-expressions */
import { createTypes, completeTypes, withPostSuccess } from 'redux-recompose';
import { replace } from 'connected-react-router';
import withPostFailure from 'redux-recompose/lib/injections/withPostFailure';

import ModalActions from '~redux/Modal/actions';
import SearchStoreActions from '~redux/SearchStore/actions';
import AuthActions from '~redux/Auth/actions';
import { MODALS } from '~redux/Modal/constants';
import {
  createCart,
  getCartById,
  getLastCart,
  sendCart,
  sendCartWithCoupon,
  updateCart
} from '~services/Cart/service';
import LocalStorageService from '~services/LocalStorageService';
import AnalyticsService from '~services/Analytics';
import {
  addItemToCart,
  modifyItemInCart,
  sumItemToCart,
  checkCartDenormalize,
  priceCart,
  anyProductIsUnavailable,
  addDiscountToCart
} from '~utils/product';

import { targets } from './constants';
import { mapCart } from './mappers';

export const actions = createTypes(
  completeTypes(
    [
      'ADD_COUPON',
      'CHECK_CART',
      'CREATE_REMOTE_CART',
      'UPDATE_REMOTE_CART',
      'GET_LAST_REMOTE_CART',
      'GET_CART_BY_ID'
    ],
    [
      'SET_ITEM',
      'SET_CART',
      'SET_FINAL_PRICE',
      'SET_SUBTOTAL_PRICE',
      'SET_ERROR',
      'SET_DISCOUNT',
      'SET_DELIVERY',
      'ADD_ITEM',
      'SUM_ITEM',
      'REMOVE_ITEM',
      'MODIFY_ITEM',
      'DELETE_REMOTE_CART',
      'RESTORE_CART'
    ]
  )
);

const setLocalStorage = (cart) => {
  LocalStorageService.setCart(cart);
  LocalStorageService.setFinalPrice(priceCart(cart));
};

const getAuthToken = () => {
  return LocalStorageService.getAccessToken() || LocalStorageService.getGuestUser();
};

const getCartPayload = ({ item, state, mapper }) => mapper(item, state);

const getUser = (getState) => {
  return getState().auth.currentUser || LocalStorageService.getProfile();
};

const isSameUserCart = (getState) => {
  const remoteCart = getState().cart[targets.REMOTE_CART];
  if (!remoteCart) {
    return false;
  }

  const user = getUser(getState);
  return user?.email === remoteCart.shoppingCart.userEmail;
};

const getRemoteCardPayload = (getState) => {
  const cart = getState().cart[targets.CART];
  const user = getUser(getState);
  const { shoppingCart } = mapCart({ cart, state: getState(), user });
  const localShoppingCart = LocalStorageService.getCart() || [];

  return {
    shoppingCart,
    localShoppingCart
  };
};

const actionCreators = {
  addItem: (item, closeUrl) => (dispatch, getState) => {
    const state = getState();
    const payload = getCartPayload({ item, state, mapper: addItemToCart });
    AnalyticsService.addToCartEvent(state, {
      item,
      currency: state.core.settings.currency
    });
    setLocalStorage(payload);
    dispatch(replace(closeUrl));
    dispatch(ModalActions.closeModal(MODALS.PRODUCT_DETAIL));
    dispatch({
      type: actions.ADD_ITEM,
      target: targets.CART,
      payload
    });
    dispatch(SearchStoreActions.getScheduledDateAvailability(item.product?.deferred));
    dispatch(actionCreators.setSubtotalPrice(priceCart(payload)));
  },
  sumItem: (item) => (dispatch, getState) => {
    const payload = getCartPayload({
      item,
      state: getState(),
      mapper: sumItemToCart
    });
    setLocalStorage(payload);
    dispatch({
      type: actions.SUM_ITEM,
      target: targets.CART,
      payload
    });
    dispatch(actionCreators.setSubtotalPrice(priceCart(payload)));
  },
  removeItem: ({ item, onPostSuccess, mapper }) => (dispatch, getState) => {
    const payload = getCartPayload({
      item,
      state: getState(),
      mapper
    });
    setLocalStorage(payload);
    dispatch({
      type: actions.REMOVE_ITEM,
      target: targets.CART,
      payload
    });
    dispatch(actionCreators.setSubtotalPrice(priceCart(payload)));
    if (onPostSuccess) {
      onPostSuccess();
    }
    dispatch(SearchStoreActions.getScheduledDateAvailability(item.product?.deferred));
  },
  setItem: (item) => ({
    type: actions.SET_ITEM,
    target: targets.ITEM,
    payload: item
  }),
  modifyItem: (item, closeUrl) => (dispatch, getState) => {
    const payload = getCartPayload({
      item,
      state: getState(),
      mapper: modifyItemInCart
    });
    setLocalStorage(payload);
    dispatch(replace(closeUrl));
    dispatch(ModalActions.closeModal(MODALS.PRODUCT_DETAIL));
    dispatch(actionCreators.setItem(null));
    dispatch({
      type: actions.MODIFY_ITEM,
      target: targets.CART,
      payload
    });
    dispatch(actionCreators.setSubtotalPrice(priceCart(payload)));
  },
  setCart: (cart) => (dispatch) => {
    return dispatch({
      type: actions.SET_CART,
      target: targets.CART,
      payload: cart
    });
  },
  setSubtotalPrice: (subtotal) => (dispatch) => {
    dispatch({
      type: actions.SET_SUBTOTAL_PRICE,
      target: targets.SUBTOTAL_PRICE,
      payload: subtotal
    });
  },
  addDiscount: ({ couponData, loyaltyData, pointsRate }, [onPostSuccess, onPostFailure]) => ({
    type: actions.ADD_COUPON,
    target: targets.CART,
    payload: addDiscountToCart({ couponData, loyaltyData, pointsRate }),
    service: sendCartWithCoupon,
    failureSelector: (response) => response.data?.error || response.problem,
    injections: [
      withPostSuccess((_, response) => {
        LocalStorageService.setCart(response.data.cart);
        LocalStorageService.setFinalPrice(response.data.subtotal);
        if (onPostSuccess) onPostSuccess(response);
      }),
      withPostFailure((_, response) => {
        if (onPostFailure) onPostFailure(response);
      })
    ]
  }),
  setError: (error) => ({
    type: actions.SET_ERROR,
    target: targets.ERROR,
    payload: error
  }),
  setDiscount: (discount) => ({
    type: actions.SET_DISCOUNT,
    target: targets.DISCOUNT,
    payload: discount
  }),
  checkCart: ({ showModalError = false, address = null } = {}) => ({
    type: actions.CHECK_CART,
    target: targets.CART,
    payload: checkCartDenormalize(address),
    service: sendCart,
    injections: [
      withPostSuccess((_, response) => {
        LocalStorageService.setCart(response.data.cart);
        LocalStorageService.setFinalPrice(response.data.subtotal);
      }),
      withPostFailure((dispatch) => {
        if (showModalError) {
          dispatch(ModalActions.openModal(MODALS.ERROR_CHECK_CART));
        }
      })
    ]
  }),
  checkItemsAvailability: () => (dispatch, getState) => {
    const state = getState();
    if (anyProductIsUnavailable(state)) {
      dispatch(ModalActions.openModal(MODALS.UNAVAILABLE_PRODUCTS));
    }
  },
  createOrUpdateRemoteCart: () => async (dispatch, getState) => {
    const queryParams = new URLSearchParams(location.search);
    const cartId = queryParams.get('cart');

    if (cartId) {
      return;
    }

    const token = getAuthToken();
    if (!token) {
      return;
    }

    const remoteCart = getState().cart[targets.REMOTE_CART];
    if (remoteCart) {
      if (isSameUserCart(getState)) {
        const { shoppingCart, localShoppingCart } = getRemoteCardPayload(getState);
        await dispatch({
          type: actions.UPDATE_REMOTE_CART,
          target: targets.REMOTE_CART,
          service: updateCart,
          payload: {
            shoppingCart: {
              ...shoppingCart,
              localShoppingCart,
              id: remoteCart.shoppingCart.id
            }
          }
        });
        return;
      }

      dispatch({
        type: actions.DELETE_REMOTE_CART,
        target: targets.REMOTE_CART,
        payload: null
      });
    }

    await dispatch({
      type: cartId ? actions.GET_CART_BY_ID : actions.GET_LAST_REMOTE_CART,
      target: targets.REMOTE_CART,
      payload: cartId || null,
      service: cartId ? getCartById : getLastCart
    });

    const lastRemoteCart = getState().cart[targets.REMOTE_CART];
    if (lastRemoteCart?.shoppingCart?.orderId) {
      dispatch({
        type: actions.DELETE_REMOTE_CART,
        target: targets.REMOTE_CART,
        payload: null
      });
    }

    const newRemoteCart = getState().cart[targets.REMOTE_CART];
    if (!newRemoteCart) {
      const { shoppingCart, localShoppingCart } = getRemoteCardPayload(getState);
      if (!localShoppingCart.length) {
        return;
      }

      dispatch({
        type: actions.CREATE_REMOTE_CART,
        target: targets.REMOTE_CART,
        payload: {
          shoppingCart: { ...shoppingCart, localShoppingCart }
        },
        service: createCart
      });
    }
  },
  removeRemoteCart: (orderId) => async (dispatch, getState) => {
    const newRemoteCart = getState().cart[targets.REMOTE_CART];

    if (!newRemoteCart) {
      return;
    }

    const { shoppingCart } = newRemoteCart;
    await dispatch({
      type: actions.UPDATE_REMOTE_CART,
      target: targets.REMOTE_CART,
      payload: { shoppingCart: { ...shoppingCart, orderId } },
      service: updateCart
    });

    return dispatch({
      type: actions.DELETE_REMOTE_CART,
      target: targets.REMOTE_CART,
      payload: null
    });
  },
  getCartById: (cartId) => ({
    type: actions.GET_CART_BY_ID,
    target: targets.REMOTE_CART,
    payload: cartId,
    service: getCartById
  }),
  restoreCart: (cartId, storeId, dispatchMethod) => async (dispatch, getState) => {
    const token = getAuthToken();
    if (!token) {
      return;
    }

    await dispatch({
      type: actions.GET_CART_BY_ID,
      target: targets.REMOTE_CART,
      payload: cartId,
      service: getCartById
    });

    const remoteCart = getState().cart[targets.REMOTE_CART];
    if (!remoteCart) {
      dispatch(replace('/'));
      return;
    }

    const skipRedirectStoreAndDispatchMethod = storeId || dispatchMethod;
    if (!skipRedirectStoreAndDispatchMethod) {
      const { storeId, dispatchMethod } = remoteCart.shoppingCart;
      const currentStoreId = getState().searchStore.currentSubsidiary?.id;
      const currentDispatchMethod = getState().searchStore.dispatchType;
      if (storeId !== currentStoreId || dispatchMethod !== currentDispatchMethod) {
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.append('store', storeId);
        newUrl.searchParams.append('dispatch-method', dispatchMethod);
        await dispatch(replace(`${newUrl.pathname}${newUrl.search}`));
        return;
      }
    }
    const newCart = remoteCart.shoppingCart.localShoppingCart;
    if (!newCart.length) {
      return;
    }

    const store = getState().searchStore.currentSubsidiary;
    const { userLatitude, userLongitude, userCity, userAddress } = remoteCart.shoppingCart;
    dispatch(
      AuthActions.setAddress({
        latitude: userLatitude,
        longitude: userLongitude,
        hasStreetNumber: true,
        city: userCity,
        description: userAddress,
        textAddress: userAddress
      })
    );

    dispatch(actionCreators.setCart([...newCart]));
    await dispatch(
      actionCreators.checkCart({
        showModalError: true,
        address: {
          latitude: userLatitude || store.latitude,
          longitude: userLongitude || store.longitude,
          hasStreetNumber: true,
          city: userCity || store.commune,
          description: userAddress || store.textAddress,
          textAddress: userAddress || store.textAddress
        }
      })
    );
    dispatch(actionCreators.checkItemsAvailability());
    dispatch(replace('/'));
  }
};

export default actionCreators;
