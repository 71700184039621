import i18next from 'i18next';

i18next.addResources('es_CL', 'CompleteProfile', {
  title: 'Datos personales',
  name: 'Nombre',
  fullnamePlaceholder: 'Nombre y apellido',
  surname: 'Apellido',
  email: 'Email',
  emailPlaceholder: 'Ingresa tu correo electrónico',
  phone: 'Teléfono',
  phonePlaceholder: 'Ingresa tu teléfono',
  login: 'Iniciar sesión',
  couponLogin: '¡Inicia sesión ',
  couponLoginDescription: 'para agregar códigos de descuento, acumular y canjear tus {{rewardPointsName}}!',
  signUp: 'Regístrate',
  hello: '¡Hola, {{name}}!',
  userName: '{{name}}',
  defaultLoginDescription: 'para agregar códigos de descuento!',
  earnableLoyaltyPoints: ' Además de poder acumular {{earnablePoints}} {{rewardPointsName}} con esta compra!',
  loggedInEarnablePoints: '¡Con esta compra obtendrás {{earnablePoints}} {{rewardPointsName}}!'
});
