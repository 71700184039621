import { targets } from './constants';

export const onCartAction = (state, action) =>
  state.merge({
    [targets.CART]: action.payload.cart,
    [targets.FINAL_PRICE]: action.payload.total,
    [targets.DELIVERY]: action.payload.delivery,
    [targets.DELIVERY_METHOD]: action.payload.deliveryMethod,
    [targets.DELIVERY_DETAILS]: action.payload.deliveryDetails,
    [targets.UBER_ESTIMATE_ID]: action.payload[targets.UBER_ESTIMATE_ID],
    [targets.UBER_ESTIMATE_EXPIRES_AT]: action.payload[targets.UBER_ESTIMATE_EXPIRES_AT],
    [targets.DISCOUNT]: 0,
    [targets.ERROR]: '',
    [`${targets.CART}Loading`]: false,
    [`${targets.CART}Error`]: null,
    [targets.MAX_LOYALTY_MONEY_TO_USE]: action.payload[targets.MAX_LOYALTY_MONEY_TO_USE],
    [targets.LOYALTY_PROGRAM]: action.payload[targets.LOYALTY_PROGRAM]
  });

export const onCartActionWithCoupon = (state, action) =>
  state.merge({
    [targets.CART]: action.payload.cart,
    [targets.FINAL_PRICE]: action.payload.total,
    [targets.DELIVERY]: action.payload.delivery,
    [targets.DELIVERY_METHOD]: action.payload.deliveryMethod,
    [targets.DELIVERY_DETAILS]: action.payload.deliveryDetails,
    [targets.UBER_ESTIMATE_ID]: action.payload[targets.UBER_ESTIMATE_ID],
    [targets.UBER_ESTIMATE_EXPIRES_AT]: action.payload[targets.UBER_ESTIMATE_EXPIRES_AT],
    [targets.DISCOUNT]: action.payload.discount,
    [targets.ERROR]: '',
    [`${targets.CART}Loading`]: false,
    [`${targets.CART}Error`]: null,
    [targets.COUPON]: action.payload.coupon,
    [targets.LOYALTY_POINTS]: action.payload.loyaltyPoints,
    [targets.MAX_LOYALTY_MONEY_TO_USE]: action.payload[targets.MAX_LOYALTY_MONEY_TO_USE],
    [targets.LOYALTY_PROGRAM]: action.payload[targets.LOYALTY_PROGRAM]
  });
