export const MODALS = {
  PRODUCT_DETAIL: 'productDetail',
  DETAILS_ORDER: 'detailsOrder',
  REPEAT_ORDER: 'repeatOrder',
  DELIVERY_OR_PICKUP: 'deliveryOrPickup',
  CONFIRMATION_ORDER: 'confirmationOrder',
  ORDER_CREATION_STATUS: 'orderCreationStatus',
  MINI_CART: 'miniCart',
  PROFILE_MENU: 'profileMenu',
  LOGIN: 'login',
  SESSION_CLOSED: 'sessionClosed',
  STORE_CLOSED: 'storeClosed',
  TOP_SITE_MESSAGE: 'topSiteMessage',
  UNAVAILABLE_PRODUCTS: 'unavailableProducts',
  FUTURE_ORDER: 'futureOrder',
  NO_RIDERS: 'noRiders',
  PAYMENT_METHOD: 'paymentMethod',
  KUSHKI: 'kushki',
  ADD_TIP: 'addTip',
  FULL_ORDER_DETAIL: 'fullOrderDetail',
  PICK_COMMUNE: 'pickCommune',
  REWARD_ERROR: 'rewardError',
  ERROR_CHECK_CART: 'errorCheckCart',
  SET_CVC: 'setCVC',
  CREDOMATIC: 'credomatic',
  ADD_CARD: 'addCard',
  PUBLIC_MENU_CATEGORIES: 'publicMenuCategories',
  PRODUCT_INFO: 'productInfo',
  FORBIDDEN: 'forbidden'
};

export const ADD_ADDRESS_MODAL = 'addAddress';
export const CHANGE_ADDRESS = 'changeAddress';
