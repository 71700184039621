export const hyphensToCamelCase = (str) => {
  const arr = str.split(/[_-]/);
  let newStr = '';
  for (let i = 1; i < arr.length; i++) {
    newStr += arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr[0] + newStr;
};

const DEFAULT_RETRIES = 3;

export const retry = async (request, retries = DEFAULT_RETRIES) => {
  const response = await request();

  if (!response.ok && retries > 0 && !response.abortRetries) {
    return retry(request, retries - 1);
  }

  return response;
};
