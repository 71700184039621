import { createTypes, completeTypes } from 'redux-recompose';

import PagesService from '~services/Pages/service';

export const actions = createTypes(completeTypes(['GET_PAGES'], []), '@@PAGES');

const targets = {
  PAGES: 'pages'
};
const actionCreators = {
  getPages: (pageNumber) => ({
    type: actions.GET_PAGES,
    service: PagesService.getPages,
    payload: pageNumber,
    target: targets.PAGES
  })
};

export default actionCreators;
