import Immutable from 'seamless-immutable';

/**
 * Receives an array of strings, and returns an obj with that strings as properties with that string as value.
 * E.G:
 * stringArrayToObject(['A', 'B', 'C']) // { A: 'A', B: 'B', C: 'C' }
 * @param {array} actionsArray array of values
 * @param {string} namespace prefix for the resulting values
 * @returns {object} (['A', 'B', 'C', 'name']) -> { A: '@name/A', B: '@name/B', C: '@name/C' }
 */
export function stringArrayToObject(actionsArray, namespace = '') {
  if (actionsArray.some((actionName) => !actionName || typeof actionName !== 'string')) {
    throw new Error('Action names must be strings and must not be empty');
  }

  return new Immutable(actionsArray).asObject((actionName) => [actionName, `${namespace}:${actionName}`]);
}

/**
 * Receives a length, and returns a new Array with indices on each position.
 * E. G:
 * arrayOfIndices(3) // [0, 1, 2]
 * This is for a reason: avoiding the use of for loops.
 * @param {number} length length of the array
 * @returns {array} [0, ..., length]
 *
 * @see https://github.com/airbnb/javascript#iterators--nope
 */
export function arrayOfIndices(length) {
  // .fill(<something not undefined>) is required for map, map ignore undefined indexes
  return Array(length)
    .fill(null)
    .map((_, index) => index);
}

export const isArray = (object) => object?.constructor?.name === 'Array';

export const lastItemArray = (array) => array[array.length - 1];

export const isLastItem = (array, index) => index === array.length - 1;

const concat = (x, y) => x.concat(y);

export const flatMap = (f, xs) => xs.map(f).reduce(concat, []);

export const findAndUpdate = (condition, update, array) => {
  const arrayCopy = [...array];
  const index = array.findIndex(condition);
  if (index === -1) {
    return [array, false];
  }
  arrayCopy[index] = update(arrayCopy[index]);
  return [arrayCopy, true];
};

export const sum = (array) => array.reduce((a, b) => a + b, 0);

export const sortBy = (array, key) => Array.from(array).sort((a, b) => a[key] - b[key]);

export const arrayHasLength = (array) => array?.length > 0;

export const removeItemFromArray = (item, array) => array?.filter((i) => i !== item);

export const filterBy = (array, filters) => {
  const reducer = (currentArray, filter) => currentArray.filter(filter);
  return filters.reduce(reducer, array);
};

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};
