import i18next from 'i18next';

i18next.addResources('es_CL', 'CategoriesBar', {
  california: 'California Rolls',
  niu: 'Combinaciones NIU',
  nikkei: 'Nikkei Rolls',
  toSnak: 'Para picar',
  specialRolls: 'Special Rolls',
  tempura: 'Tempura & Panko Rolls',
  others: 'Otros'
});
