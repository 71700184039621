/* eslint-disable camelcase */
import i18next from 'i18next';

i18next.addResources('es_CL', 'Actionlinks', {
  storeDelivery: 'Delivery',
  enterAddress: 'Ingresa tu dirección',
  inStore: 'Retiro',
  time: 'Hora',
  address: 'Dirección',
  or: ' o'
});
