import Immutable from 'seamless-immutable';
import { createReducer, completeReducer, completeState, onReadValue } from 'redux-recompose';

import { actions } from './actions';
import { TARGETS, INITIAL_TIP, INITIAL_ORDERS_TAB } from './constants';

const stateDescription = {
  createOrder: null,
  [TARGETS.ONLINE_PAYMENT_TARGET]: null,
  orders: [],
  lastOrder: null,
  activeOrders: { page: null },
  [TARGETS.TIP_TARGET]: INITIAL_TIP,
  [TARGETS.QUESTIONS]: [],
  ordersTab: INITIAL_ORDERS_TAB,
  [TARGETS.FUTURE_ORDER]: false
};

const initialState = completeState(stateDescription);

const reducerDescription = {
  primaryActions: [
    actions.CREATE_ORDER,
    actions.ONLINE_PAYMENT,
    actions.GET_ORDERS,
    actions.GET_LAST_ORDER,
    actions.GET_ACTIVE_ORDERS,
    actions.ADD_PURCHASE_TO_DATALAYER,
    actions.GET_QUESTIONS,
    actions.CREATE_ANSWERS,
    actions.SET_PRESELECTED_FUTURE_ORDER_DATE,
    actions.GET_KUSHKI_TOKEN,
    actions.GET_KUSHKI_SUBSCRIPTION_TOKEN
  ],
  override: {
    [actions.SET_TIP]: onReadValue(),
    [actions.RESET_TIP]: onReadValue(),
    [actions.SET_ORDERS_TAB]: onReadValue(),
    [actions.SET_FUTURE_ORDER]: onReadValue(),
    [actions.SET_PRESELECTED_FUTURE_ORDER_DATE]: onReadValue(),
    [actions.CLEAR_KUSHKI_TOKEN_ERROR]: (state) =>
      state.merge({
        ...state,
        [TARGETS.KUSHKI_TOKEN_ERROR]: null
      })
  }
};

export default createReducer(new Immutable(initialState), completeReducer(reducerDescription));
