import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { func, string } from 'prop-types';

import { pluralize, capitalize } from '~utils/string';
import Input from '~components/Input';
import Field from '~components/Field';
import CartActions from '~redux/Cart/actions';
import icoRewardPoints from '~assets/ico-rewardpoints.svg';
import { formatPrice } from '~utils/number';
import useOrderDetails from '~hooks/useOrderDetail';

import RewardDisclaimer from '../RewardDisclaimer';
import { REWARDS_FIELD } from '../../../OrderSummary/constants';
import { FIELDS } from '../../../../constants';

import styles from './styles.module.scss';
import { converFromMoneyToNumber } from './utils';
import useRewardPoints from './hooks/useRewardPoints';

function RewardPoints({ setFieldValue, paymentMethod, loyaltyProgramName }) {
  const dispatch = useDispatch();
  const { subtotal } = useOrderDetails();

  const {
    rewardPointsName,
    maxLoyaltyMoneyToUse,
    loyaltyPoints,
    minAmountCCLimit,
    maxAmountCCLimit,
    coupon,
    errorMessages,
    cartLoading
  } = useRewardPoints();

  const [reward, setReward] = useState(loyaltyPoints || maxLoyaltyMoneyToUse || null);
  const [hasReward, setHasReward] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const hasCoupon = coupon?.appliedCoupon?.code;
  const couponCode = hasCoupon && coupon?.appliedCoupon?.code;

  const handleOnChange = (e) => {
    const changedValue = converFromMoneyToNumber(e.target.value);
    setReward(parseInt(changedValue) || 0);
  };

  const handleApply = () => {
    if (cartLoading) return;
    new Promise((...params) => {
      dispatch(
        CartActions.addDiscount(
          {
            couponData: {
              coupon: hasCoupon ? couponCode : '',
              paymentMethods: paymentMethod
            },
            loyaltyData: {
              cashToExchange: reward
            }
          },
          params
        )
      );
    }).then(({ data }) => {
      setFieldValue(FIELDS.LOYALTY_POINTS, data.loyaltyPoints);
      setHasReward(true);
    });
  };

  const handleRemove = () => {
    if (cartLoading) return;
    new Promise((...params) => {
      dispatch(
        CartActions.addDiscount(
          {
            couponData: {
              coupon: hasCoupon ? couponCode : '',
              paymentMethods: paymentMethod
            },
            loyaltyData: { cashToExchange: null }
          },
          params
        )
      );
    }).then(() => {
      setFieldValue(FIELDS.LOYALTY_POINTS, null);
      setHasReward(false);
    });
  };

  const rewardIsGreaterThanMinCCLimit = (money) => money >= minAmountCCLimit;
  const rewardIsLowerThanMaxAmountToBeExchanged = (money) => money <= maxLoyaltyMoneyToUse;
  const rewardIsDifferentFromZero = (money) => money !== 0;
  const hasEnoughMoney = (money) => maxLoyaltyMoneyToUse >= money;
  const isValidForUsePoints = (money) =>
    rewardIsDifferentFromZero(money) &&
    rewardIsGreaterThanMinCCLimit(money) &&
    rewardIsLowerThanMaxAmountToBeExchanged(money) &&
    hasEnoughMoney(money);

  const hasError = !hasReward && !isValidForUsePoints(reward);

  const isGreaterThan = (value1, value2) => value1 > value2;

  useEffect(() => {
    return () => {
      dispatch(
        CartActions.addDiscount(
          {
            couponData: {
              coupon: '',
              paymentMethods: ''
            },
            loyaltyData: { cashToExchange: null }
          },
          []
        )
      );
    };
  }, [dispatch]);

  useEffect(() => {
    if (isGreaterThan(reward, subtotal)) return setErrorMessage('rewardHigherThanTotalPrice');
    if (isGreaterThan(reward, maxLoyaltyMoneyToUse)) return setErrorMessage('notEnoughPoints');
    if (isGreaterThan(reward, maxAmountCCLimit)) return setErrorMessage('maxAmountToExchange');
    if (isGreaterThan(minAmountCCLimit, reward)) return setErrorMessage('minAmountToExchange');
    if (reward === 0) return setErrorMessage('notDiscount');
    setErrorMessage('');
  }, [
    hasCoupon,
    hasError,
    maxAmountCCLimit,
    maxLoyaltyMoneyToUse,
    minAmountCCLimit,
    reward,
    rewardPointsName,
    subtotal
  ]);

  useEffect(() => {
    if (hasReward && loyaltyPoints === null) {
      setFieldValue(FIELDS.LOYALTY_POINTS, null);
      setHasReward(false);
    }
  }, [loyaltyPoints, hasReward, setFieldValue]);

  useEffect(() => {
    setReward(loyaltyPoints || maxLoyaltyMoneyToUse);
  }, [maxLoyaltyMoneyToUse, loyaltyPoints]);

  useEffect(() => {
    if (loyaltyPoints) {
      setHasReward(true);
    }
  }, [loyaltyPoints]);

  return (
    <>
      {maxLoyaltyMoneyToUse === 0 && (
        <RewardDisclaimer
          message={i18next.t('RewardPoints:disclaimerCouponUsed', {
            rewardPointsName: capitalize(pluralize(loyaltyProgramName))
          })}
        />
      )}
      {maxLoyaltyMoneyToUse !== 0 && (
        <div className={styles.container}>
          <div className="row text-emperor bold font-size-14 m-bottom-4">
            <div className="col">
              <img src={icoRewardPoints} alt="reward icon" className="m-right-2" />
            </div>
            <div className="row text-emperor bold font-size-14 m-bottom-2">
              {i18next.t('RewardPoints:title', {
                amount: formatPrice(loyaltyPoints || maxLoyaltyMoneyToUse),
                rewardPointsName: rewardPointsName
              })}
            </div>
          </div>

          <div
            className={cn('row middle space-between', styles.containerStyle, {
              [styles.disabled]: hasReward
            })}>
            <Field
              component={Input}
              value={formatPrice(reward)}
              name={REWARDS_FIELD}
              spanStyle={styles.inputTitle}
              inputStyle={cn('full-width', styles.profileInput, {
                [styles.inputEnabled]: !hasReward,
                [styles.inputDisabled]: hasReward
              })}
              inputClassName={cn({
                [styles.errorText]: hasError && !hasReward
              })}
              type="text"
              setFieldValue
              onChange={handleOnChange}
              disabled={hasReward}
              readOnly={hasReward}
            />
            <button
              disabled={reward === 0 || hasError}
              type="button"
              onClick={hasReward ? handleRemove : handleApply}
              className={cn('text-brand-primary', styles.discountText, {
                [styles.disabledButton]: hasReward || reward === 0 || hasError,
                [styles.disabledHover]: reward === 0 || hasError
              })}>
              {i18next.t(`RewardPoints:${hasReward ? 'remove' : 'apply'}`)}
            </button>
          </div>
          {hasReward && (
            <span className="text-green-observatory light-text light m-top-1">
              <i className={`icon-check ${styles.iconCheck}`} />
              {i18next.t('RewardPoints:validCoupon')}
            </span>
          )}
          {hasError && (
            <span className="text-red light-text light m-top-1">
              <span className={`material-icons m-right-1 ${styles.iconCleanFull}`}>cancel</span>{' '}
              {errorMessages[errorMessage]}
            </span>
          )}
        </div>
      )}
    </>
  );
}

RewardPoints.propTypes = {
  loyaltyProgramName: string,
  paymentMethod: string,
  setFieldValue: func
};

export default RewardPoints;
