import React from 'react';

import LoadingWrapper from '~components/Spinner/LoadingWrapper';

import { LOADING_ICON_SIZE } from './constants';

function LoadingContainer() {
  return (
    <LoadingWrapper withInitialLoading iconProps={{ width: LOADING_ICON_SIZE, height: LOADING_ICON_SIZE }} />
  );
}

export default LoadingContainer;
