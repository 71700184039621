import i18next from 'i18next';

i18next.addResources('es_CL', 'ForbiddenModal', {
  welcome: '¡Bienvenido!',
  areOlder: '¿Eres mayor de edad?',
  subtitle: 'Para comprar en nuestra tienda debes ser mayor de 18 años.',
  positive: 'sí, soy mayor de edad',
  negative: 'no, soy menor de edad',
  sorry: 'Lo sentimos',
  noAgeEnough: 'No tienes la edad legal para entrar en nuestra tienda.'
});
