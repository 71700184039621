import { createTypes } from 'redux-recompose';

export const actions = createTypes(['SHOW', 'CLOSE'], '@@NOTIFICATION');

export const actionCreators = {
  show: (value) => ({
    type: actions.SHOW,
    payload: value
  }),
  close: () => ({
    type: actions.CLOSE
  })
};
