import Inmutable from 'seamless-immutable';
import { createReducer, onReadValue } from 'redux-recompose';

import { actions } from './actions';

const reducerDescription = {
  [actions.SET_SEARCH_VALUE]: onReadValue()
};

const reducer = createReducer(new Inmutable({}), reducerDescription);

export default reducer;
