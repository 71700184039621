/*
 * TODO Add this if you need it
 * export const sidebarSize = 100;
 */
import { navbarHeight } from '~scss/variables/_variables.scss';
import { scssVariableToNumber } from '~utils/screenSize';

export const MOBILE_SIZE = 550;

export const isMobile = () => window.innerWidth <= MOBILE_SIZE;

// eslint-disable-next-line no-magic-numbers
export const TOP_BAR_SIZE = isMobile() ? 109 : scssVariableToNumber(navbarHeight);
