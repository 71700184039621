export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'active',
  FAILED_PAYMENT: 'failed_payment',
  SUSPENDED: 'suspended',
  INACTIVE: 'inactive'
};

export const isAccountStatusSuspended = (subscriptionStatus) => {
  const isSuspendedStatus =
    subscriptionStatus === SUBSCRIPTION_STATUS.SUSPENDED ||
    subscriptionStatus === SUBSCRIPTION_STATUS.INACTIVE;

  return isSuspendedStatus;
};
