import React from 'react';
import { arrayOf, string, bool, func, node, number } from 'prop-types';

import RadioGroupContext from './context';

function RadioGroup({
  disabled = false,
  className = '',
  name,
  children,
  onChange,
  value,
  error,
  setFieldValue,
  limit = 1,
  mapInitialValue = (val) => val?.[0]?.name
}) {
  const handleChange = (newSelected, item) => () => {
    const existingItem = value.find((i) => i.name === newSelected);
    const isSelected = existingItem && existingItem.quantity === item.quantity;
    setFieldValue(name, isSelected || item.quantity === 0 ? [] : [item]);
    if (onChange) {
      onChange(newSelected);
    }
  };

  return (
    <div id={name} className={className}>
      <RadioGroupContext.Provider
        value={{
          selectedValue: value,
          mapInitialValue,
          error,
          handleChange,
          name,
          limit,
          groupDisabled: disabled
        }}>
        {children}
      </RadioGroupContext.Provider>
    </div>
  );
}

RadioGroup.propTypes = {
  children: arrayOf(node).isRequired,
  setFieldValue: func.isRequired,
  value: string.isRequired,
  className: string,
  disabled: bool,
  error: string,
  limit: number,
  mapInitialValue: func,
  name: string,
  onChange: func
};

export default RadioGroup;
