import i18next from 'i18next';

i18next.addResources('es_CL', 'Delivery', {
  notEnoughData: 'Nos faltan datos',
  description: 'Completa los datos del delivery',
  time: '{{time}} m aprox.',
  from: 'Desde tienda {{value}}',
  fromPickUp: 'Tienda {{value}}',
  inStore: 'En tienda {{value}}',
  confirmButton: 'Confirmar',
  apartmentPlaceholder: 'Depto / Oficina / Casa',
  notePlaceholder: 'Deja un mensaje al delivery',
  note: 'Nota de delivery',
  exclusionZone: 'No hay delivery disponible',
  exclusionZoneMessage: 'Lo sentimos, por ahora no podemos entregar en tu dirección',
  noStreetAddress: 'Escribe tu dirección completa para habilitar la entrega a domicilio'
});
