import i18next from 'i18next';

import { CURRENCY_SYMBOL } from '~constants/environment';

i18next.addResources('es_CL', 'OrderSummary', {
  addATip: 'Añade una propina',
  addCoupon: 'Agregar código de descuento',
  addTip: 'Agregar propina',
  modifyTip: 'Modificar propina',
  addToTip: `Añadir ${CURRENCY_SYMBOL}{{mount}} en propina`,
  apply: 'Aplicar',
  buy: 'Comprar',
  cardEndWith: 'Terminada en ****{{lastDigits}}',
  asterisksCardEndWith: '**** **** **** {{lastDigits}}',
  complianceText: '*Al confirmar el pedido declaras haber leído y aceptado nuestros ',
  delivery: 'Delivery:',
  discountCoupon: '¿Tienes un cupón de descuento?',
  driver: 'al repartidor',
  driverTip: 'Ellos hacen todo lo posible por llegar cuanto antes con tu pedido. Prémialos con un extra.',
  freeDelivery: 'Gratis',
  giveTip: 'Recompensa este servicio',
  hideDetail: 'Ocultar detalle',
  invalidCoupon: 'Prueba con otro cupón',
  login: 'Iniciar sesión',
  minPriceDelivery: 'Mínimo delivery {{price}}',
  minPriceForDelivery: '¡Te faltan {{remainingAmount}} para el pedido mínimo!',
  modify: 'Ver pedido',
  modifyCoupon: 'Modificar código descuento',
  modifyOrder: 'Modifica tu pedido',
  mustSelectPaymentMethod: 'Debes seleccionar un método de pago',
  notYet: 'Por ahora no',
  or: 'o',
  paymentMethods: 'Método de pago',
  remove: 'Remover',
  resume: 'Resumen de compra',
  returnStore: 'Vuelve a la tienda',
  showDetail: 'Ver detalle',
  store: 'a la tienda',
  service: 'Servicio:',
  storeClosed: 'La tienda se encuentra cerrada',
  storeTip: 'Puedes dar un extra a este local y así darle un reconocimiento adicional a su servicio.',
  subtotal: 'Subtotal:',
  termsAndConditions: 'términos y condiciones.',
  tipText:
    'Nuestros colaboradores son importantes en la preparación de tu orden. Prémialos con una propina extra.',
  tipTo: 'Propina {{who}}:',
  total: 'Total',
  totalSaved: 'Descuento:',
  validCoupon: 'Descuento aplicado correctamente.',
  wantToReward: '¿Quieres recompensar {{who}}?',
  writeCoupon: 'Ingresa el código',
  subscription: 'Quiero recibir promociones (opcional).',
  acceptTermsAndConditions: 'Acepto los términos y condiciones'
});
