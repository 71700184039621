import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers as CR, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { fetchMiddleware, configureMergeState, wrapCombineReducers } from 'redux-recompose';

import tabs from './Tabs/reducer';
import modal from './Modal/reducer';
import menu from './Menu/reducer';
import searchStore from './SearchStore/reducer';
import auth from './Auth/reducer';
import cart from './Cart/reducer';
import coupons from './Coupons/reducer';
import order from './Order/reducer';
import product from './Product/reducer';
import core from './Core/reducer';
import cdn from './CDN/reducer';
import pages from './Pages/reducer';
import nextUp from './NextUp/reducer';
import searchBar from './SearchBar/reducer';
import AnalyticsMiddleware from './middlewares/AnalyticsMiddleware';
import creditCards from './CreditCards/reducer';

configureMergeState((state, diff) => state.merge(diff));

const combineReducers = wrapCombineReducers(CR);

export const history = createBrowserHistory();

// Add reducers here
const reducers = combineReducers({
  router: connectRouter(history),
  modal,
  menu,
  tabs,
  searchStore,
  auth,
  cart,
  order,
  core,
  coupons,
  cdn,
  product,
  pages,
  nextUp,
  searchBar,
  creditCards
});

const middlewares = [thunk, fetchMiddleware, routerMiddleware(history)];
export const enhancers = [];

/* ------------- Analytics Middleware ------------- */
middlewares.push(AnalyticsMiddleware);

/* ------------- Assemble Middleware ------------- */
enhancers.push(applyMiddleware(...middlewares));

export const composeEnhancers =
  // eslint-disable-next-line no-underscore-dangle
  (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const rootReducer = (state, action) => reducers(state, action);

const store = createStore(rootReducer, composeEnhancers(...enhancers));

export default store;
