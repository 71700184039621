import AnalyticsService from '~services/Analytics';
import { actions as OrdersActions } from '~redux/Order/actions';

const eventsMap = (type, store, payload) =>
  ({
    [OrdersActions.ADD_PURCHASE_TO_DATALAYER_SUCCESS]: () => AnalyticsService.purchaseEvent(store, payload)
  }[type]);

const analyticsMiddleware = ({ getState }) => (next) => (action) => {
  const { type, payload } = action;
  const store = getState();
  eventsMap(type, store, payload)?.();
  return next(action);
};

export default analyticsMiddleware;
