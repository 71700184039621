import Immutable from 'seamless-immutable';
import { createReducer, completeReducer, completeState } from 'redux-recompose';
import onReadValue from 'redux-recompose/lib/effects/onReadValue';

import { actions } from './actions';
import { onCartAction, onCartActionWithCoupon } from './effects';

const stateDescription = {
  cart: [],
  remoteCart: null,
  subtotal: 0,
  total: 0,
  delivery: 0,
  discount: 0,
  item: null,
  error: '',
  coupon: [],
  loyaltyPoints: null,
  maxLoyaltyMoneyToUse: 0,
  loyaltyProgram: null
};

const initialState = completeState(stateDescription, ['item']);

const reducerDescription = {
  primaryActions: [
    actions.ADD_COUPON,
    actions.SET_ERROR,
    actions.SET_DISCOUNT,
    actions.CHECK_CART,
    actions.CREATE_REMOTE_CART,
    actions.UPDATE_REMOTE_CART,
    actions.GET_LAST_REMOTE_CART,
    actions.GET_CART_BY_ID
  ],
  override: {
    [actions.SET_ITEM]: onReadValue(),
    [actions.SET_CART]: onReadValue(),
    [actions.SET_FINAL_PRICE]: onReadValue(),
    [actions.SET_SUBTOTAL_PRICE]: onReadValue(),
    [actions.ADD_ITEM]: onReadValue(),
    [actions.SUM_ITEM]: onReadValue(),
    [actions.REMOVE_ITEM]: onReadValue(),
    [actions.MODIFY_ITEM]: onReadValue(),
    [actions.ADD_COUPON_SUCCESS]: onCartActionWithCoupon,
    [actions.SET_ERROR]: onReadValue(),
    [actions.SET_DISCOUNT]: onReadValue(),
    [actions.CHECK_CART_SUCCESS]: onCartAction,
    [actions.DELETE_REMOTE_CART]: onReadValue(),
    [actions.RESTORE_CART]: onReadValue()
  }
};

export default createReducer(new Immutable(initialState), completeReducer(reducerDescription));
