/* eslint-disable babel/no-unused-expressions */
import { useState, useEffect } from 'react';

const SCRIPT_STATES = {
  IDLE: 'idle',
  LOADING: 'loading',
  READY: 'ready',
  ERROR: 'error'
};

export function useScript(src, params, baseComponentId) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? SCRIPT_STATES.LOADING : SCRIPT_STATES.IDLE);
  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus(SCRIPT_STATES.IDLE);
        return;
      }
      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script = document.querySelector(`script[src="${src}"]`);
      if (script) {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute('data-status'));
      } else {
        // Create script
        script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.setAttribute('data-status', SCRIPT_STATES.LOADING);
        Object.entries(params).forEach(([key, value]) => script?.setAttribute(key, value));
        // Add script to document body
        document.getElementById(baseComponentId)?.appendChild(script);
        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event) => {
          script?.setAttribute(
            'data-status',
            event.type === 'load' ? SCRIPT_STATES.READY : SCRIPT_STATES.ERROR
          );
        };
        script.addEventListener('load', setAttributeFromEvent);
        script.addEventListener('error', setAttributeFromEvent);
      }
      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event) => {
        setStatus(event.type === 'load' ? SCRIPT_STATES.READY : SCRIPT_STATES.ERROR);
      };
      // Add event listeners
      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent);
      // Remove event listeners on cleanup
      // eslint-disable-next-line consistent-return
      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent);
          script.removeEventListener('error', setStateFromEvent);
        }
      };
    },
    // Only re-run effect if script src or params change
    [src, params, baseComponentId]
  );
  return status;
}
