export const COUPON_CODE = {
  COUPON_EXPIRED: 'COUPON_EXPIRED',
  COUPON_MAX_REACHED: 'COUPON_MAX_REACHED',
  COUPON_NOT_AVAILABLE: 'COUPON_NOT_AVAILABLE',
  MAX_COUPON_USES_BY_USER_REACHED: 'MAX_COUPON_USES_BY_USER_REACHED',
  COUPON_DISPATCH_METHOD: 'COUPON_DISPATCH_METHOD',
  COUPON_CANNOT_BE_USED_BY_USER: 'COUPON_CANNOT_BE_USED_BY_USER',
  COUPON_WITH_MINIMUM_BILL: 'COUPON_WITH_MINIMUM_BILL',
  COUPON_PLATFORM_ERROR: 'COUPON_PLATFORM_ERROR',
  NOT_FIRST_PURCHASE: 'NOT_FIRST_PURCHASE',
  INVALID_INVITATION_CODE: 'INVALID_INVITATION_CODE',
  INVALID_MINIMUM_START_TIME: 'INVALID_MINIMUM_START_TIME',
  INVALID_MAXIMUM_EXPIRY_TIME: 'INVALID_MAXIMUM_EXPIRY_TIME',
  COUPON_COUPONIZABLE_ERROR: 'COUPON_COUPONIZABLE_ERROR',
  INVALID_COUPON_CODE: 'INVALID_COUPON_CODE'
};
