import i18next from 'i18next';

i18next.addResources('es_CL', 'StoreSearchBar', {
  placeholder: 'Ingresa tu dirección para pedir con delivery',
  gettingCurrentLocation: 'Obteniendo ubicación…',
  currentLocation: 'Usar mi ubicación actual',
  PERMISSION_DENIED: 'Para utilizar esta funcionalidad debes habilitar los permisos de geolocalización',
  POSITION_UNAVAILABLE: 'Información de localización no disponible',
  TIMEOUT: 'El requerimiento expiró',
  UNKNOWN: 'Error desconocido.'
});
