import { ISO_COUNTRY } from '~constants/environment';
import { MODALS } from '~redux/Modal/constants';

export const PAYMENT_STATUS = {
  SCHEDULED: 'scheduled',
  CREATED: 'created',
  PREPARING: 'preparing',
  READY: 'ready',
  IN_TRANSIT: 'inTransit',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  FAILED: 'failed',
  REVERSED: 'reversed',
  UNKNOWN: 'unknown',
  CANCELED: 'canceled'
};

export const STATUS_ALLOWED_TO_SHOW_MAP = [PAYMENT_STATUS.READY, PAYMENT_STATUS.IN_TRANSIT];

export const PAYMENT_METHOD_WITH_QR = ['chek_payment', 'mach_payment', 'fpay_payment'];

export const INTERVAL_REQUEST = 1000;

export const TARGETS = {
  CREATE_ORDER: 'createOrder',
  ORDERS: 'orders',
  LAST_ORDER: 'lastOrder',
  ACTIVE_ORDERS: 'activeOrders',
  ADD_PURCHASE_TO_DATALAYER: 'addPurchaseToDatalayer',
  ONLINE_PAYMENT_TARGET: 'onlinePayment',
  CURRENT_ORDER_TARGET: 'currentOrder',
  TIP: 'tip',
  STORE_TIP: 'storeTip',
  QUESTIONS: 'questions',
  ORDERS_TAB: 'ordersTab',
  FUTURE_ORDER: 'futureOrder',
  PRESELECTED_DATE: 'preselectedDate',
  KUSHKI_TOKEN: 'kushkiToken',
  KUSHKI_TOKEN_ERROR: 'kushkiTokenError',
  KUSHKI_SUBSCRIPTION_TOKEN: 'kushkiSubscriptionToken',
  KUSHKI_DEVICE_TOKEN: 'kushkiDeviceToken'
};

const INITIAL_TIP_VALUES = {
  default: 0,
  cl: 0
};

const DIFFERENTIAL_TIP_VALUES = {
  default: 1,
  cl: 500,
  pe: 3,
  ar: 60,
  co: 2500,
  gu: 5,
  cr: 400,
  me: 13
};

export const INITIAL_TIP = INITIAL_TIP_VALUES?.[ISO_COUNTRY] || INITIAL_TIP_VALUES.default;
export const DIFERENTIAL_TIP = DIFFERENTIAL_TIP_VALUES?.[ISO_COUNTRY] || DIFFERENTIAL_TIP_VALUES.default;
export const INCREASE_TIP = 'increase';
export const DECREASE_TIP = 'decrease';

export const INITIAL_ORDERS_TAB = 'orders';

export const REDIRECT_URL = window.location.origin.includes('localhost')
  ? 'https://dev.justburger.cl'
  : window.location.origin.replace(/\/$/, '');

export const ERROR_MODALS = {
  1092: MODALS.NO_RIDERS,
  1093: MODALS.NO_RIDERS,
  11101: MODALS.NO_RIDERS
};

export const DEFAULT_SEGMENTS = {
  START: 0,
  END: 80
};
