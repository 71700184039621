export const getScripts = (id) => ({
  script: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-${id}');`,
  noScript: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-${id}"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>`
});

export const getDatalayer = ({ dataLayer, dataLayerName }) => `
window.${dataLayerName} = window.${dataLayerName} || [];
window.${dataLayerName}.push(${JSON.stringify(dataLayer)})`;
