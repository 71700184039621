import i18next from 'i18next';

i18next.addResources('es_CL', 'CheckoutSection', {
  discounts: 'Descuentos',
  dispatchDetails: 'Datos de entrega',
  paymentMethods: 'Métodos de pago',
  deliveryOptions: 'Indicaciones',
  questions: 'Instrucciones adicionales',
  profile: 'Datos personales'
});
