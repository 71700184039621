import i18next from 'i18next';

export const MAX_LAST_ADDRESSES = 4;

export const TARGETS = {
  ADDRESS: 'address',
  GUEST_USER: 'guestUser',
  CURRENT_USER: 'currentUser',
  SEND_SIGN_IN_CODE_TO_EMAIL: 'sendSignInCodeToEmail',
  SIGN_IN_WITH_EMAIL_CODE: 'signInWithEmailCode',
  REGISTER_USER: 'registerUser',
  CREDIT_CARDS: 'creditCards'
};

const ERROR_TYPES = {
  PHONE_TAKEN: 'phone_taken'
};

const mappedErrors = {
  [ERROR_TYPES.PHONE_TAKEN]: i18next.t('APIErrorFields:phoneTaken')
};

export const authErrorFieldsCatcher = (fields) =>
  fields.reduce((ac, el) => [...ac, el.errors.reduce((erAc, er) => [...erAc, mappedErrors[er]], [])], []);

export const DEFAULT_VALUES = {
  address: {
    current: {
      textAddress: '',
      description: '',
      apartmentDetails: '',
      latitude: 0,
      longitude: 0,
      placeId: 0
    }
  }
};
