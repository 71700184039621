import orderAccepted from './assets/order_accepted.json';
import orderPrepared from './assets/order_prepared.json';
import fastFood from './assets/fast-food-mobile-app-loading.json';
import preparing from './assets/preparing_food.json';
import ready from './assets/takeaway.json';
import orderDeclined from './assets/order_declined.json';
import addedToCart from './assets/added_to_cart.json';
import orderSuccess from './assets/order_success.json';
import inTransit from './assets/order_on_way.json';
import closedRestaurant from './assets/restaurant_closed.json';
import cookingFood from './assets/cooking_food.json';
import noOrders from './assets/no_orders.json';
import waitingConfirmation from './assets/waiting_confirmation.json';
import trendingRestaurant from './assets/trending_restaurant.json';
import searchingFood from './assets/searching_food.json';
import chickenBucket from './assets/chicken_bucket.json';
import preparingFoodCooking from './assets/preparing_food_cooking.json';
import burgerLoader from './assets/burger_loader.json';
import foodMenu from './assets/food_menu.json';

export const VARIANTS = {
  fastFood,
  orderAccepted,
  preparing,
  ready,
  inTransit,
  orderDeclined,
  addedToCart,
  orderPrepared,
  orderSuccess,
  closedRestaurant,
  cookingFood,
  noOrders,
  waitingConfirmation,
  trendingRestaurant,
  searchingFood,
  chickenBucket,
  preparingFoodCooking,
  burgerLoader,
  foodMenu
};

export const DEFAULT_HEIGHT = 150;

export const DEFAULT_WIDTH = 150;
