import { create } from 'apisauce';

import api, { apiSetup, HEADERS } from '~config/api';
import { mapCart, mapCartWithDiscount } from '~redux/Cart/mappers';
import { API_URL } from '~constants/environment';
import { retry } from '~utils/functions';

const createTemporalCartApi = (externalId) => {
  const temporalApi = create({
    baseURL: API_URL,
    headers: {
      ...(externalId && {
        [HEADERS.USER_ID]: externalId
      }),
      [HEADERS.TENANT]: api.headers[HEADERS.TENANT],
      [HEADERS.PLATFORM]: api.headers[HEADERS.PLATFORM]
    }
  });
  apiSetup(temporalApi);
  return temporalApi;
};

export const sendCart = (state) => {
  const temporalCartApi = createTemporalCartApi(state.state.auth?.currentUser?.externalId);
  const requestShoppingCartsPrices = () =>
    temporalCartApi.post('v1/shopping_carts/prices', mapCart(state)).then((res) => ({
      ok: res.ok,
      data: {
        cart: state.cart,
        subtotal: res.data.subtotal,
        total: res.data.total,
        delivery: res.data.delivery,
        deliveryMethod: res.data.deliveryMethod,
        uberEstimateId: res.data.uberEstimateId,
        uberEstimateExpiresAt: res.data.uberEstimateExpiresAt,
        deliveryDetails: res.data.deliveryDetails,
        maxLoyaltyMoneyToUse: res.data.maxLoyaltyMoneyToUse,
        loyaltyProgram: res.data.loyaltyProgram
      }
    }));

  return retry(requestShoppingCartsPrices);
};

export const sendCartWithCoupon = ({ coupon, state, paymentMethod, cashToExchange }) => {
  const temporalCartApi = createTemporalCartApi(state.auth?.currentUser?.externalId);
  return temporalCartApi
    .post('v1/shopping_carts/prices', mapCartWithDiscount({ state, coupon, paymentMethod, cashToExchange }))
    .then((res) => ({
      ...res,
      data: {
        cart: state.cart.cart,
        subtotal: res.data.subtotal,
        total: res.data.total,
        delivery: res.data.delivery,
        deliveryMethod: res.data.deliveryMethod,
        deliveryDetails: res.data.deliveryDetails,
        uberEstimateId: res.data.uberEstimateId,
        uberEstimateExpiresAt: res.data.uberEstimateExpiresAt,
        discount: res.data.discount,
        error: {
          code: res.data.description,
          metadata: res.data.metadata
        },
        coupon: res.data.coupon,
        loyaltyPoints: res.data.loyaltyProgram?.amount,
        maxLoyaltyMoneyToUse: res.data.maxLoyaltyMoneyToUse,
        loyaltyProgram: res.data.loyaltyProgram
      }
    }));
};

export const createCart = (cart) => {
  return api.post('v1/shopping_carts', cart);
};

export const getLastCart = () => {
  return api.get('v1/shopping_carts/last');
};

export const updateCart = (cart) => {
  return api.patch(`v1/shopping_carts/${cart.shoppingCart.id}`, cart);
};

export const getCartById = (cartId) => {
  return api.get(`v1/shopping_carts/${cartId}`);
};
